export const constants = {
  initialRowHeight: 94,
  smBreakpoint: 600,
};

export const resourceStrings = {
  showScheduledTasksFilterText: 'Show Scheduled Tasks',
  showRecurringTasksFilterText: 'Show Recurring Tasks',
  showFutureRecurringTasksFilterText: 'Show Future Recurring Tasks',
  showUnscheduledTasksFilterText: 'Show Unscheduled Tasks',
  showTodayOnlyFilterText: 'Show Today Only',
  showEmptySectionsFilterText: 'Show Empty Sections',

  showEventsFilterText: 'Show Events',

  overdueText: 'Overdue',
  todayText: 'Today',
  tomorrowText: 'Tomorrow',
  after7DaysText: 'After 7 Days',
  unscheduledTasksText: 'Unscheduled Tasks',

  taskCompletedText: 'Task completed.',
  tasksCompletedText: 'Tasks completed.',
  tasksLoadedSuccessfully: 'Tasks loaded successfully.',
  errorLoadingTasks: 'Error loading tasks.',
  networkConnectionErrorText: 'Network connection error.',
  networkOperationFailedText: 'Network operation failed.',
  networkRequestFailedToSendText: 'Network request failed to send.',

  cannotDropHereText: 'Cannot drop here.',
  cannotReorderRecurringTaskInstancesText:
    'Cannot reorder recurring task instances.',
  cannotDropInOverdueSectionText: 'Cannot drop in Overdue section.',
  cannotDropInAfter7DaysSectionText: 'Cannot drop in After 7 Days section.',
  droppedInSameSectionNoChangedMadeText:
    'Dropped in same section. No changes made.',
  alreadyScheduledForTodayNoChangedMadeText:
    'Already scheduled for today. No changes made.',
  reorderedText: 'Reordered.',

  invalidTaskDropped: 'Invalid task dropped.',
  operationNotSupportedForRecurringTasks:
    'Operation not supported for recurring tasks.',

  noTaskTitleEntered: 'No task title entered.',
  invalidTagNameEntered: 'Invalid tag name entered.',
  multiDayTasksNotSupported: 'Multi-day tasks not currently supported.',
  movingRecurringInstanceToOtherDayNotSupported:
    'Moving a recurring task instance to another day is not currently supported.',
  errorSavingTask: 'Error saving task.',
  noChangesWereMade: 'No changes were made.',

  updateAllTasks: 'Update All Tasks',
  thisWillUpdateAllTasksEtc: `This will update all tasks apart from deleted and completed instances. Continue?`,
  removeRecurrence: 'Remove Recurrence',
  thisWillCompletelyRemoveTaskRecurrenceEtc: `This will completely remove the task recurrence and set the task to be unscheduled. Continue?`,
  operationNotSupported: 'Operation not supported.',

  taskUpdated: 'Task updated.',
  tasksUpdated: 'Tasks updated.',
  taskAdded: 'Task added.',
  taskDeleted: 'Task deleted.',
  tasksDeleted: 'Tasks deleted.',
  taskUnscheduled: 'Task unscheduled.',
  tasksUnscheduled: 'Tasks unscheduled.',

  tagAdded: 'Tag added.',
  errorAddingTag: 'Error adding tag.',
  tagUpdated: 'Tag updated.',
  errorUpdatingTag: 'Error updating tag.',
  tagDeleted: 'Tag deleted.',
  errorDeletingTag: 'Error deleting tag.',
};

export const taskType = {
  Task: 'Task',
  Header: 'Header',
};

export const jsType = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
};
