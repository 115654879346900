import React from 'react';
import { Typography } from '@material-ui/core';
import { ReactComponent as Logo } from 'src/media/logo-todocalendar.svg';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  content: {
    height: '100%',
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#4183f1',
    marginBottom: '2rem',
  },
  logo: {
    width: '30%',
    maxWidth: '8rem',
  },
  message: {
    marginTop: '2rem',
  },
});

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant="h4">
        <strong>Todo</strong> Calendar
      </Typography>
      <Logo className={classes.logo} />
      <div>
        <Typography className={classes.message} variant="h6">
          Page not found
        </Typography>
      </div>
    </div>
  );
};

export default NotFound;
