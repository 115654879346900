import React, { forwardRef } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

const FilterMenuItem = forwardRef(
  ({ handleClick, filterKey, isChecked, text }, ref) => {
    return (
      <MenuItem onClick={handleClick.bind(this, filterKey)}>
        <ListItemIcon>
          <CheckIcon style={isChecked ? { opacity: 1 } : { opacity: 0 }} />
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </MenuItem>
    );
  }
);

export default FilterMenuItem;
