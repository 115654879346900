import * as XRegExp from 'xregexp';

import { Task } from 'src/model/Task';
import { cloneTask, toProperCase } from 'src/util/TaskUtil';
import { Tag } from 'src/model/Tag';
import AppSettings from 'src/constants/AppSettings';
import {
  isEqual,
  addMinutes,
  startOfDay,
  setISODay,
  addWeeks,
  getISODay,
  addYears,
  addMonths,
  addDays,
  isBefore,
  setMonth,
  setDate,
  getHours,
  getMinutes,
  addHours,
  setYear,
  endOfMonth,
  startOfMonth,
  startOfISOWeek,
  setMinutes,
  startOfToday,
  format,
  differenceInMilliseconds,
  startOfYesterday,
  startOfTomorrow,
  isAfter,
  differenceInDays,
  getYear,
} from 'date-fns';
import { getDateFromDayOfWeek } from 'src/util/Utils';

export default class TaskInputService {
  dateHighlightClass;
  tagHighlightClass;
  dateTimeRegex = XRegExp(AppSettings.DATE_TIME_REGEX, 'xsmig');
  currentDateTimeRegexResult;

  setClasses(dateHighlightClass, tagHighlightClass) {
    this.dateHighlightClass = dateHighlightClass;
    this.tagHighlightClass = tagHighlightClass;
  }

  highlightDateTime(inputElement) {
    inputElement.normalize();
    const startingCaretPosition = this.getCaretPosition(inputElement);
    let regexResult;

    if (!this.containsDateTimeSpan(inputElement)) {
      // No date/time highlight span in place, so run regex to look for a match
      let resultTextNode = null;

      const nodes = Array.from(inputElement.childNodes);
      for (const node of nodes) {
        if (node.nodeType !== Node.TEXT_NODE || node.nodeValue.length === 0) {
          continue;
        }

        regexResult = this.getRegexResult(node.nodeValue, this.dateTimeRegex);
        if (regexResult) {
          resultTextNode = node;
          break;
        }
      }

      if (!regexResult || !resultTextNode) {
        return;
      }

      // Found a match, so move it into a date/time highlight span
      resultTextNode.nodeValue =
        resultTextNode.nodeValue.substring(0, regexResult.index) +
        resultTextNode.nodeValue.substring(
          regexResult.index + regexResult[0].length
        );
      resultTextNode.splitText(regexResult.index);

      const newSpanElement = document.createElement('span');
      newSpanElement.setAttribute('class', this.dateHighlightClass);
      newSpanElement.addEventListener('click', event => {
        const spanNodeText = event.target.firstChild.nodeValue;
        this.removeDateTimeSpan(inputElement);
        this.addDateTimeSpanException(spanNodeText);
        this.highlightDateTime(inputElement);
        event.preventDefault();
      });
      const newTextNode = document.createTextNode(regexResult[0]);
      newSpanElement.appendChild(newTextNode);

      resultTextNode.parentNode.insertBefore(
        newSpanElement,
        resultTextNode.nextSibling
      );
      this.setCaretPosition(inputElement, startingCaretPosition);
      this.setCurrentDateTimeRegexResult(regexResult);
    } else {
      // Existing date/time highlight span, so run regex on this span to check if still a match
      const spanElement = this.getDateTimeSpan(inputElement);
      const spanTextNode = spanElement.firstChild;
      regexResult = this.getRegexResult(
        spanTextNode.nodeValue,
        this.dateTimeRegex
      );
      if (!regexResult) {
        // The span no longer has any matches, so remove the span
        spanElement.insertAdjacentText('afterend', spanTextNode.nodeValue);
        spanElement.remove();
        this.setCaretPosition(inputElement, startingCaretPosition);
        this.setCurrentDateTimeRegexResult(null);
        return;
      }

      if (regexResult[0] !== spanTextNode.nodeValue) {
        // The span still has at least one match but it doesn't fill span completely,
        // so move any non-matching text before or after match out of the span

        // Check and move text out of left side
        if (regexResult.index > 0) {
          const textToMoveLeft = spanTextNode.nodeValue.substring(
            0,
            regexResult.index
          );
          if (
            spanElement.previousSibling &&
            spanElement.previousSibling.nodeType === Node.TEXT_NODE
          ) {
            spanElement.previousSibling.nodeValue += textToMoveLeft;
          } else {
            spanElement.insertAdjacentText('beforebegin', textToMoveLeft);
          }
        }

        // Check and move text out of right side
        if (
          regexResult.index + regexResult[0].length <
          spanTextNode.nodeValue.length
        ) {
          const textToMoveRight = spanTextNode.nodeValue.substring(
            regexResult.index + regexResult[0].length
          );
          if (
            spanElement.nextSibling &&
            spanElement.nextSibling.nodeType === Node.TEXT_NODE
          ) {
            spanElement.nextSibling.nodeValue =
              textToMoveRight + spanElement.nextSibling.nodeValue;
          } else {
            spanElement.insertAdjacentText('afterend', textToMoveRight);
          }
        }
        spanTextNode.nodeValue = regexResult[0];
        this.setCaretPosition(inputElement, startingCaretPosition);
        this.setCurrentDateTimeRegexResult(regexResult);
      } else {
        // Span still has a match and match completely fills span
        this.setCurrentDateTimeRegexResult(regexResult);

        // Check if we can extend the match

        // First check to right
        if (
          spanElement.nextSibling &&
          spanElement.nextSibling.nodeType === Node.TEXT_NODE
        ) {
          const rightTextNode = spanElement.nextSibling;
          const regexResultExtended = this.getRegexResult(
            spanTextNode.nodeValue + rightTextNode.nodeValue,
            this.dateTimeRegex
          );

          if (
            regexResultExtended &&
            regexResultExtended[0].startsWith(regexResult[0]) &&
            regexResultExtended[0].length > spanTextNode.nodeValue.length
          ) {
            rightTextNode.nodeValue = rightTextNode.nodeValue.substring(
              regexResultExtended[0].length - spanTextNode.nodeValue.length
            );
            spanTextNode.nodeValue = regexResultExtended[0];
            this.setCurrentDateTimeRegexResult(regexResultExtended);
            this.setCaretPosition(inputElement, startingCaretPosition);
          }
        }

        // Then check to left
        if (
          spanElement.previousSibling &&
          spanElement.previousSibling.nodeType === Node.TEXT_NODE
        ) {
          const leftTextNode = spanElement.previousSibling;
          const regexResultExtended = this.getRegexResult(
            leftTextNode.nodeValue + spanTextNode.nodeValue,
            this.dateTimeRegex
          );

          if (
            regexResultExtended &&
            regexResultExtended[0].endsWith(regexResult[0]) &&
            regexResultExtended[0].length > spanTextNode.nodeValue.length
          ) {
            leftTextNode.nodeValue = leftTextNode.nodeValue.substring(
              0,
              regexResultExtended.index
            );
            spanTextNode.nodeValue = regexResultExtended[0];
            this.setCurrentDateTimeRegexResult(regexResultExtended);
            this.setCaretPosition(inputElement, startingCaretPosition);
          }
        }
      }
    }
  }

  setCurrentDateTimeRegexResult = value => {
    this.currentDateTimeRegexResult = value;
  };

  getCaretPosition(inputElement) {
    let caretOffset = 0;
    if (typeof window.getSelection !== 'undefined') {
      const range = window.getSelection().getRangeAt(0);
      const selectionLength = range.toString().length;
      const rangeClone = range.cloneRange();
      rangeClone.selectNodeContents(inputElement);
      rangeClone.setEnd(range.endContainer, range.endOffset);
      caretOffset = rangeClone.toString().length - selectionLength;
    }
    return caretOffset;
  }

  setCaretPosition(inputElement, caretPosition) {
    const nodeWithCaretPosition = this.findNodeWithCaretPosition(
      inputElement,
      caretPosition
    );
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStart(
      nodeWithCaretPosition.node,
      Math.min(
        nodeWithCaretPosition.caretOffset,
        nodeWithCaretPosition.node.textContent.length
      )
    );
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    inputElement.normalize();
  }

  containsDateTimeSpan(element) {
    const childNodes = Array.from(element.childNodes);
    for (const node of childNodes) {
      if (this.isDateTimeSpan(node)) {
        return true;
      }
    }
    return false;
  }

  isDateTimeSpan(element) {
    if (!element) {
      return false;
    }

    if (
      element.nodeName === 'SPAN' &&
      element.className === this.dateHighlightClass
    ) {
      return true;
    }

    return false;
  }

  getRegexResult(testString, regex) {
    let matchFromPosition = 0;
    let matchToPosition = testString.length;
    let result;
    const regexNBSP = new RegExp(AppSettings.NBSP, 'g');

    for (
      matchFromPosition = 0;
      matchFromPosition < testString.length - 1;
      matchFromPosition++
    ) {
      for (
        matchToPosition = testString.length;
        matchToPosition > matchFromPosition + 1;
        matchToPosition--
      ) {
        result = XRegExp.exec(
          testString
            .substring(matchFromPosition, matchToPosition)
            .replace(regexNBSP, ' '),
          regex
        );

        if (!result && matchToPosition === testString.length) {
          // If no result in string ending at end of test string (no matter where we are currently starting),
          // no need to do any more checks so return from method
          return null;
        } else if (!result) {
          // If no result in this substring, break out of inner loop as no need to check shorter strings
          break;
        }

        if (!this.isDateTimeSpanException(result[0])) {
          // If character after match is not a space, then skip it
          if (matchToPosition < testString.length) {
            const matchLastIndex =
              matchFromPosition + result.index + result[0].length;
            const charAfterMatch = testString.substring(
              matchLastIndex,
              matchLastIndex + 1
            );

            if (charAfterMatch !== ' ' && charAfterMatch !== AppSettings.NBSP) {
              continue;
            }
          }

          if (matchFromPosition + result.index > 0) {
            const charBeforeMatch = testString.substring(
              matchFromPosition + result.index - 1,
              matchFromPosition + result.index
            );
            if (
              charBeforeMatch !== ' ' &&
              charBeforeMatch !== AppSettings.NBSP
            ) {
              continue;
            }
          }

          // Return the match if it's not in list of exceptions
          result.index += matchFromPosition;
          return result;
        }
      }
    }
    return null;
  }

  isDateTimeSpanException(textToCheck) {
    if (AppSettings.dateTimeSpanExceptions.indexOf(textToCheck) !== -1) {
      return true;
    }
    return false;
  }

  findNodeWithCaretPosition(element, position) {
    let node;
    const textNodes = this.getAllTextNodes(element);
    for (let nodeIndex = 0; nodeIndex < textNodes.length; nodeIndex++) {
      if (
        position > textNodes[nodeIndex].nodeValue.length &&
        textNodes[nodeIndex + 1]
      ) {
        // remove amount from the position, go to next node
        position -= textNodes[nodeIndex].nodeValue.length;
      } else {
        node = textNodes[nodeIndex];
        break;
      }
    }
    // you'll need the node and the position (offset) to set the caret
    return { node: node, caretOffset: position };
  }

  getAllTextNodes(element) {
    const textNodes = [];
    const walker = document.createTreeWalker(
      element,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );
    let currentNode;
    while ((currentNode = walker.nextNode())) {
      textNodes.push(currentNode);
    }
    return textNodes;
  }

  getDateTimeSpan(element) {
    const childNodes = Array.from(element.childNodes);
    for (const node of childNodes) {
      if (this.isDateTimeSpan(node)) {
        return node;
      }
    }
    return null;
  }

  removeDateTimeSpan(inputElement) {
    const spanElement = this.getDateTimeSpan(inputElement);
    const spanTextNode = spanElement.firstChild;
    const startingCaretPosition = this.getCaretPosition(inputElement);

    spanElement.insertAdjacentText('afterend', spanTextNode.nodeValue);
    spanElement.remove();
    this.setCaretPosition(inputElement, startingCaretPosition);
  }

  addDateTimeSpanException(exceptionText) {
    const exceptionTextLength = exceptionText.length;
    AppSettings.dateTimeSpanExceptions.push(exceptionText);
    if (exceptionText.endsWith('s') && exceptionTextLength > 1) {
      AppSettings.dateTimeSpanExceptions.push(
        exceptionText.substring(0, exceptionTextLength - 1)
      );
    }
  }

  removeBrFromNodeAndChildren(input, node) {
    if (node.nodeName === 'BR') {
      const prevSibling = node.previousSibling;
      const nextSibling = node.nextSibling;
      if (
        prevSibling &&
        prevSibling.nodeType === Node.TEXT_NODE &&
        prevSibling.textContent.slice(prevSibling.textContent.length - 1) ===
          ' ' &&
        (!nextSibling || nextSibling.nodeName !== 'BR')
      ) {
        const caretPosition = this.getCaretPosition(input);
        node.previousSibling.textContent = node.previousSibling.textContent.replace(
          /[ ]$/g,
          AppSettings.NBSP
        );
        this.setCaretPosition(input, caretPosition);
        node.parentNode.removeChild(node);
      }
    }

    for (const childNode of node.childNodes) {
      this.removeBrFromNodeAndChildren(input, childNode);
    }
  }

  highlightTags(inputElement) {
    if (inputElement.innerHTML.length === 0) {
      return;
    }

    inputElement.normalize();
    const startingCaretPosition = this.getCaretPosition(inputElement);

    // Look for all existing tag spans
    const tagSpans = inputElement.querySelectorAll(
      'span.' + this.tagHighlightClass
    );

    tagSpans.forEach(tagSpan => {
      const tagSpanTextNode = tagSpan.firstChild;
      if (!tagSpanTextNode) {
        return;
      }

      // If text contains &nbsp; just before closing </span> then user has pressed space
      // so move it outside of span
      if (tagSpanTextNode.nodeValue.endsWith(AppSettings.NBSP)) {
        tagSpanTextNode.nodeValue = tagSpanTextNode.nodeValue.substring(
          0,
          tagSpanTextNode.nodeValue.length - 1
        );
        if (tagSpan.nextSibling) {
          tagSpan.nextSibling.nodeValue = ' ' + tagSpan.nextSibling.nodeValue;
        } else {
          tagSpan.insertAdjacentText('afterend', AppSettings.NBSP);
        }
        this.setCaretPosition(inputElement, startingCaretPosition);
      }

      // If span is empty, remove it
      if (tagSpanTextNode.nodeValue.length === 0) {
        tagSpan.parentElement.removeChild(tagSpan);
      }
    });

    // Look for any tag prefix characters that are not in a span, and add to a tag span
    inputElement.childNodes.forEach(childNode => {
      if (childNode.nodeType !== Node.TEXT_NODE) {
        return;
      }

      const tagPrefixIndex = childNode.nodeValue.indexOf(
        AppSettings.TAG_PREFIX
      );

      if (tagPrefixIndex !== -1) {
        childNode.nodeValue =
          childNode.nodeValue.substring(0, tagPrefixIndex) +
          childNode.nodeValue.substring(tagPrefixIndex + 1);
        const splitTextNode = childNode.splitText(tagPrefixIndex);
        const nextNode = splitTextNode.nextSibling;
        if (nextNode && splitTextNode.length === 0) {
          splitTextNode.nodeValue = AppSettings.NBSP;
        }

        const newTagSpan = document.createElement('span');
        newTagSpan.setAttribute('class', this.tagHighlightClass);
        const newTagSpanTextNode = document.createTextNode(
          AppSettings.TAG_PREFIX
        );
        newTagSpan.appendChild(newTagSpanTextNode);

        childNode.parentNode.insertBefore(newTagSpan, childNode.nextSibling);

        this.setCaretPosition(inputElement, startingCaretPosition);
      }
    });
  }

  caretIsInTagSpan(inputElement) {
    const selection = window.getSelection();
    if (
      selection.type === 'Caret' &&
      selection.anchorNode &&
      selection.anchorNode.parentElement &&
      selection.anchorNode.parentElement.localName.toLowerCase() === 'span' && // TODO: Change from localName to tagName?
      selection.anchorNode.nodeValue.indexOf(AppSettings.TAG_PREFIX) === 0 &&
      selection.anchorNode.parentElement.parentElement === inputElement
    ) {
      return true;
    }
    return false;
  }

  getTagSpanAtCaretText(inputElement) {
    const selection = window.getSelection();
    if (
      selection.type === 'Caret' &&
      selection.anchorNode &&
      selection.anchorNode.parentElement &&
      selection.anchorNode.parentElement.localName.toLowerCase() === 'span' &&
      selection.anchorNode.parentElement.className === this.tagHighlightClass &&
      selection.anchorNode.parentElement.parentElement === inputElement
    ) {
      return selection.anchorNode.nodeValue;
    }
    return '';
  }

  caretIsInDateSpan(inputElement) {
    const selection = window.getSelection();
    if (
      selection.type === 'Caret' &&
      selection.anchorNode &&
      selection.anchorNode.parentElement &&
      selection.anchorNode.parentElement.localName.toLowerCase() === 'span' &&
      selection.anchorNode.parentElement.className ===
        this.dateHighlightClass &&
      selection.anchorNode.parentElement.parentElement === inputElement
    ) {
      return true;
    }
    return false;
  }

  getDateTimeSpanAtCaretText(inputElement) {
    const selection = window.getSelection();
    if (
      selection.type === 'Caret' &&
      selection.anchorNode &&
      selection.anchorNode.parentElement &&
      selection.anchorNode.parentElement.localName.toLowerCase() === 'span' &&
      selection.anchorNode.parentElement.className ===
        this.dateHighlightClass &&
      selection.anchorNode.parentElement.parentElement === inputElement
    ) {
      return selection.anchorNode.nodeValue;
    }
    return '';
  }

  getTagSpanAtCaret(inputElement, savedSelection) {
    const selection = window.getSelection();
    if (!selection || !selection.anchorNode) {
      return;
    }

    let selectionNode = selection.anchorNode;
    let selectionType = selection.type;
    if (!inputElement.contains(selectionNode) && savedSelection) {
      // Input element is not focused, so use saved selection if present
      selectionNode = savedSelection.node;
      selectionType = savedSelection.type;
      // this.savedSelectionNode = null;
      // this.savedSelectionType = null;
    }

    if (
      selectionType === 'Caret' &&
      selectionNode &&
      selectionNode.parentElement &&
      selectionNode.parentElement.localName.toLowerCase() === 'span' &&
      selectionNode.parentElement.className === this.tagHighlightClass &&
      selectionNode.parentElement.parentElement === inputElement
    ) {
      return selectionNode.parentElement;
    }

    return null;
  }

  getElementText(element) {
    let elementText = '';
    element.childNodes.forEach(childNode => {
      if (childNode.nodeType === Node.TEXT_NODE) {
        elementText += childNode.nodeValue;
      } else if (
        childNode.nodeType === Node.ELEMENT_NODE &&
        childNode.nodeName === 'BR'
      ) {
        elementText += '\n';
      }
    });
    return elementText.replace(/[ \u00a0]+/g, ' ').trim();
  }

  getInputTagNames(inputElement) {
    inputElement.normalize();
    const tagNames = [];

    const tagSpans = inputElement.querySelectorAll(
      'span.' + this.tagHighlightClass
    );
    tagSpans.forEach(tagSpan => {
      const tagSpanTextNode = tagSpan.firstChild;
      if (!tagSpanTextNode || tagSpanTextNode.nodeValue.length === 0) {
        return;
      }

      tagNames.push(tagSpanTextNode.nodeValue);
    });

    return tagNames.filter((item, index) => {
      return tagNames.indexOf(item) >= index;
    });
  }

  getTaskFromInput(taskInput, existingTask, tags) {
    const taskTitle = this.getElementText(taskInput);
    let taskToAddOrUpdate;

    if (!existingTask || existingTask.Id === -1) {
      taskToAddOrUpdate = new Task();
    } else {
      taskToAddOrUpdate = cloneTask(existingTask);
    }

    taskToAddOrUpdate.Title = taskTitle;
    this.setTaskDateAndRecurrence(taskToAddOrUpdate, taskInput);

    taskToAddOrUpdate.Tags = [];
    const tagNames = this.getInputTagNames(taskInput);
    tagNames.forEach(tagName => {
      const existingTag = tags.find(
        tag => tag.Name.toUpperCase() === tagName.toUpperCase()
      );

      if (existingTag) {
        taskToAddOrUpdate.Tags.push(existingTag);
      } else {
        taskToAddOrUpdate.Tags.push(new Tag(tagName));
      }
    });

    return taskToAddOrUpdate;
  }

  setTaskDateAndRecurrence(task, inputElement, future) {
    if (!future) {
      future = true;
    }

    task.Start = null;
    task.End = null;
    task.AllDay = false;
    task.IsRecurring = false;
    task.RecurrenceStart = null;
    task.RecurrenceFrequency = null;
    task.RecurrenceFrequencyCount = null;
    task.RecurrenceEnd = null;
    task.RecurrenceWeekdays = null;

    if (!this.containsDateTimeSpan(inputElement)) {
      return;
    }

    const dateTimeString = this.getDateTimeSpanText(inputElement);
    const regexResult = this.currentDateTimeRegexResult;
    if (dateTimeString !== regexResult[0]) {
      return;
    }

    const currentDateTime = new Date();
    let startDateTime = null;

    // Get time if present
    const time = this.getTimeFromRegexInputs(
      [regexResult.groupTimeWithDate12Hour, regexResult.groupTimeOnly12Hour],
      [
        regexResult.groupTimeWithDate12Minute,
        regexResult.groupTimeOnly12Minute,
      ],
      [regexResult.groupTimeWithDate12AMPM, regexResult.groupTimeOnly12AMPM],
      [regexResult.groupTimeWithDate24Hour, regexResult.groupTimeOnly24Hour],
      [
        regexResult.groupTimeWithDate24Minute,
        regexResult.groupTimeOnly24Minute,
      ],
      [regexResult.groupTimeWithDateNoon, regexResult.groupTimeOnlyNoon]
    );

    // Get duration if present
    const regexDurationMins = this.getDurationFromRegexInputs(
      [
        regexResult.groupDurationName,
        regexResult.groupTimeOnlyDurationName,
        regexResult.groupNowDurationName,
        regexResult.groupInIntervalDurationName,
      ],
      [
        regexResult.groupDurationCount,
        regexResult.groupTimeOnlyDurationCount,
        regexResult.groupNowDurationCount,
        regexResult.groupInIntervalDurationCount,
      ]
    );

    let durationMins = AppSettings.DEFAULT_TASK_DURATION_MINS;
    if (regexDurationMins) {
      durationMins = regexDurationMins;
    }

    // Get starting date if present
    const startDate = this.getDateTimeFromRegexInputs(
      currentDateTime,
      [regexResult.groupStartingDayName],
      [
        regexResult.groupStartingMonthDateA,
        regexResult.groupStartingMonthDateB,
        regexResult.groupStartingMonthDateC,
      ],
      [
        regexResult.groupStartingMonthNameA,
        regexResult.groupStartingMonthNameB,
        regexResult.groupStartingMonthNameC,
      ],
      [
        regexResult.groupStartingMonthYearA,
        regexResult.groupStartingMonthYearB,
        regexResult.groupStartingMonthYearC,
      ],
      [regexResult.groupStartingInIntervalName],
      [regexResult.groupStartingInIntervalCount]
    );
    if (startDate) {
      startDateTime = startDate;
    } else {
      startDateTime = currentDateTime;
    }

    // Get ending date if present, based on start datetime
    const endDate = this.getDateTimeFromRegexInputs(
      startDateTime,
      [regexResult.groupEndingDayName],
      [
        regexResult.groupEndingMonthDateA,
        regexResult.groupEndingMonthDateB,
        regexResult.groupEndingMonthDateC,
      ],
      [
        regexResult.groupEndingMonthNameA,
        regexResult.groupEndingMonthNameB,
        regexResult.groupEndingMonthNameC,
      ],
      [
        regexResult.groupEndingMonthYearA,
        regexResult.groupEndingMonthYearB,
        regexResult.groupEndingMonthYearC,
      ],
      [regexResult.groupEndingInIntervalName],
      [regexResult.groupEndingInIntervalCount]
    );

    // Get scheduled datetime based on start datetime
    let start = this.getDateTimeFromRegexInputs(
      startDateTime,
      [regexResult.groupNow, regexResult.groupDayName],
      [
        regexResult.groupMonthDateA,
        regexResult.groupMonthDateB,
        regexResult.groupMonthDateC,
      ],
      [
        regexResult.groupMonthNameA,
        regexResult.groupMonthNameB,
        regexResult.groupMonthNameC,
      ],
      [
        regexResult.groupMonthYearA,
        regexResult.groupMonthYearB,
        regexResult.groupMonthYearC,
      ],
      [regexResult.groupInIntervalName],
      [regexResult.groupInIntervalCount],
      time,
      future
    );

    // Get recurring details
    const recurringDetails = {
      recurrenceFrequency: null,
      recurrenceFrequencyCount: null,
      allowedWeekdays: null,
      hourOfDay: null,
      startMonthDate: null,
      startMonthName: null,
    };
    this.getRecurringDetails(
      recurringDetails,
      // tslint:disable-next-line:max-line-length
      [
        regexResult.groupDaily,
        regexResult.groupWeekly,
        regexResult.groupMonthly,
        regexResult.groupYearly,
        regexResult.groupEveryPeriod1,
        regexResult.groupEveryPeriod2,
        regexResult.groupEveryPeriodEveryDay,
      ],
      regexResult.groupEveryPeriodCount,
      regexResult.groupEveryWeekOnDays,
      [regexResult.groupEveryMonthDateA, regexResult.groupEveryMonthDateB],
      [regexResult.groupEveryMonthNameA, regexResult.groupEveryMonthNameB]
    );

    if (start) {
      start.setHours(start.getHours(), start.getMinutes(), 0, 0);
      task.Start = start;
      task.End = addMinutes(start, durationMins);
      if (isEqual(start, startOfDay(start))) {
        task.AllDay = true;
        task.End = null;
      }
    }

    if (recurringDetails.recurrenceFrequency) {
      const firstDateTime = this.getFirstRecurringDateTime(
        startOfDay(startDateTime),
        time,
        recurringDetails.hourOfDay,
        recurringDetails.recurrenceFrequency,
        recurringDetails.recurrenceFrequencyCount,
        recurringDetails.allowedWeekdays,
        recurringDetails.startMonthDate,
        recurringDetails.startMonthName
      );
      const firstDate = startOfDay(firstDateTime);

      task.Start = firstDateTime;
      task.End = addMinutes(firstDateTime, durationMins);
      if (isEqual(firstDate, firstDateTime)) {
        task.AllDay = true;
        task.End = null;
      }

      task.IsRecurring = true;
      task.RecurrenceStart = firstDateTime;
      task.RecurrenceFrequency = recurringDetails.recurrenceFrequency;
      task.RecurrenceFrequencyCount = recurringDetails.recurrenceFrequencyCount;
      if (endDate) {
        task.RecurrenceEnd = startOfDay(endDate);
      }
      task.RecurrenceWeekdays = recurringDetails.allowedWeekdays;
    }
  }

  getDateTimeSpanText(element) {
    return this.getDateTimeSpan(element).firstChild.nodeValue;
  }

  getTimeFromRegexInputs(
    hours12,
    minutes12,
    ampms12,
    hours24,
    minutes24,
    noons
  ) {
    let hour = null;
    let minute = null;

    const hour12 = this.getFirstDefinedValue(hours12);
    const minute12 = this.getFirstDefinedValue(minutes12);
    const ampm12 = this.getFirstDefinedValue(ampms12);
    const hour24 = this.getFirstDefinedValue(hours24);
    const minute24 = this.getFirstDefinedValue(minutes24);
    const noon = this.getFirstDefinedValue(noons);

    if (hour12) {
      // Got time in 12H format
      if (ampm12 && ampm12.toLowerCase() === 'pm') {
        hour = parseInt(hour12, 10) + 12;
        if (hour === 24) {
          hour = 12;
        }
      } else {
        hour = parseInt(hour12, 10);
        if (hour === 12) {
          hour = 0;
        }
      }
      minute = minute12 ? parseInt(minute12, 10) : 0;
    } else if (hour24) {
      // Got time in 24H format
      hour = parseInt(hour24, 10);
      minute = parseInt(minute24, 10);
    } else if (noon) {
      hour = 12;
      minute = 0;
    }

    if (hour != null && minute != null) {
      const dateTime = new Date();
      dateTime.setHours(hour, minute, 0, 0);
      return dateTime;
    } else {
      return null;
    }
  }

  getDurationFromRegexInputs(durationNames, durationCounts) {
    const durationName = this.getFirstDefinedValue(durationNames);
    const durationCount = parseFloat(this.getFirstDefinedValue(durationCounts));

    if (!durationName || !durationCount) {
      return null;
    }

    let minutes;
    if (durationName === 'min' || durationName === 'minute') {
      minutes = durationCount;
    } else if (durationName === 'hour') {
      minutes = durationCount * 60;
    } else {
      return null;
    }

    minutes =
      Math.round(minutes / AppSettings.MIN_EVENT_LENGTH_MINS) *
      AppSettings.MIN_EVENT_LENGTH_MINS;
    if (minutes === 0) {
      minutes = AppSettings.MIN_EVENT_LENGTH_MINS;
    }
    return minutes;
  }

  getDateTimeFromRegexInputs(
    startingDateTime,
    dayPatterns,
    monthDates,
    monthNames,
    years,
    intervalNames,
    intervalCounts,
    time,
    future
  ) {
    if (!future) {
      future = true;
    }
    const dayPattern = this.getFirstDefinedValue(dayPatterns);
    const monthDate = this.getFirstDefinedValue(monthDates);
    const monthName = this.getFirstDefinedValue(monthNames);
    const year = this.getFirstDefinedValue(years);
    const intervalName = this.getFirstDefinedValue(intervalNames);
    const intervalCount = this.getFirstDefinedValue(intervalCounts);

    let resultDateTime = null;
    if (dayPattern) {
      resultDateTime = this.getDateFromDayPattern(
        startingDateTime,
        time,
        dayPattern
      );
    } else if (monthDate) {
      resultDateTime = this.getDateFromDate(
        startingDateTime,
        time,
        monthDate,
        monthName,
        year
      );
    } else if (intervalName) {
      resultDateTime = this.getDateFromInterval(
        startingDateTime,
        intervalName,
        intervalCount
      );
    } else if (time) {
      resultDateTime = this.getDateFromTime(startingDateTime, time, future);
    }

    if (resultDateTime) {
      const roundedMinutes =
        Math.round(
          getMinutes(resultDateTime) / AppSettings.TASK_START_ROUND_MINS
        ) * AppSettings.TASK_START_ROUND_MINS;
      resultDateTime = setMinutes(resultDateTime, roundedMinutes);
    }

    return resultDateTime;
  }

  getDateFromDayPattern(startDateTime, time, datePattern) {
    const startDate = startOfDay(startDateTime);
    let resultDateTime;

    switch (datePattern.toLowerCase()) {
      case 'now': {
        resultDateTime = startDateTime;
        break;
      }
      case 'yesterday': {
        resultDateTime = addDays(startDate, -1);
        break;
      }
      case 'tod':
      case 'today': {
        resultDateTime = startDate;
        break;
      }
      case 'tom':
      case 'tomorrow': {
        resultDateTime = addDays(startDate, 1);
        break;
      }
      case 'mon':
      case 'monday': {
        resultDateTime = getDateFromDayOfWeek(startDate, 1);
        break;
      }
      case 'tue':
      case 'tues':
      case 'tuesday': {
        resultDateTime = getDateFromDayOfWeek(startDate, 2);
        break;
      }
      case 'wed':
      case 'wednesday': {
        resultDateTime = getDateFromDayOfWeek(startDate, 3);
        break;
      }
      case 'thu':
      case 'thur':
      case 'thurs':
      case 'thursday': {
        resultDateTime = getDateFromDayOfWeek(startDate, 4);
        break;
      }
      case 'fri':
      case 'friday': {
        resultDateTime = getDateFromDayOfWeek(startDate, 5);
        break;
      }
      case 'sat':
      case 'saturday': {
        resultDateTime = getDateFromDayOfWeek(startDate, 6);
        break;
      }
      case 'sun':
      case 'sunday': {
        resultDateTime = getDateFromDayOfWeek(startDate, 7);
        break;
      }
      case 'next week': {
        resultDateTime = addDays(startOfISOWeek(startDate), 1);
        break;
      }
      case 'next month': {
        resultDateTime = startOfMonth(addMonths(startDate, 1));
        break;
      }
      case 'end of month': {
        resultDateTime = startOfDay(endOfMonth(startDate));
        break;
      }
      default: {
        // Empty
      }
    }
    if (time && datePattern !== 'now') {
      resultDateTime.setHours(getHours(time), getMinutes(time), 0, 0);
    }
    return resultDateTime;
  }

  getDateFromDate(startDateTime, time, monthDate, monthName, year) {
    const startDate = startOfDay(startDateTime);
    let shiftInterval = 'month';
    let resultDateTime = new Date(startDate.getTime());

    if (monthName) {
      shiftInterval = 'year';
      const monthNumber = AppSettings.monthNumbers[monthName.toLowerCase()];
      resultDateTime = setMonth(resultDateTime, monthNumber);
    }

    if (monthDate) {
      resultDateTime = setDate(resultDateTime, monthDate);
    }

    if (year) {
      // Don't shift to first instance in future if year is specified
      resultDateTime = setYear(resultDateTime, year);
    } else if (isBefore(resultDateTime, startDate)) {
      if (shiftInterval === 'month') {
        resultDateTime = addMonths(resultDateTime, 1);
      } else if (shiftInterval === 'year') {
        resultDateTime = addYears(resultDateTime, 1);
      }
    }

    if (time) {
      resultDateTime.setHours(getHours(time), getMinutes(time), 0, 0);
    }

    return resultDateTime;
  }

  getDateFromInterval(startDateTime, intervalName, intervalCount) {
    let resultDateTime;

    if (intervalName === 'min') {
      intervalName = 'minute';
    }

    if (intervalName === 'minute') {
      resultDateTime = addMinutes(startDateTime, intervalCount);
    } else if (intervalName === 'hour') {
      resultDateTime = addHours(startDateTime, intervalCount);
    } else if (intervalName === 'day') {
      resultDateTime = addDays(startDateTime, intervalCount);
    } else if (intervalName === 'week') {
      resultDateTime = addWeeks(startDateTime, intervalCount);
    } else if (intervalName === 'month') {
      resultDateTime = addMonths(startDateTime, intervalCount);
    }

    if (intervalName !== 'minute' && intervalName !== 'hour') {
      resultDateTime = startOfDay(resultDateTime);
    }

    return resultDateTime;
  }

  getDateFromTime(startDateTime, time, future) {
    if (!future) {
      future = true;
    }
    let resultDateTime = time;
    if (isBefore(resultDateTime, startDateTime) && future) {
      resultDateTime = addDays(resultDateTime, 1);
    }

    return resultDateTime;
  }

  getRecurringDetails(
    r,
    dayPatterns,
    recurrenceFrequencyCount,
    daysOfWeek,
    monthDates,
    monthNames
  ) {
    const dayPattern = this.getFirstDefinedValue(dayPatterns);
    const monthDate = this.getFirstDefinedValue(monthDates);
    const monthName = this.getFirstDefinedValue(monthNames);

    if (dayPattern) {
      this.getRecurringDetailsFromDayPattern(
        dayPattern,
        recurrenceFrequencyCount,
        r
      );
    } else if (daysOfWeek) {
      this.getRecurringDetailsFromDaysOfWeek(daysOfWeek, r);
    } else if (monthDate) {
      this.getRecurringDetailsFromDate(monthDate, monthName, r);
    }
  }

  clearDateTimeSpanExceptions() {
    AppSettings.dateTimeSpanExceptions = [];
  }

  getRecurringDetailsFromDayPattern(dayPattern, recurrenceFrequencyCount, r) {
    r.recurrenceFrequencyCount = 1;

    switch (dayPattern.toLowerCase()) {
      case 'day':
      case 'daily': {
        r.recurrenceFrequency = 'day';
        if (recurrenceFrequencyCount) {
          r.recurrenceFrequencyCount = parseInt(recurrenceFrequencyCount, 10);
        }
        break;
      }
      case 'week':
      case 'weekly': {
        r.recurrenceFrequency = 'week';
        if (recurrenceFrequencyCount) {
          r.recurrenceFrequencyCount = parseInt(recurrenceFrequencyCount, 10);
        }
        break;
      }
      case 'month':
      case 'monthly': {
        r.recurrenceFrequency = 'month';
        if (recurrenceFrequencyCount) {
          r.recurrenceFrequencyCount = parseInt(recurrenceFrequencyCount, 10);
        }
        break;
      }
      case 'year':
      case 'yearly': {
        r.recurrenceFrequency = 'year';
        if (recurrenceFrequencyCount) {
          r.recurrenceFrequencyCount = parseInt(recurrenceFrequencyCount, 10);
        }
        break;
      }
      case 'weekday': {
        r.recurrenceFrequency = 'day';
        r.allowedWeekdays = '1,2,3,4,5';
        break;
      }
      case 'weekend': {
        r.recurrenceFrequency = 'day';
        r.allowedWeekdays = '6';
        break;
      }
      case 'morning': {
        r.recurrenceFrequency = 'day';
        r.hourOfDay = AppSettings.MORNING_HOUR;
        break;
      }
      case 'afternoon': {
        r.recurrenceFrequency = 'day';
        r.hourOfDay = AppSettings.AFTERNOON_HOUR;
        break;
      }
      case 'evening': {
        r.recurrenceFrequency = 'day';
        r.hourOfDay = AppSettings.EVENING_HOUR;
        break;
      }
      default: {
        // Empty
      }
    }
  }

  getRecurringDetailsFromDaysOfWeek(daysOfWeek, r) {
    // e.g. 'every mon, thu', 'ev mon, wed, friday'
    r.recurrenceFrequency = 'day';
    r.recurrenceFrequencyCount = 1;

    // Get array of day names
    let days = daysOfWeek
      .toLowerCase()
      .replace(/\s/g, '')
      .replace('monday', 'mon') // Standardise naming
      .replace('tuesday', 'tue')
      .replace('tues', 'tue')
      .replace('wednesday', 'wed')
      .replace('thursday', 'thu')
      .replace('thurs', 'thu')
      .replace('thur', 'thu')
      .replace('friday', 'fri')
      .replace('saturday', 'sat')
      .replace('sunday', 'sun')
      .split(',');
    // Remove duplicates
    days = days.filter((item, index) => {
      return days.indexOf(item) === index;
    });
    // Convert to isoWeekday numbers and sort
    r.allowedWeekdays = days
      .map(item => AppSettings.dayOfWeekNumbers[item])
      .sort()
      .join(',');
  }

  getRecurringDetailsFromDate(monthDate, monthName, r) {
    r.recurrenceFrequencyCount = 1;
    if (monthName) {
      r.recurrenceFrequency = 'year';
    } else {
      r.recurrenceFrequency = 'month';
    }

    r.startMonthDate = monthDate;
    r.startMonthName = monthName;
  }

  getFirstDefinedValue(values) {
    if (!values) {
      return null;
    }
    for (let index = 0; index < values.length; index++) {
      if (values[index]) {
        return values[index];
      }
    }
    return null;
  }

  getFirstRecurringDateTime(
    startDate,
    time,
    hourOfDay,
    recurrenceFrequency,
    recurrenceFrequencyCount,
    allowedWeekdays,
    startMonthDate,
    startMonthName
  ) {
    startDate = startOfDay(startDate);
    let firstDateTime = new Date(startDate.getTime());

    if (hourOfDay) {
      firstDateTime.setHours(hourOfDay, 0, 0, 0);
    } else if (time) {
      firstDateTime.setHours(getHours(time), getMinutes(time), 0, 0);
    }

    if (startMonthDate) {
      firstDateTime = setDate(firstDateTime, startMonthDate);
    }
    if (startMonthName) {
      firstDateTime = setMonth(
        firstDateTime,
        AppSettings.monthNumbers[startMonthName]
      );
    }
    let weekday;

    while (true) {
      weekday = getISODay(firstDateTime).toString();

      if (
        !isBefore(firstDateTime, startDate) &&
        (allowedWeekdays == null ||
          allowedWeekdays.split(',').indexOf(weekday) !== -1)
      ) {
        break;
      }
      if (!recurrenceFrequencyCount) {
        recurrenceFrequencyCount = 1;
      }
      if (recurrenceFrequency === 'day') {
        firstDateTime = addDays(firstDateTime, recurrenceFrequencyCount);
      } else if (recurrenceFrequency === 'week') {
        firstDateTime = addWeeks(firstDateTime, recurrenceFrequencyCount);
      } else if (recurrenceFrequency === 'month') {
        firstDateTime = addMonths(firstDateTime, recurrenceFrequencyCount);
      } else if (recurrenceFrequency === 'year') {
        firstDateTime = addYears(firstDateTime, recurrenceFrequencyCount);
      }
    }
    return firstDateTime;
  }

  insertSelectedAutocompleteTag(inputElement, tagResult, savedSelection) {
    // Update focused node with selected tag and close autocomplete
    const tagTextNode = this.getTagSpanAtCaret(inputElement, savedSelection)
      .childNodes[0];

    tagTextNode.nodeValue = tagResult.Name;
    tagTextNode.parentElement.setAttribute(
      'style',
      'background-color: ' + tagResult.Colour
    );

    // Add space after tag span if not already present
    const tagSpanNode = tagTextNode.parentNode;
    const selection = window.getSelection();
    const nextNode = tagSpanNode.nextSibling;
    if (
      nextNode &&
      nextNode.nodeType === Node.TEXT_NODE &&
      nextNode.nodeValue.length > 0 &&
      (nextNode.nodeValue.charAt(0) === ' ' ||
        nextNode.nodeValue.charAt(0) === AppSettings.NBSP)
    ) {
      // If there is a next text node starting with a space, do nothing

      // Set caret 1 space after tag span
      const range = document.createRange();
      range.setStart(nextNode, 1);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      tagSpanNode.insertAdjacentText('afterend', '\u00A0');
      const addedSpaceNode = tagSpanNode.nextSibling;

      // Set caret after added space
      const range = document.createRange();
      range.setStart(addedSpaceNode, 1);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  ensureScrolledIntoView(container, element) {
    // Determine container top and bottom
    const scrollTop = container.scrollTop;
    const scrollBottom = scrollTop + container.clientHeight;

    // Determine element top and bottom
    const elementTop = element.offsetTop;
    const elementBottom = elementTop + element.clientHeight;

    // Check if out of view
    if (elementTop < scrollTop) {
      container.scrollTop -= scrollTop - elementTop;
    } else if (elementBottom > scrollBottom) {
      container.scrollTop += elementBottom - scrollBottom;
    }
  }

  populateTaskEditString(task, taskInput) {
    taskInput.innerHTML = '';

    const titleTextNodes = task.Title.split('\n');
    let lastTitleTextNode = taskInput.appendChild(
      document.createTextNode(titleTextNodes[0])
    );

    for (let index = 1; index < titleTextNodes.length; index++) {
      taskInput.appendChild(document.createElement('br'));
      lastTitleTextNode = taskInput.appendChild(
        document.createTextNode(titleTextNodes[index])
      );
    }

    const dateStringSpan = this.generateDateStringSpan(task, taskInput);
    if (dateStringSpan != null) {
      lastTitleTextNode.nodeValue += AppSettings.NBSP;
      taskInput.appendChild(dateStringSpan);
      this.setCurrentDateTimeRegexResult(
        this.getRegexResult(
          dateStringSpan.firstChild.nodeValue,
          this.dateTimeRegex
        )
      );
    }

    for (const tag of task.Tags) {
      const tagSpan = this.generateTagSpan(tag);
      if (tagSpan) {
        taskInput.appendChild(document.createTextNode(AppSettings.NBSP));
        taskInput.appendChild(tagSpan);
      }
    }
  }

  generateDateStringSpan(task, inputElement) {
    const dateString = this.getTaskDateEditString(task);
    if (dateString == null || dateString.length === 0) {
      return null;
    }

    const span = document.createElement('span');
    span.classList.add(this.dateHighlightClass);
    span.innerHTML = dateString;

    span.addEventListener('click', event => {
      const spanNodeText = event.target.firstChild.nodeValue;
      this.removeDateTimeSpan(inputElement);
      this.addDateTimeSpanException(spanNodeText);
      this.highlightDateTime(inputElement);
      event.preventDefault();
    });

    return span;
  }

  generateTagSpan = tag => {
    if (!tag || tag.Name.length === 0) {
      return null;
    }

    const newTagSpan = document.createElement('span');
    newTagSpan.setAttribute('class', this.tagHighlightClass);
    newTagSpan.setAttribute('style', 'background-color: ' + tag.Colour);
    const newTagSpanTextNode = document.createTextNode(tag.Name);
    newTagSpan.appendChild(newTagSpanTextNode);

    return newTagSpan;
  };

  getTaskDateEditString(task) {
    if (task.Start == null) {
      return null;
    }

    let dateString = '';
    const taskStart = task.Start;
    const taskEnd = task.End;

    if (!task.IsRecurring) {
      return this.getTaskDateDisplayString(task, true, true);
    } else {
      // Recurring task
      dateString = 'Every ';

      if (task.RecurrenceWeekdays && task.RecurrenceWeekdays.length > 0) {
        dateString += task.RecurrenceWeekdays.split(',')
          .sort()
          .map(weekdayNumber =>
            format(setISODay(new Date(), parseInt(weekdayNumber, 10)), 'EEE')
          )
          .join(', ');
      } else if (
        task.RecurrenceFrequencyCount &&
        task.RecurrenceFrequencyCount > 1
      ) {
        dateString +=
          task.RecurrenceFrequencyCount +
          ' ' +
          toProperCase(task.RecurrenceFrequency) +
          's';
      } else {
        dateString += toProperCase(task.RecurrenceFrequency);
      }

      const taskRecurrenceStart = task.RecurrenceStart;

      if (!task.AllDay && format(taskRecurrenceStart, 'HHmm') !== '0000') {
        dateString += format(taskRecurrenceStart, ' HH:mm');

        const durationInMs = differenceInMilliseconds(taskEnd, taskStart);
        if (durationInMs !== AppSettings.DEFAULT_TASK_DURATION_MS) {
          dateString += ' for ';
          if (durationInMs >= AppSettings.HOUR_IN_MILLISECONDS) {
            const durationInHours =
              durationInMs / AppSettings.HOUR_IN_MILLISECONDS;
            dateString +=
              durationInHours + ' hour' + (durationInHours === 1 ? '' : 's');
          } else {
            const durationInMins =
              durationInMs / AppSettings.MINUTE_IN_MILLISECONDS;
            dateString +=
              durationInMins + ' min' + (durationInMins === 1 ? '' : 's');
          }
        }
      }

      if (task.RecurrenceStart) {
        // Add 'Starting...'
        dateString += ' Starting ' + format(taskRecurrenceStart, 'd MMM yyyy');
      }

      if (task.RecurrenceEnd) {
        // Add 'Ending...'
        const recurrenceEnd = task.RecurrenceEnd;
        dateString += ' Ending ' + format(recurrenceEnd, 'd MMM yyyy');
      }
    }

    return dateString;
  }

  getTaskDateDisplayString(task, hideDay, showDuration) {
    if (task.Start == null) {
      return '';
    }

    let dateString = '';
    const start = task.Start;
    const startDate = startOfDay(start);
    const end = task.End;

    if (isEqual(startDate, startOfYesterday())) {
      dateString = 'Yesterday';
    } else if (isEqual(startDate, startOfToday())) {
      dateString = 'Today';
    } else if (isEqual(startDate, startOfTomorrow())) {
      dateString = 'Tomorrow';
    } else if (
      isAfter(startDate, startOfToday) &&
      differenceInDays(startDate, startOfToday()) <= 6
    ) {
      dateString = format(startDate, 'EEEE');
    } else {
      if (hideDay) {
        dateString = format(startDate, 'd MMM');
      } else {
        dateString = format(startDate, 'EEE d MMM');
      }

      if (
        getYear(startDate) !== getYear(startOfToday()) ||
        isBefore(startDate, startOfToday())
      ) {
        dateString += format(startDate, ' yyyy');
      }
    }

    if (!task.AllDay) {
      dateString += format(start, ' HH:mm');

      if (showDuration) {
        const durationInMs = differenceInMilliseconds(end, start);
        if (durationInMs !== AppSettings.DEFAULT_TASK_DURATION_MS) {
          dateString += ' for ';
          if (durationInMs >= AppSettings.HOUR_IN_MILLISECONDS) {
            const durationInHours =
              durationInMs / AppSettings.HOUR_IN_MILLISECONDS;
            dateString +=
              durationInHours + ' hour' + (durationInHours === 1 ? '' : 's');
          } else {
            const durationInMinutes =
              durationInMs / AppSettings.MINUTE_IN_MILLISECONDS;
            dateString +=
              durationInMinutes + ' min' + (durationInMinutes === 1 ? '' : 's');
          }
        }
      }
    }

    return dateString;
  }

  insertTextAtCursor(text) {
    let selection;
    let range;
    if (window.getSelection) {
      selection = window.getSelection();
      if (selection.getRangeAt && selection.rangeCount) {
        range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(text));
        range.collapse();
      }
    } else if (document.selection && document.selection.createRange) {
      document.selection.createRange().text = text;
    }
  }
}
