import React, { useContext, createContext, useRef, useState } from 'react';

const AddToHomeScreenContext = createContext();
export const UseAddToHomeScreen = () => useContext(AddToHomeScreenContext);

export const AddToHomeScreenProvider = ({ children }) => {
  const [isInstallable, setIsInstallable] = useState(false);

  const deferredPrompt = useRef();

  window.addEventListener('beforeinstallprompt', e => {
    e.preventDefault(); // Prevent chrome 76+ from showing mini info bar
    deferredPrompt.current = e;
    setIsInstallable(true);
  });

  const promptToInstall = () => {
    setIsInstallable(false);
    deferredPrompt.current.prompt();
  };

  return (
    <AddToHomeScreenContext.Provider value={{ isInstallable, promptToInstall }}>
      {children}
    </AddToHomeScreenContext.Provider>
  );
};
