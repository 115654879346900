import axios from 'axios';
import { jsType, resourceStrings } from 'src/constants/constants';
import {
  getHours,
  getMinutes,
  isEqual,
  startOfDay,
  startOfToday,
} from 'date-fns';
import { useAuth0 } from 'src/providers/Auth0Provider';
import { useList } from 'src/providers/ListProvider';
import { useProgress } from 'src/providers/ProgressProvider';
import { useSnackbar } from 'src/providers/SnackbarProvider';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import TaskFilterService from 'src/services/TaskFilterService';
import TaskSortService from 'src/services/TaskSortService';
import { getLocalStorage } from 'src/util/StorageUtil';
import {
  cloneTask,
  cloneTasks,
  cloneTags,
  populateTagDates,
  populateTagsDates,
  populateTasksAndTagsDates,
  populateEventTasksDates,
  updateUnscheduledTasksSortOrder,
  findTaskIndex,
  getIndexOfFirstDifference,
  findTask,
  updateTasksAsUnscheduled,
} from 'src/util/TaskUtil';
import { ValidationError } from 'src/errors/ValidationError';

const taskSortService = new TaskSortService();
const taskFilterService = new TaskFilterService();

const TasksContext = createContext();
export const useTasks = () => useContext(TasksContext);

export const TasksProvider = ({ children }) => {
  const { isAuthenticated, getTokenSilently, logout } = useAuth0();
  const { clearListCache } = useList();
  const { showSnackbar } = useSnackbar();
  const { showProgress, hideProgress } = useProgress();

  const [tasks, setTasksInternal] = useState(null);
  const [eventTasks, setEventTasks] = useState(null);
  const [filteredTasks, setFilteredTasks] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [highlightedTasks, setHighlightedTasks] = useState([]);
  const [tags, setTags] = useState(null);
  const [isLoadError, setIsLoadError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([
    {
      key: 'filterShowScheduledTasks',
      text: resourceStrings.showScheduledTasksFilterText,
      isChecked: getLocalStorage(
        'filterShowScheduledTasks',
        true,
        jsType.boolean
      ),
    },
    {
      key: 'filterShowRecurringTasks',
      text: resourceStrings.showRecurringTasksFilterText,
      isChecked: getLocalStorage(
        'filterShowRecurringTasks',
        true,
        jsType.boolean
      ),
    },
    {
      key: 'filterShowFutureRecurringTasks',
      text: resourceStrings.showFutureRecurringTasksFilterText,
      isChecked: getLocalStorage(
        'filterShowFutureRecurringTasks',
        false,
        jsType.boolean
      ),
    },
    {
      key: 'filterShowUnscheduledTasks',
      text: resourceStrings.showUnscheduledTasksFilterText,
      isChecked: getLocalStorage(
        'filterShowUnscheduledTasks',
        true,
        jsType.boolean
      ),
    },
    {
      key: 'filterShowTodayOnly',
      text: resourceStrings.showTodayOnlyFilterText,
      isChecked: getLocalStorage('filterShowTodayOnly', false, jsType.boolean),
    },
    {
      key: 'filterShowEmptySections',
      text: resourceStrings.showEmptySectionsFilterText,
      isChecked: getLocalStorage(
        'filterShowEmptySections',
        false,
        jsType.boolean
      ),
    },
  ]);

  const taskListDropEnabledRef = useRef(true);

  const setIsTaskListDropEnabled = value => {
    taskListDropEnabledRef.current = value;
  };

  const isTaskListDropEnabled = () => {
    return taskListDropEnabledRef.current;
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    getTasksTagsAndEventsFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!tasks) {
      return;
    }

    // Set selected tasks
    const selectedTasksToSet = tasks.filter(task => task.IsSelected);
    setSelectedTasks(selectedTasksToSet);

    // Set filtered tasks
    const tasksSortedWithHeaders = taskSortService.sortTasksAndInsertHeaders(
      tasks.filter(task => !task.IsCompleted)
    );
    const filteredTasksToSet = taskFilterService.filterTasks(
      tasksSortedWithHeaders,
      filters
    );

    // Clear task list cache appropriately
    const index = getIndexOfFirstDifference(filteredTasks, filteredTasksToSet);
    setFilteredTasks(filteredTasksToSet);
    if (index !== null) {
      clearListCache(index);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, filters]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange);
  });

  const onVisibilityChange = async () => {
    if (document['hidden'] === false) {
      await getAccessToken();
    }
  };

  const getAccessToken = async () => {
    try {
      return await getTokenSilently();
    } catch (error) {
      console.log(`Error in getTokenSilently(). Logging out...`);
      logout();
      return '';
    }
  };

  const setTasks = (tasks, highlightedTasks) => {
    if (highlightedTasks) {
      setHighlightedTasks(highlightedTasks);
      setTimeout(() => setHighlightedTasks([]), 1000);
    } else {
      setHighlightedTasks([]);
    }

    setTasksInternal(tasks);
  };

  const isTaskHighlighted = task => {
    if (findTask(task, highlightedTasks)) {
      return true;
    }
    return false;
  };

  const setTaskSelectedStatus = (task, selected) => {
    const updatedTask = cloneTask(task);
    updatedTask.IsSelected = selected;

    updateTasksLocal([task], [updatedTask], false);

    return updatedTask;
  };

  const setTaskIsCompleting = (task, completingTask) => {
    const updatedTask = cloneTask(task);
    updatedTask.IsCompleting = completingTask;
    updateTasksLocal([task], [updatedTask]);
  };

  const setTasksIsCompleted = async (tasks, setAsCompleted) => {
    setTasksCompletionStatus(tasks, setAsCompleted, onTasksUpdateSnackbarUndo);
  };

  const setTasksUnscheduled = async tasks => {
    const updatedTasks = updateTasksAsUnscheduled(tasks);
    await updateTasks(tasks, updatedTasks);
    showSnackbar(
      tasks.length > 1
        ? resourceStrings.tasksUnscheduled
        : resourceStrings.taskUnscheduled
    );
  };

  const setTasksCompletionStatus = async (
    tasksToUpdate,
    completionStatus,
    onSnackbarUndo,
    updateRecurringInstance = true
  ) => {
    let updatedTasks = updateTasksCompletionStatus(
      tasksToUpdate,
      completionStatus
    );

    let result;
    if (updateRecurringInstance) {
      result = await updateTasksApi(updatedTasks, updateRecurringInstance);
    } else {
      result = await patchTaskCompletionStatusApi(
        tasksToUpdate[0].Id,
        completionStatus
      );
    }

    if (!result) {
      return;
    }

    if (!updateRecurringInstance) {
      // If updating series, get all instances of local tasks to update
      tasksToUpdate = tasks.filter(task =>
        tasksToUpdate.some(taskToUpdate => taskToUpdate.Id === task.Id)
      );
      updatedTasks = updateTasksCompletionStatus(
        tasksToUpdate,
        completionStatus
      );
    }
    updateTasksLocal(tasksToUpdate, updatedTasks);

    if (completionStatus) {
      showSnackbar(
        tasksToUpdate.length === 1
          ? resourceStrings.taskCompletedText
          : resourceStrings.tasksCompletedText,
        false,
        true,
        onSnackbarUndo,
        tasksToUpdate,
        tasks,
        updateRecurringInstance,
        updateRecurringInstance ? false : true
      );
    }
  };

  const updateTasksCompletionStatus = (tasksToUpdate, completeTasks) => {
    const updatedTasks = cloneTasks(tasksToUpdate);

    for (const updatedTask of updatedTasks) {
      updatedTask.IsSelected = false;
      updatedTask.IsCompleted = completeTasks;
      if (completeTasks) {
        updatedTask.CompletedDateTime = new Date();
      } else {
        updatedTask.CompletedDateTime = null;
      }
    }

    return updatedTasks;
  };

  const onTasksUpdateSnackbarUndo = async snackbar => {
    let result;
    if (
      snackbar.undoApiIsPatch &&
      snackbar.undoApiTasks &&
      snackbar.undoApiTasks.length > 0
    ) {
      const undoPatchTask = snackbar.undoApiTasks[0];
      result = await patchTaskCompletionStatusApi(
        undoPatchTask.Id,
        undoPatchTask.IsCompleted
      );
    } else {
      result = await updateTasksApi(
        snackbar.undoApiTasks,
        snackbar.undoApiUpdateRecurringInstance
      );
    }

    if (!result) {
      return;
    }

    setTasks(cloneTasks(snackbar.undoLocalTasks), snackbar.undoApiTasks);
  };

  const addOrUpdateTasks = async (tasksToAddOrUpdate, existingTasks) => {
    let updatedLocalTasks = null;
    showProgress('addOrUpdateTasks');
    for (let index = 0; index < tasksToAddOrUpdate.length; index++) {
      updatedLocalTasks = await addOrUpdateTask(
        tasksToAddOrUpdate[index],
        existingTasks[index],
        updatedLocalTasks,
        false
      );
    }
    showSnackbar(
      tasksToAddOrUpdate.length > 1
        ? resourceStrings.tasksUpdated
        : resourceStrings.taskUpdated
    );
    hideProgress('addOrUpdateTasks');
  };

  const addOrUpdateTask = async (
    taskToAddOrUpdate,
    existingTask,
    localTasks,
    successSnackbarEnabled = true
  ) => {
    if (taskToAddOrUpdate.Start) {
      taskToAddOrUpdate.SortOrder = 0;
    }

    if (!existingTask || existingTask.Id === -1) {
      // Add new task
      const updatedLocalTasks = await addTask(taskToAddOrUpdate, localTasks);
      if (successSnackbarEnabled) showSnackbar(resourceStrings.taskAdded);
      return updatedLocalTasks;
    } else {
      // Editing existing task

      if (!existingTask.IsRecurring) {
        // Editing a non-recurring task

        if (!taskToAddOrUpdate.IsRecurring) {
          // Update is also non-recurring
          const updatedLocalTasks = await updateTasks(
            [existingTask],
            [taskToAddOrUpdate],
            false,
            localTasks
          );
          if (updatedLocalTasks && successSnackbarEnabled)
            showSnackbar(resourceStrings.taskUpdated);
          return updatedLocalTasks;
        } else {
          // Updating a non-recurring task to a recurring task
          const updatedLocalTasks = await updateRecurringTaskSeries(
            existingTask,
            taskToAddOrUpdate,
            localTasks
          );
          if (updatedLocalTasks && successSnackbarEnabled)
            showSnackbar(resourceStrings.taskUpdated);
          return updatedLocalTasks;
        }
      } else {
        // Editing a recurring task

        if (taskToAddOrUpdate.IsRecurring) {
          // Update is also recurring

          const updatedLocalTasks = await updateRecurringTaskSeries(
            existingTask,
            taskToAddOrUpdate,
            localTasks
          );
          if (updatedLocalTasks && successSnackbarEnabled)
            showSnackbar(resourceStrings.tasksUpdated);
          return updatedLocalTasks;
        } else if (!taskToAddOrUpdate.Start) {
          // User has entered no date, so update removing recurrence completely
          const updatedLocalTasks = await updateRecurringTaskAsUnscheduled(
            existingTask,
            taskToAddOrUpdate,
            localTasks
          );
          if (updatedLocalTasks && successSnackbarEnabled)
            showSnackbar(resourceStrings.tasksUpdated);
          return updatedLocalTasks;
        } else {
          // Update is NON-recurring - update instance if allowed
          // Get due date/time again without shifting an entered time to tomorrow, to get actual entered time/date

          // setTaskDateAndRecurrence(taskToAddOrUpdate, taskInput, false); // TODO: Find a way to get this without taskInput

          const existingTaskStart = existingTask.Start;
          const existingTaskStartDate = startOfDay(existingTaskStart);
          const updatedTaskStart = taskToAddOrUpdate.Start;
          const updatedTaskStartDate = startOfDay(updatedTaskStart);
          const updatedTaskEnd = taskToAddOrUpdate.End;
          const updatedTaskAllDay = taskToAddOrUpdate.AllDay;

          // Check has not changed to another day
          if (
            !isEqual(updatedTaskStartDate, existingTaskStartDate) &&
            !isEqual(updatedTaskStartDate, startOfToday())
          ) {
            showSnackbar(resourceStrings.operationNotSupported);
            return;
          } else if (existingTask.AllDay && taskToAddOrUpdate.AllDay) {
            showSnackbar(resourceStrings.noChangesWereMade);
            return;
          } else if (
            !existingTask.AllDay &&
            !taskToAddOrUpdate.AllDay &&
            getHours(existingTaskStart) === getHours(updatedTaskStart) &&
            getMinutes(existingTaskStart) === getMinutes(updatedTaskStart)
          ) {
            showSnackbar(resourceStrings.noChangesWereMade);
            return;
          }
          // Update hour and minute of task instance
          taskToAddOrUpdate = cloneTask(existingTask);
          taskToAddOrUpdate.Start.setHours(
            getHours(updatedTaskStart),
            getMinutes(updatedTaskStart)
          );
          taskToAddOrUpdate.End.setHours(
            getHours(updatedTaskEnd),
            getMinutes(updatedTaskEnd)
          );
          taskToAddOrUpdate.AllDay = updatedTaskAllDay;
          if (updatedTaskAllDay) {
            taskToAddOrUpdate.End = null;
          }
          const updatedLocalTasks = await updateTasks(
            [existingTask],
            [taskToAddOrUpdate],
            true,
            localTasks
          );
          if (updatedLocalTasks && successSnackbarEnabled)
            showSnackbar(resourceStrings.taskUpdated);
          return updatedLocalTasks;
        }
      }
    }
  };

  const addOrUpdateTag = async (tagToAddOrUpdate, existingTag) => {
    if (!existingTag) {
      return await addTag(tagToAddOrUpdate);
    } else {
      return await updateTag(existingTag, tagToAddOrUpdate, true);
    }
  };

  const addTask = async (newTask, localTasks) => {
    if (!newTask.Start) {
      newTask.SortOrder = 1;
    }

    let addedTasks;
    addedTasks = await addTaskApi(newTask);

    if (!addedTasks && addedTasks.length === 0) {
      console.error('No added tasks returned');
      return addedTasks;
    }

    const isUnscheduled = !newTask.Start;
    const updatedLocalTasks = addTasksLocal(
      addedTasks,
      isUnscheduled,
      localTasks
    );

    // Add any new tags to local tags - only take first added task as tags would be same
    const newTags = addedTasks[0].Tags.filter(
      addedTaskTag => !tags.some(localTag => addedTaskTag.Id === localTag.Id)
    );

    if (newTags.length === 0) {
      return;
    }

    addTagsLocal(newTags);

    return updatedLocalTasks;
  };

  const addTag = async newTag => {
    let addedTag;
    addedTag = await addTagApi(newTag);

    if (!addedTag) {
      console.error('Error adding tag.');
      return false;
    }

    addTagsLocal([addedTag]);

    return addedTag;
  };

  const updateRecurringTaskSeries = async (
    existingTask,
    updatedTask,
    localTasks
  ) => {
    const taskInstances = await updateTaskApi(updatedTask, false);
    if (!taskInstances) {
      return null;
    }

    let updatedTasks = (localTasks
      ? cloneTasks(localTasks, true)
      : cloneTasks(tasks, true)
    ).filter(task => task.Id !== existingTask.Id);
    for (const taskInstance of taskInstances) {
      updatedTasks.push(taskInstance);
    }
    setTasks(updatedTasks);
    return updatedTasks;
  };

  const updateRecurringTaskAsUnscheduled = async (
    existingTask,
    updatedTask,
    localTasks
  ) => {
    const taskInstances = await updateTaskApi(updatedTask, false);
    if (!taskInstances) {
      return null;
    }

    let updatedTasks = (localTasks
      ? cloneTasks(localTasks, true)
      : cloneTasks(tasks, true)
    ).filter(task => task.Id !== existingTask.Id);
    if (updatedTask.IsSelected) {
      updatedTask.IsSelected = false;
    }
    updatedTasks.push(updatedTask);

    setTasks(updatedTasks);
    return updatedTasks;
  };

  const updateTasks = async (
    existingTasks,
    updatedTasks,
    updateRecurringInstance,
    localTasks
  ) => {
    const result = await updateTasksApi(updatedTasks, updateRecurringInstance);
    if (!result) {
      return null;
    }

    return updateTasksLocal(existingTasks, updatedTasks, true, localTasks);
  };

  const updateTag = async (existingTag, updatedTag) => {
    const result = await updateTagApi(updatedTag);

    if (!result) {
      return false;
    }

    updateTagLocal(existingTag, updatedTag);

    if (
      existingTag.Name !== updatedTag.Name ||
      existingTag.Colour !== updatedTag.Colour
    ) {
      // Update tag in tasks that contain it
      const tasksToUpdate = tasks.filter(task =>
        task.Tags.some(tag => tag.Id === updatedTag.Id)
      );

      if (tasksToUpdate.length === 0) {
        return true;
      }

      const updatedTasks = cloneTasks(tasksToUpdate);

      for (const updatedTask of updatedTasks) {
        const tagIndex = updatedTask.Tags.findIndex(
          tag => tag.Id === updatedTag.Id
        );
        updatedTask.Tags.splice(tagIndex, 1);
        updatedTask.Tags.push(updatedTag);
      }

      updateTasksLocal(tasksToUpdate, updatedTasks);
    }

    return true;
  };

  const deleteTasks = async (tasksToDelete, deleteSeries) => {
    const result = await deleteTasksApi(
      tasksToDelete,
      deleteSeries ? false : true
    );
    if (!result) {
      return;
    }

    deleteTasksLocal(tasksToDelete, deleteSeries);

    showSnackbar(
      tasksToDelete.length > 1 || deleteSeries
        ? resourceStrings.tasksDeleted
        : resourceStrings.taskDeleted
    );
  };

  const deleteTag = async tagToDelete => {
    const result = await deleteTagApi(tagToDelete);
    if (result) {
      deleteTagLocal(tagToDelete);
    }
    return result;
  };

  const addTasksLocal = (addedTasks, isUnscheduled, localTasks) => {
    let tasksToSet = localTasks
      ? cloneTasks(localTasks, true)
      : cloneTasks(tasks, true);

    if (isUnscheduled) {
      // Unscheduled task added, so increment sort order of all unscheduled tasks
      tasksToSet = updateUnscheduledTasksSortOrder(tasksToSet, +1);
    }

    for (const addedTask of addedTasks) {
      tasksToSet.push(addedTask);
    }

    setTasks(tasksToSet, addedTasks);
    return tasksToSet;
  };

  const deleteTagLocal = tagToDelete => {
    const updatedTags = cloneTags(tags);
    const tagIndex = updatedTags.findIndex(tag => tag.Id === tagToDelete.Id);
    if (tagIndex === -1) {
      return;
    }

    updatedTags.splice(tagIndex, 1);
    setTags(updatedTags);

    // Remove deleted tag from all local tasks which have it
    const tasksForUpdate = [];
    const updatedTasks = [];

    for (const task of tasks) {
      const deletedTagIndex = task.Tags.findIndex(
        tag => tag.Id === tagToDelete.Id
      );
      if (deletedTagIndex === -1) {
        continue;
      }

      tasksForUpdate.push(task);

      const updatedTask = cloneTask(task);
      updatedTask.Tags.splice(deletedTagIndex, 1);
      updatedTasks.push(updatedTask);
    }

    updateTasksLocal(tasksForUpdate, updatedTasks);
  };

  const addTagsLocal = addedTags => {
    const updatedTags = cloneTags(tags);
    addedTags.forEach(addedTag => updatedTags.push(addedTag));
    setTags(updatedTags);
  };

  const updateTasksLocalAfterDragDrop = (existingTask, updatedTask) => {
    // First update sort order of unscheduled tasks appropriately
    updatedTask.IsSelected = false;
    let tasksCloned = cloneTasks(tasks, true);
    tasksCloned = updateSortOrderForTasks(
      tasksCloned,
      existingTask.SortOrder,
      updatedTask.SortOrder
    );

    // Then update task with potential sort order change
    const taskIndex = findTaskIndex(existingTask, tasksCloned);
    if (taskIndex === -1) {
      throw new ValidationError(`Unable to find task in tasks list.`);
    }
    tasksCloned[taskIndex] = updatedTask;

    setTasks(tasksCloned, [updatedTask]);
  };

  const updateSortOrderForTasks = (tasks, oldSortOrder, newSortOrder) => {
    if (oldSortOrder === newSortOrder) {
      return tasks;
    }

    if (oldSortOrder > 0 && newSortOrder === 0) {
      // Unscheduled task being scheduled
      tasks = updateUnscheduledTasksSortOrder(tasks, -1, oldSortOrder + 1);
    } else if (oldSortOrder === 0 && newSortOrder > 0) {
      // Scheduled task being unscheduled
      tasks = updateUnscheduledTasksSortOrder(tasks, +1, newSortOrder);
    } else {
      tasks = updateUnscheduledTasksSortOrder(
        tasks,
        newSortOrder > oldSortOrder ? -1 : +1,
        oldSortOrder + (newSortOrder > oldSortOrder ? 1 : -1),
        newSortOrder
      );
    }

    return tasks;
  };

  const updateTasksLocal = (
    existingTasks,
    updatedTasks,
    clearSelection = true,
    localTasks = null
  ) => {
    if (
      !existingTasks ||
      !updatedTasks ||
      existingTasks.length !== updatedTasks.length
    ) {
      throw new ValidationError(
        `'existingTasks', 'updatedTasks' are required and should have same length.`
      );
    }

    if (existingTasks.length === 0 || updatedTasks.length === 0) {
      return;
    }

    let tasksCloned = localTasks
      ? cloneTasks(localTasks, clearSelection)
      : cloneTasks(tasks, clearSelection);

    existingTasks.forEach((existingTask, index) => {
      const updatedTask = updatedTasks[index];
      if (clearSelection && updatedTask.IsSelected) {
        updatedTask.IsSelected = false;
      }

      const tasksExistingTaskIndex = findTaskIndex(existingTask, tasks);
      if (tasksExistingTaskIndex === -1) {
        throw new ValidationError('Task not found in tasks list.');
      }
      tasksCloned[tasksExistingTaskIndex] = updatedTask;
    });

    setTasks(tasksCloned);

    return tasksCloned;
  };

  const updateTagLocal = (existingTag, updatedTag) => {
    const index = tags.findIndex(tag => tag.Id === existingTag.Id);
    if (index === -1) {
      throw new ValidationError(
        `Tag with Id '${existingTag.Id}' was not found.`
      );
    }

    const updatedTags = cloneTags(tags);
    updatedTags.splice(index, 1);
    updatedTags.push(updatedTag);
    setTags(updatedTags);
  };

  const deleteTasksLocal = (tasksToDelete, deleteSeries) => {
    if (deleteSeries) {
      tasksToDelete = tasks.filter(task =>
        tasksToDelete.some(taskToDelete => taskToDelete.Id === task.Id)
      );
    }

    const tasksCloned = cloneTasks(tasks);
    for (const taskToDelete of tasksToDelete) {
      const taskIndex = findTaskIndex(taskToDelete, tasksCloned);
      if (taskIndex !== -1) {
        tasksCloned.splice(taskIndex, 1);
      }
    }
    setTasks(tasksCloned);
  };

  const getTasksTagsAndEventsFromServer = async () => {
    setIsLoading(true);
    showProgress('getTasksTagsAndEventsFromServer');
    setIsLoadError(false);
    setTasks(null);

    let tasksReceived;
    let tagsReceived;
    let eventTasksReceived;

    try {
      [tasksReceived, tagsReceived, eventTasksReceived] = await Promise.all([
        getTasksApi(),
        getTagsApi(),
        getEventTasksApi(),
      ]);
    } catch (error) {
      showSnackbar(resourceStrings.errorLoadingTasks, true);
      setIsLoadError(true);
      setIsLoading(false);
      hideProgress('getTasksTagsAndEventsFromServer');
      return;
    }

    if (!tasksReceived || !tagsReceived || !eventTasksReceived) {
      setIsLoadError(true);
      setIsLoading(false);
      hideProgress('getTasksTagsAndEventsFromServer');
      return;
    }

    clearListCache(null, true);
    setTags(tagsReceived);
    setTasks(tasksReceived);
    setEventTasks(eventTasksReceived);
    setIsLoading(false);
    hideProgress('getTasksTagsAndEventsFromServer');
    showSnackbar(resourceStrings.tasksLoadedSuccessfully);
  };

  const showAxiosErrorSnackbar = error => {
    if (error.response) {
      showSnackbar(resourceStrings.networkOperationFailedText, true);
    } else if (error.request) {
      showSnackbar(resourceStrings.networkConnectionErrorText, true);
    } else {
      showSnackbar(resourceStrings.networkRequestFailedToSendText, true);
    }
  };

  const getTasksApi = async () => {
    try {
      showProgress('getTasksApi');
      const result = await axios.get(
        process.env.REACT_APP_API_URL + '/api/tasks',
        await getApiConfig()
      );
      hideProgress('getTasksApi');

      return populateTasksAndTagsDates(result.data);
    } catch (error) {
      showAxiosErrorSnackbar(error);
      hideProgress('getTasksApi');
      throw error;
    }
  };

  const getTagsApi = async () => {
    try {
      showProgress('getTagsApi');
      const result = await axios.get(
        process.env.REACT_APP_API_URL + '/api/tags',
        await getApiConfig()
      );
      hideProgress('getTagsApi');

      return populateTagsDates(result.data);
    } catch (error) {
      showAxiosErrorSnackbar(error);
      hideProgress('getTagsApi');
      throw error;
    }
  };

  const getEventTasksApi = async () => {
    try {
      showProgress('getEventTasksApi');
      const result = await axios.get(
        process.env.REACT_APP_API_URL + '/api/tasks/google-calendar-events',
        await getApiConfig()
      );
      hideProgress('getEventTasksApi');

      return populateEventTasksDates(result.data);
    } catch (error) {
      showAxiosErrorSnackbar(error);
      hideProgress('getEventTasksApi');
      throw error;
    }
  };

  const addTaskApi = async task => {
    task.CreatedDateTime = new Date();
    task.UpdatedDateTime = new Date();
    try {
      showProgress('addTaskApi');
      const result = await axios.post(
        process.env.REACT_APP_API_URL + '/api/tasks',
        task,
        await getApiConfig()
      );
      hideProgress('addTaskApi');

      return populateTasksAndTagsDates(result.data);
    } catch (error) {
      showAxiosErrorSnackbar(error);
      console.log(error);
      hideProgress('addTaskApi');
      return null;
    }
  };

  const addTagApi = async tag => {
    tag.CreatedDateTime = new Date();
    tag.UpdatedDateTime = new Date();
    try {
      showProgress('addTagApi');
      const result = await axios.post(
        process.env.REACT_APP_API_URL + '/api/tags',
        tag,
        await getApiConfig()
      );
      hideProgress('addTagApi');

      return populateTagDates(result.data);
    } catch (error) {
      showAxiosErrorSnackbar(error);
      console.log(error);
      hideProgress('addTagApi');

      return null;
    }
  };

  const updateTasksApi = async (tasks, updateRecurringInstance) => {
    if (
      updateRecurringInstance === undefined &&
      tasks &&
      tasks.length > 0 &&
      tasks[0].IsRecurring
    ) {
      updateRecurringInstance = true;
    }
    showProgress('updateTasksApi');
    let result;
    for (const task of tasks) {
      result = await updateTaskApi(task, updateRecurringInstance);
      if (!result) {
        hideProgress('updateTasksApi');
        return null;
      }
    }
    hideProgress('updateTasksApi');
    return result;
  };

  const updateTaskApi = async (task, updateRecurringInstance) => {
    task.UpdatedDateTime = new Date();
    try {
      showProgress('updateTaskApi');
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/tasks/${task.Id}${
          task.IsRecurring && updateRecurringInstance
            ? '?updateRecurringInstance=true'
            : ''
        }`,
        task,
        await getApiConfig()
      );
      hideProgress('updateTaskApi');

      return populateTasksAndTagsDates(result.data);
    } catch (error) {
      showAxiosErrorSnackbar(error);
      console.log(error);
      hideProgress('updateTaskApi');
      return null;
    }
  };

  const patchTaskCompletionStatusApi = async (taskId, completionStatus) => {
    const patchDocument = [
      {
        path: '/isCompleted',
        op: 'replace',
        value: completionStatus ? true : false,
      },
    ];
    if (completionStatus === true) {
      patchDocument.push({
        path: '/completedDateTime',
        op: 'replace',
        value: new Date(),
      });
    } else {
      patchDocument.push({
        path: '/completedDateTime',
        op: 'remove',
      });
    }

    try {
      showProgress('patchTaskCompletionStatusApi');
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/tasks/${taskId}`,
        patchDocument,
        await getApiConfig()
      );
      hideProgress('patchTaskCompletionStatusApi');

      return true;
    } catch (error) {
      showAxiosErrorSnackbar(error);
      console.log(error);
      hideProgress('patchTaskCompletionStatusApi');

      return null;
    }
  };

  const updateTagApi = async tag => {
    tag.UpdatedDateTime = new Date();
    try {
      showProgress('updateTagApi');
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/tags/${tag.Id}`,
        tag,
        await getApiConfig()
      );
      hideProgress('updateTagApi');

      return true;
    } catch (error) {
      showAxiosErrorSnackbar(error);
      console.log(error);
      hideProgress('updateTagApi');

      return false;
    }
  };

  const deleteTasksApi = async (tasks, deleteRecurringInstance) => {
    try {
      showProgress('deleteTasksApi');
      for (const task of tasks) {
        task.UpdatedDateTime = new Date();
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/tasks/${task.Id}${
            deleteRecurringInstance ? '/delete-recurring-instance' : ''
          }`,
          await getApiTaskDeleteConfig(task)
        );
      }
      hideProgress('deleteTasksApi');

      return true;
    } catch (error) {
      showAxiosErrorSnackbar(error);
      console.log(error);
      hideProgress('deleteTasksApi');

      return false;
    }
  };

  const deleteTagApi = async tag => {
    try {
      showProgress('deleteTagApi');
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/tags/${tag.Id}`,
        await getApiTagDeleteConfig(tag)
      );
      hideProgress('deleteTagApi');

      return true;
    } catch (error) {
      showAxiosErrorSnackbar(error);
      console.log(error);
      hideProgress('deleteTagApi');

      return false;
    }
  };

  const getApiConfig = async () => {
    const accessToken = await getAccessToken();
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
        'X-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      timeout: 30000,
    };
  };

  // TODO: Update server to not need Content-Type on DELETE operation so can remove this
  const getApiTaskDeleteConfig = async task => {
    const accessToken = await getAccessToken();
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
        'X-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      timeout: 30000,
      data: task,
    };
  };

  const getApiTagDeleteConfig = async tag => {
    const accessToken = await getAccessToken();
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
        'X-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      timeout: 30000,
      data: tag,
    };
  };

  return (
    <TasksContext.Provider
      value={{
        tasks,
        setTasks,
        addOrUpdateTask,
        addOrUpdateTasks,
        updateTasks,
        updateTasksLocal,
        updateTasksLocalAfterDragDrop,
        updateTasksApi,
        setTasksCompletionStatus,
        deleteTasks,
        filters,
        setFilters,
        filteredTasks,
        highlightedTasks,
        isTaskHighlighted,
        selectedTasks,
        setTaskSelectedStatus,
        setTaskIsCompleting,
        setTasksIsCompleted,
        tags,
        addOrUpdateTag,
        deleteTag,
        eventTasks,
        isLoading,
        isLoadError,
        getTasksTagsAndEventsFromServer,
        onTasksUpdateSnackbarUndo,
        isTaskListDropEnabled,
        setIsTaskListDropEnabled,
        setTasksUnscheduled,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};
