import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';

import { useAuth0 } from 'src/providers/Auth0Provider';
import NotFound from 'src/components/NotFound';
import Login from 'src/components/Login';
import Layout from 'src/components/Layout';
import TaskList from 'src/components/TaskList';
import FullCalendar from 'src/components/FullCalendar';
import SimpleSplitPane from 'src/components/SimpleSplitPane';
import { useList } from 'src/providers/ListProvider';

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { clearListCache } = useList();

  const guttersMobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  // Redraw list on browser resize end
  let resizeTimeout;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      clearListCache(null, true);
    }, 250);
  });

  if (isLoading) {
    return <div></div>;
  }

  if (!isAuthenticated) {
    return <Login></Login>;
  }

  return (
    <BrowserRouter>
      {isDesktop ? (
        <Switch>
          <Route
            exact
            path={['/', '/tasks', '/calendar']}
            render={() => (
              <Layout hideNavigation>
                <SimpleSplitPane />
              </Layout>
            )}
          />
          <Route component={NotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            path={['/', '/tasks']}
            render={() => (
              <Layout>
                <TaskList gutters={guttersMobile} />
              </Layout>
            )}
          />
          <Route
            exact
            path="/calendar"
            render={() => (
              <Layout>
                <FullCalendar />
              </Layout>
            )}
          />
          <Route component={NotFound} />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default App;
