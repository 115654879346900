import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AutoSizer } from 'react-virtualized';
import { sortableContainer } from 'react-sortable-hoc';
import {
  isToday,
  startOfDay,
  differenceInSeconds,
  getYear,
  getMonth,
  getDate,
  addSeconds,
} from 'date-fns';

import { useTasks } from 'src/providers/TasksProvider';
import { useList } from 'src/providers/ListProvider';
import { useSnackbar } from 'src/providers/SnackbarProvider';
import VirtualList from 'src/components/VirtualList';
import TaskRowSkeleton from 'src/components/TaskRowSkeleton';
import { resourceStrings, taskType } from 'src/constants/constants';
import {
  getSectionHeader,
  getSortOrderInSection,
  updateTaskAsUnscheduled,
  updateTasksAsScheduled,
  cloneTask,
  rowIsHeader,
  findTaskIndex,
} from 'src/util/TaskUtil';
import FilterMenu from 'src/components/FilterMenu';

const useStyles = makeStyles(() => ({
  center: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  typographyCenter: {
    textAlign: 'center',
    flex: 'auto',
  },
  taskList: {
    flex: 1,
    position: 'relative',
  },
}));

const SortableVirtualList = sortableContainer(VirtualList);

const TaskList = ({ gutters }) => {
  const classes = useStyles();

  const { scrollListToIndex } = useList();
  const {
    tasks,
    updateTasksLocalAfterDragDrop,
    updateTasksApi,
    filteredTasks,
    highlightedTasks,
    setTaskSelectedStatus,
    isLoading,
    isLoadError,
    onTasksUpdateSnackbarUndo,
    isTaskListDropEnabled,
    setIsTaskListDropEnabled,
  } = useTasks();
  const { showSnackbar } = useSnackbar();

  const [dragIndex, setDragIndex] = useState(null);

  useEffect(() => {
    if (!filteredTasks) {
      return;
    }

    scrollToFirstHighlightedTask();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTasks]);

  const scrollToFirstHighlightedTask = () => {
    if (highlightedTasks.length === 0) {
      return;
    }

    const indexInFilteredTasks = findTaskIndex(
      highlightedTasks[0],
      filteredTasks
    );
    if (indexInFilteredTasks === -1) {
      return;
    }

    scrollListToIndex(indexInFilteredTasks);
  };

  const onTaskClick = (task, selected) => {
    setTaskSelectedStatus(task, selected);
  };

  const shouldCancelSort = event => {
    if (rowIsHeader(event.target)) {
      return true;
    }
  };

  const onBeforeSortStart = ({ index }) => {
    setDragIndex(index);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (!isTaskListDropEnabled()) {
      setIsTaskListDropEnabled(true);
      return;
    }

    setDragIndex(null);
    if (oldIndex === newIndex) {
      return;
    }

    updateDraggedTask(oldIndex, newIndex);
  };

  const updateDraggedTask = async (oldIndex, newIndex) => {
    const task = filteredTasks[oldIndex];

    if (newIndex === 0) {
      showSnackbar(resourceStrings.cannotDropHereText);
      return;
    }

    if (task.IsRecurring) {
      showSnackbar(resourceStrings.cannotReorderRecurringTaskInstancesText);
      return;
    }

    const dragFromSectionHeader = getSectionHeader(filteredTasks, oldIndex);
    const dragToSectionHeader = getSectionHeader(
      filteredTasks,
      newIndex > oldIndex ? newIndex : newIndex - 1
    );
    if (dragToSectionHeader.Title === resourceStrings.overdueText) {
      showSnackbar(resourceStrings.cannotDropInOverdueSectionText);
      return;
    }

    if (dragToSectionHeader.Title === resourceStrings.after7DaysText) {
      showSnackbar(resourceStrings.cannotDropInAfter7DaysSectionText);
      return;
    }

    if (
      dragFromSectionHeader.Title === dragToSectionHeader.Title &&
      dragFromSectionHeader.Title !== resourceStrings.unscheduledTasksText
    ) {
      showSnackbar(resourceStrings.droppedInSameSectionNoChangedMadeText);
      return;
    }

    if (
      dragFromSectionHeader.Title === resourceStrings.overdueText &&
      dragToSectionHeader.Title === resourceStrings.todayText &&
      isToday(task.Start)
    ) {
      // Dragged from Overdue to Today, but already scheduled for today
      showSnackbar(resourceStrings.alreadyScheduledForTodayNoChangedMadeText);
      return;
    }

    let updatedTask = cloneTask(task);

    if (dragToSectionHeader.Title === resourceStrings.unscheduledTasksText) {
      // Dragged into unscheduled tasks section

      if (
        dragFromSectionHeader.Title !== resourceStrings.unscheduledTasksText
      ) {
        // Dragged from a scheduled tasks section
        updatedTask.SortOrder = getSortOrderInSection(
          filteredTasks,
          newIndex + 1
        );

        updatedTask = updateTaskAsUnscheduled(updatedTask);
      } else {
        // Dragged within unscheduled tasks section
        updatedTask.SortOrder = getSortOrderInSection(filteredTasks, newIndex);
      }
    } else {
      // Dragged into a scheduled tasks section

      if (
        dragFromSectionHeader.Title === resourceStrings.unscheduledTasksText
      ) {
        // Dragged from unscheduled tasks section, so remove sort order and set date
        updatedTask.SortOrder = 0;
        updatedTask = updateTasksAsScheduled(
          updatedTask,
          startOfDay(dragToSectionHeader.Start),
          null,
          true
        );
      } else {
        // Dragged from a scheduled tasks section, so update the date
        if (!updatedTask.AllDay) {
          // Task is timed
          let taskDurationInSeconds = null;
          if (updatedTask.End && updatedTask.Start) {
            taskDurationInSeconds = differenceInSeconds(
              updatedTask.End,
              updatedTask.Start
            );
          }

          const startYear = getYear(dragToSectionHeader.Start);
          const startMonth = getMonth(dragToSectionHeader.Start);
          const startDate = getDate(dragToSectionHeader.Start);

          updatedTask.Start.setFullYear(startYear, startMonth, startDate);
          updatedTask.End = addSeconds(
            updatedTask.Start,
            taskDurationInSeconds
          );
        } else {
          // Task is AllDay
          updatedTask.Start = startOfDay(dragToSectionHeader.Start);
          updatedTask.End = null;
        }
      }
    }

    updateTasksLocalAfterDragDrop(task, updatedTask);
    await updateTasksApi([updatedTask]);

    showSnackbar(
      resourceStrings.reorderedText,
      false,
      true,
      onTasksUpdateSnackbarUndo,
      [task],
      tasks
    );
  };

  let tasksList;
  const activeTasksCount = tasks
    ? tasks.filter(task => task.IsCompleted === false).length
    : null;
  const filteredTasksCount = filteredTasks ? filteredTasks.length : 0;

  const filterMenu = (
    <FilterMenu marginRight={gutters ? '3rem' : '2rem'}></FilterMenu>
  );

  // Load error
  if (isLoadError) {
    tasksList = (
      <div className={classes.center}>
        <Typography className={classes.typographyCenter} variant="body1">
          Error loading tasks.
          <br />
          <br />
          Please try again in a few moments.
        </Typography>
      </div>
    );
  }
  // Task list
  else if (filteredTasksCount > 0) {
    tasksList = (
      <div className={classes.taskList} data-cy="task-list-tasks">
        {filterMenu}
        <AutoSizer>
          {({ height, width }) => (
            <SortableVirtualList
              height={height}
              width={width}
              filteredTasks={filteredTasks}
              onTaskClick={onTaskClick}
              dragIndex={dragIndex}
              pressDelay={500}
              shouldCancelStart={shouldCancelSort}
              updateBeforeSortStart={onBeforeSortStart}
              onSortEnd={onSortEnd}
              helperClass="sortable-dragging"
              gutters={gutters}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
  // First load of tasks
  else if (isLoading) {
    tasksList = (
      <div
        style={{ flex: 1, overflowY: 'hidden' }}
        data-cy="task-list-loading-skeleton"
      >
        <TaskRowSkeleton
          gutters={gutters}
          type={taskType.Header}
        ></TaskRowSkeleton>
        {Array(20)
          .fill()
          .map((item, index) => (
            <TaskRowSkeleton
              key={index}
              gutters={gutters}
              type={taskType.Task}
            ></TaskRowSkeleton>
          ))}
      </div>
    );
  }
  // No active tasks
  else if (activeTasksCount !== null && activeTasksCount === 0) {
    tasksList = (
      <div className={classes.center}>
        {filterMenu}
        <Typography
          className={classes.typographyCenter}
          variant="body1"
          data-cy="task-list-no-tasks-message"
        >
          You don't have any tasks.
          <br />
          <br />
          Add a task to get started with Todo Calendar.
        </Typography>
      </div>
    );
  }
  // Tasks hidden by filters
  else if (
    activeTasksCount !== null &&
    activeTasksCount > 0 &&
    filteredTasksCount === 0
  ) {
    tasksList = (
      <div className={classes.center}>
        {filterMenu}
        <Typography className={classes.typographyCenter} variant="body1">
          Task filters are hiding all tasks.
          <br />
          <br />
          Check the task filters at the top of the screen.
        </Typography>
      </div>
    );
  }
  // Loading
  else {
    tasksList = <div className={classes.center}></div>;
  }

  return <>{tasksList}</>;
};

export default TaskList;
