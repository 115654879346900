import React from 'react';

import { List, CellMeasurer } from 'react-virtualized';
import { sortableElement } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/styles';

import { useList } from 'src/providers/ListProvider';
import TaskRow from 'src/components/TaskRow';
import classNames from 'classnames';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { useRef } from 'react';

const useStyles = makeStyles({
  virtualList: {
    '&:focus': {
      outline: 'none',
    },
  },
  overflow: {
    overflow: 'hidden',
  },
});

const SortableItem = sortableElement(({ value, style, classes }) => {
  return (
    <div style={style} className={classNames(classes.overflow, 'sortable-row')}>
      {value}
    </div>
  );
});

const VirtualList = ({
  height,
  width,
  filteredTasks,
  onTaskClick,
  dragIndex,
  gutters,
}) => {
  const classes = useStyles();

  const { cellMeasurerCache, listRef } = useList();

  const draggableRef = useRef();

  const onRowsRendered = () => {
    if (draggableRef.current) {
      return;
    }
    const sortableList = document.querySelector('.ReactVirtualized__List');
    draggableRef.current = new ThirdPartyDraggable(sortableList, {
      itemSelector: '.sortable-row',
    });
  };

  const rowRenderer = ({ index, key, parent, style }) => {
    const value = (
      <CellMeasurer
        cache={cellMeasurerCache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
      >
        <TaskRow
          task={filteredTasks[index]}
          isFirst={index === 0}
          onTaskClick={onTaskClick}
          dragging={dragIndex === index ? true : false}
          gutters={gutters}
        />
      </CellMeasurer>
    );

    return (
      <SortableItem
        key={key}
        index={index}
        value={value}
        style={style}
        classes={classes}
      />
    );
  };

  return (
    <List
      ref={list => (listRef.current = list)}
      height={height}
      width={width}
      rowCount={filteredTasks.length}
      rowHeight={cellMeasurerCache.rowHeight}
      rowRenderer={rowRenderer}
      deferredMeasurementCache={cellMeasurerCache}
      overscanRowCount={0}
      className={classes.virtualList}
      onRowsRendered={onRowsRendered}
    />
  );
};
export default VirtualList;
