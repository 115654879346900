import React, { useState, useRef } from 'react';

import { Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '0.8rem',
  },
  tooltipPlacementBottom: {
    margin: '1rem 0',
  },
  tooltipPlacementLeft: {
    margin: '0 0',
  },
});

const SimpleTooltip = ({ children, title, placement }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const timeoutRef = useRef();

  const onOpen = () => {
    setOpen(true);
    timeoutRef.current = setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const onClose = () => {
    clearTimeout(timeoutRef.current);
    setOpen(false);
  };

  return (
    <Tooltip
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title={title}
      enterDelay={500}
      placement={placement ? placement : 'bottom'}
      classes={{
        tooltip: classes.tooltip,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
        tooltipPlacementLeft: classes.tooltipPlacementLeft,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default SimpleTooltip;
