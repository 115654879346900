import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  IconButton,
  Toolbar,
  useMediaQuery,
  Popover,
  Slider,
  Grid,
} from '@material-ui/core';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import TodayIcon from '@material-ui/icons/Today';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomIcon from '@material-ui/icons/ZoomOutMap';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import {
  format,
  startOfToday,
  isAfter,
  addHours,
  addMinutes,
  getHours,
  getMinutes,
  isToday,
  isSameDay,
  parseISO,
  addSeconds,
  differenceInSeconds,
  isValid,
} from 'date-fns';
import { useHotkeys } from 'react-hotkeys-hook';
import classNames from 'classnames';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'src/fullcalendar.scss';

import { useTasks } from 'src/providers/TasksProvider';
import { useSnackbar } from 'src/providers/SnackbarProvider';
import { useTaskDialog } from 'src/providers/TaskDialogProvider';
import {
  taskListsDifferByThisPropertyOnly,
  cloneTask,
  findTaskByIdAndStart,
} from 'src/util/TaskUtil';
import {
  getCalendarEventsFromTasks,
  getEventIdForTask,
  getEventColoursForTask,
  getCalendarDurationString,
  getCalendarHeaderDateString,
} from 'src/util/CalendarUtil';
import { resourceStrings, jsType, constants } from 'src/constants/constants';
import { Task } from 'src/model/Task';
import { debounce } from 'src/util/Utils';
import SimpleTooltip from 'src/components/SimpleTooltip';
import SimpleDropdown from 'src/components/SimpleDropdown';
import { isMobile } from 'src/util/DeviceUtil';
import { getLocalStorage, setLocalStorage } from 'src/util/StorageUtil';
import CalendarFilterMenu from 'src/components/CalendarFilterMenu';

const eventHighlight = {
  position: 'absolute',
  content: '""',
  backgroundColor: 'rgba(65, 131, 241, 0.3)',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',

  opacity: 0,
};

const useStyles = makeStyles({
  calendar: {
    flex: 1,
    '& a.fc-time-grid-event.fc-event:before': eventHighlight,
    '& a.fc-day-grid-event.fc-event:before': eventHighlight,
  },
  toolbar: {
    borderBottom: '1px solid rgba(0,0,0,.10)',
  },
  eventSelected: {
    '&:before': {
      opacity: '1 !important',
    },
  },
  iconButtonSmall: {
    padding: '0.5rem',
    position: 'relative',
    top: '-1.2rem',
    left: '-0.1rem',
  },
  iconButton: {
    zIndex: 5,
  },
  recurringIcon: {
    width: '0.95rem',
    height: '0.95rem',
    fontSize: '0.95rem',
    // lineHeight: '0.75rem',
    verticalAlign: 'text-bottom',
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
  },
  popover: {
    padding: '1rem 0.2rem 0rem 0.2rem',
  },
  slider: {
    marginLeft: '1rem',
    marginRight: '1rem',
    width: '12rem',
  },
  sliderIconButton: {
    marginTop: '-0.7rem',
  },
  eventHighlighted: {
    animationName: '$eventHighlighted',
    animationDuration: '2s',
    animationDelay: '100ms',
    '&:before': {
      animationName: '$eventBackgroundHighlighted',
      animationDuration: '2s',
      animationDelay: '100ms',
    },
  },
  '@keyframes eventHighlighted': {
    '0%': {},
    '15%': {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '85%': {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '100%': {},
  },
  '@keyframes eventBackgroundHighlighted': {
    '0%': {
      opacity: 0,
    },
    '15%': {
      opacity: 1,
    },
    '85%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
});

const allDayTasksMinCount = 2;
const allDayTasksMaxCount = 12;

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const FullCalendar = () => {
  const classes = useStyles();

  const {
    tasks,
    highlightedTasks,
    isTaskHighlighted,
    setTaskSelectedStatus,
    updateTasksLocal,
    updateTasksApi,
    eventTasks,
    setIsTaskListDropEnabled,
  } = useTasks();
  const { showSnackbar } = useSnackbar();
  const { openTaskDialog } = useTaskDialog();

  const calendarElementRef = useRef();
  const calendarRef = useRef();
  const swipeRef = useRef();

  const getInitialZoomLevel = () => {
    return getLocalStorage('calendarZoomLevel', 1, jsType.number);
  };

  const storeZoomLevel = zoomLevel => {
    setLocalStorage('calendarZoomLevel', zoomLevel);
  };

  const [zoomAnchorElement, setZoomAnchorElement] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(getInitialZoomLevel);
  const minZoomLevel = 1;
  const maxZoomLevel = 5;

  useHotkeys('-,shift+-', () => incrementZoomLevel(-1), [{}]);
  useHotkeys('=,shift+=', () => incrementZoomLevel(1), [{}]);
  const incrementZoomLevel = incrementOrDecrement => {
    if (zoomLevel == null) {
      return;
    }

    const newZoomLevel = zoomLevel + incrementOrDecrement;
    if (newZoomLevel < minZoomLevel || newZoomLevel > maxZoomLevel) {
      return;
    }

    updateZoomLevel(newZoomLevel);
  };

  const onZoomLevelSliderChange = (event, value) => {
    updateZoomLevel(value);
  };

  useEffect(() => {
    updateCalendarZoomLevel(zoomLevel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomLevel]);

  const updateZoomLevel = newZoomLevel => {
    setZoomLevel(newZoomLevel);
    storeZoomLevel(newZoomLevel);

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  const zoomingRef = useRef(false);
  const zoomingTimeoutRef = useRef();

  const updateCalendarZoomLevel = newZoomLevel => {
    const newRowHeightRem = 2.0 + newZoomLevel * 0.8;
    const newTimeOffsetRem = -1.0 - newZoomLevel * 0.4;

    let style = document.querySelector('html > head > style#zoom-style');
    if (style) {
      style.innerHTML = '';
    } else {
      style = document.createElement('style');
      style.id = 'zoom-style';
    }

    style.appendChild(
      document.createTextNode(`
        .fc-time-grid .fc-slats td { 
          height: ${newRowHeightRem}rem;
        }

        td.fc-axis.fc-time.fc-widget-content > span { 
          position: relative; 
          top: ${newTimeOffsetRem}rem;
        }`)
    );
    const head = document.querySelector('html > head');
    head.appendChild(style);

    if (!calendarRef.current) {
      return;
    }

    clearTimeout(zoomingTimeoutRef.current);
    zoomingRef.current = true;

    initialiseCalendar();
    setTimeout(() => {
      if (tasks) rerenderEvents(tasks, 'tasks', false);
      if (eventTasks) rerenderEvents(eventTasks, 'eventTasks', true);
    }, 0);

    const scrollPercent = getLocalStorage(
      'calendarScrollPercent',
      null,
      jsType.number
    );
    const scroller = getScrollerElement();
    if (scrollPercent == null || !scroller) {
      return;
    }
    const newScrollTop =
      scrollPercent * scroller.scrollHeight - scroller.clientHeight / 2;
    setScrollTop(newScrollTop);
    storeScrollTop(newScrollTop);
    storeScrollPercent(getScrollPercent(scroller));

    // TODO: Find more elegant way to prevent onScroll event while zooming
    // Doing this to avoid calendar jumping during quick successive zooms
    zoomingTimeoutRef.current = setTimeout(() => {
      zoomingRef.current = false;
    }, 500);
  };

  const onZoomClick = event => {
    setZoomAnchorElement(event.target);
  };

  const onZoomPopoverClose = () => {
    setZoomAnchorElement(null);
  };

  const isZoomPopoverOpen = Boolean(zoomAnchorElement);

  const onTouchStart = event => {
    if (event.touches.length === 0) {
      return;
    }

    swipeRef.current = {
      touchStartX: event.touches[0].clientX,
      touchStartY: event.touches[0].clientY,
      touchStartDateTime: new Date(),
    };
  };

  const onTouchMove = () => {
    removeWavesFromAllEvents();
  };

  const onTouchEnd = event => {
    setTimeout(() => addWavesToAllEvents(true), 400);

    const swipedResult = getSwipedResult(event, new Date());
    if (!swipedResult) {
      return;
    } else if (swipedResult === 'right') {
      goToPreviousPeriod();
    } else if (swipedResult === 'left') {
      goToNextPeriod();
    }
  };

  const getSwipedResult = (event, dateTime) => {
    if (!swipeRef.current || event.changedTouches.length === 0) {
      return;
    }

    const touchEndX = event.changedTouches[0].clientX;
    const touchEndY = event.changedTouches[0].clientY;
    const touchEndDateTime = dateTime;

    const xThreshold = 100;
    const yThreshold = 50;
    const timeThreshold = 300;

    if (
      Math.abs(touchEndX - swipeRef.current.touchStartX) > xThreshold &&
      Math.abs(touchEndY - swipeRef.current.touchStartY) < yThreshold &&
      touchEndDateTime - swipeRef.current.touchStartDateTime < timeThreshold
    ) {
      if (touchEndX > swipeRef.current.touchStartX) {
        return 'right';
      } else {
        return 'left';
      }
    }

    return null;
  };

  const removeWavesFromAllEvents = () => {
    const eventElements = document.querySelectorAll(
      'a.fc-time-grid-event.fc-event.waves-effect.waves-effect-color'
    );
    for (const eventElement of eventElements) {
      eventElement.classList.remove('waves-effect');
      eventElement.classList.remove('waves-effect-color');
    }
  };

  const addWavesToAllEvents = () => {
    const eventElements = document.querySelectorAll(
      'a.fc-time-grid-event.fc-event'
    );
    for (const eventElement of eventElements) {
      eventElement.classList.add('waves-effect');
      eventElement.classList.add('waves-effect-color');
    }
  };

  const getInitialScrollTopToSet = () => {
    const currentDateTime = new Date();

    const storedScrollTop = getLocalStorage(
      'calendarScrollTop',
      null,
      jsType.number
    );
    const scrollTopStoredAt = new Date(
      getLocalStorage('calendarScrollTopStoredAt', null, jsType.number)
    );

    if (isAfter(addHours(scrollTopStoredAt, 2), currentDateTime)) {
      // If value stored in the last 2 hours, use it
      return storedScrollTop;
    } else {
      return null;
    }
  };

  const getInitialSelectedDate = () => {
    const currentDateTime = new Date();

    const storedSelectedDate = new Date(
      getLocalStorage('selectedDate', startOfToday().getTime(), jsType.number)
    );
    const selectedDateStoredAt = new Date(
      getLocalStorage(
        'selectedDateStoredAt',
        currentDateTime.getTime(),
        jsType.number
      )
    );

    if (isAfter(addHours(selectedDateStoredAt, 2), currentDateTime)) {
      // If value stored in the last 2 hours, use it
      return storedSelectedDate;
    } else {
      return startOfToday();
    }
  };

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const getIsDesktop = () => {
    return window.innerWidth >= constants.smBreakpoint ? true : false;
  };

  const desktopViews = [
    { value: 'timeGridDay', text: 'Day' },
    { value: 'timeGridWeek', text: 'Week' },
    { value: 'dayGridMonth', text: 'Month' },
    { value: 'timeGridXDay', text: '3 Days' },
  ];

  const mobileViews = [
    { value: 'timeGridDay', text: 'Day' },
    { value: 'timeGridXDay', text: '3 Days' },
  ];

  const desktopOnlyViews = [
    { value: 'timeGridWeek', text: 'Week' },
    { value: 'dayGridMonth', text: 'Month' },
  ];

  const storeSelectedView = view => {
    setLocalStorage('selectedView', view);
    setLocalStorage('selectedViewStoredAt', new Date().getTime());
  };

  const getInitialSelectedView = () => {
    const currentDateTime = new Date();
    const defaultView = 'timeGridDay';

    let storedSelectedView = getLocalStorage('selectedView', defaultView);
    const selectedViewStoredAt = new Date(
      getLocalStorage(
        'selectedViewStoredAt',
        currentDateTime.getTime(),
        jsType.number
      )
    );

    if (isAfter(addHours(selectedViewStoredAt, 2), currentDateTime)) {
      // If value stored in the last 2 hours, use it

      if (
        !getIsDesktop() &&
        desktopOnlyViews.some(
          calendarView => calendarView.value === storedSelectedView
        )
      ) {
        // If on mobile but a desktop only view (e.g. month) is selected, use a mobile supported view
        storedSelectedView = mobileViews[0].value;
        storeSelectedView(mobileViews[0].value);
      }

      return storedSelectedView;
    } else {
      return defaultView;
    }
  };

  const [selectedDate, setSelectedDate] = useState(getInitialSelectedDate());
  const [headerText, setHeaderText] = useState('');
  const [selectedView, setSelectedView] = useState(getInitialSelectedView());

  const [showScheduledTasks, setShowScheduledTasks] = useState(
    getLocalStorage('filterCalendarShowScheduledTasks', true, jsType.boolean)
  );
  const [showRecurringTasks, setShowRecurringTasks] = useState(
    getLocalStorage('filterCalendarShowRecurringTasks', true, jsType.boolean)
  );
  const [showEvents, setShowEvents] = useState(
    getLocalStorage('filterCalendarShowEvents', true, jsType.boolean)
  );

  useEffect(() => {
    initialiseCalendar();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialiseCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.destroy();
    }

    calendarRef.current = new Calendar(calendarElementRef.current, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      header: false,
      selectable: true,
      selectMirror: true,
      editable: true,
      height: 'parent',
      firstDay: 1,
      defaultView: selectedView,
      eventLimit: allDayTasksMinCount,
      eventLimitText: '',
      allDayText: '',
      slotDuration: '00:30:00',
      snapDuration: '00:15:00',
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      },
      timeFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      },
      views: {
        timeGridXDay: {
          type: 'timeGrid',
          duration: { days: 3 },
          columnHeaderText: date => format(date, 'EEE d'),
        },
        timeGridDay: {
          columnHeaderText: date => format(date, 'EEE d'),
        },
        timeGridWeek: {
          columnHeaderText: date => format(date, 'EEE d'),
        },
      },
      nowIndicator: true,
      // droppable: true,
      // rerenderDelay: 0,
      defaultDate: selectedDate,
      defaultTimedEventDuration: '00:30:00',
      eventOrder: 'start,-duration,allDay,sortOrder,title',
      eventLimitClick: onEventLimitClick,
      eventSourceSuccess: onEventSourceSuccess,
      datesDestroy: onDatesDestroy,
      datesRender: onDatesRender,
      viewSkeletonRender: onViewSkeletonRender,
    });
    calendarRef.current.render();

    const scrollTop = getInitialScrollTopToSet();
    if (scrollTop) {
      setScrollTop(scrollTop);
    } else if (isToday(selectedDate)) {
      scrollCalendarToNow();
    }

    calendarElementRef.current.addEventListener('touchstart', onTouchStart);
    calendarElementRef.current.addEventListener('touchmove', onTouchMove);
    calendarElementRef.current.addEventListener('touchend', onTouchEnd);
  };

  useEffect(() => {
    calendarRef.current.off('eventClick');
    calendarRef.current.on('eventClick', onEventClick);

    calendarRef.current.off('eventRender');
    calendarRef.current.on('eventRender', onEventRender);

    calendarRef.current.off('eventResize');
    calendarRef.current.on('eventResize', onEventResize);

    calendarRef.current.off('eventDrop');
    calendarRef.current.on('eventDrop', onEventDrop);

    calendarRef.current.off('drop');
    calendarRef.current.on('drop', onDrop);

    calendarRef.current.off('select');
    calendarRef.current.on('select', onSelect);

    const scroller = document.querySelector(
      'div.fc-scroller.fc-time-grid-container:not(.scroll-event-listener-added)'
    );
    if (scroller) {
      scroller.classList.add('scroll-event-listener-added');
      scroller.addEventListener('scroll', onScroll);
    }
  });

  const getScrollerElement = () => {
    return document.querySelector('div.fc-scroller.fc-time-grid-container');
  };

  const setScrollTop = scrollTop => {
    const scroller = getScrollerElement();
    if (!scroller) {
      return;
    }

    scroller.scrollTop = scrollTop;
  };

  const onScroll = debounce(e => {
    if (!e.target || zoomingRef.current) {
      return;
    }

    const scroller = e.target;

    if (!scroller) {
      return;
    }

    storeScrollTop(scroller.scrollTop);
    storeScrollPercent(getScrollPercent(scroller));
  }, 250);

  const getScrollPercent = scroller =>
    (scroller.scrollTop + scroller.clientHeight / 2) / scroller.scrollHeight;

  const onSelect = e => {
    const newTask = new Task();
    newTask.Title = '';
    newTask.AllDay = e.allDay;
    newTask.Start = e.start;
    newTask.End = e.end;
    newTask.Tags = [];
    newTask.IsCompleted = false;
    newTask.IsRecurring = false;
    calendarRef.current.unselect();
    openTaskDialog(null, newTask);
  };

  const onEventSourceSuccess = () => {
    showMoreOrLessLinkIfNotPresentWithDelay(1000);
  };

  const onEventResize = async e => {
    await eventResizeDrop(e);
  };

  const onEventDrop = async e => {
    await eventResizeDrop(e);
  };

  const eventResizeDrop = async e => {
    const task = e.event.extendedProps.task;

    const updatedTask = cloneTask(task);
    updatedTask.IsSelected = false;
    updatedTask.Start = e.event.start;
    if (task.AllDay && !e.event.allDay) {
      updatedTask.End = addMinutes(e.event.start, 30);
      e.event.setEnd(addMinutes(e.event.start, 30));
    } else {
      updatedTask.End = e.event.end;
    }
    updatedTask.AllDay = e.event.allDay;

    if (!updatedTask.AllDay && !isSameDay(updatedTask.Start, updatedTask.End)) {
      showSnackbar(resourceStrings.multiDayTasksNotSupported);
      e.revert();
      return;
    }

    if (task.IsRecurring && !isSameDay(task.Start, updatedTask.Start)) {
      showSnackbar(
        resourceStrings.movingRecurringInstanceToOtherDayNotSupported
      );
      e.revert();
      return;
    }

    setDoNotRerender(true);
    e.event.setExtendedProp('task', updatedTask);
    updateTasksLocal([task], [updatedTask]);
    showSnackbar(resourceStrings.taskUpdated);
    await updateTasksApi([updatedTask]);

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  const onDrop = async e => {
    if (e.date == null || e.allDay == null || e.draggedEl == null) {
      showSnackbar(resourceStrings.invalidTaskDropped);
      return;
    }

    const taskId = parseInt(
      e.draggedEl.querySelector('#task-row-item').getAttribute('data-task-id'),
      10
    );
    if (!taskId) {
      showSnackbar(resourceStrings.invalidTaskDropped);
      return;
    }

    const taskStart = parseISO(
      e.draggedEl
        .querySelector('#task-row-item')
        .getAttribute('data-task-start')
    );
    const droppedTask = findTaskByIdAndStart(taskId, taskStart, tasks);
    if (!droppedTask) {
      showSnackbar(resourceStrings.invalidTaskDropped);
      return;
    }

    if (droppedTask.IsRecurring) {
      showSnackbar(resourceStrings.operationNotSupportedForRecurringTasks);
      return;
    }

    setIsTaskListDropEnabled(false);

    const updatedTask = cloneTask(droppedTask);

    updatedTask.AllDay = e.allDay;
    updatedTask.Start = e.date;
    updatedTask.SortOrder = 0;
    if (e.allDay) {
      updatedTask.End = null;
    } else {
      if (!droppedTask.Start || droppedTask.AllDay) {
        updatedTask.End = addMinutes(e.date, 30);
      } else {
        updatedTask.End = addSeconds(
          e.date,
          differenceInSeconds(droppedTask.End, droppedTask.Start)
        );
      }
    }

    updateTasksLocal([droppedTask], [updatedTask]);
    showSnackbar(resourceStrings.taskUpdated);
    await updateTasksApi([updatedTask]);
  };

  const prevTasks = usePrevious(tasks);

  const [doNotRerender, setDoNotRerender] = useState(false);

  useEffect(() => {
    if (!tasks) {
      return;
    }

    const tasksDifferingByIsSelected = taskListsDifferByThisPropertyOnly(
      prevTasks,
      tasks,
      'IsSelected'
    );
    if (tasksDifferingByIsSelected && tasksDifferingByIsSelected.length > 0) {
      renderEvents(tasksDifferingByIsSelected);
      return;
    }

    if (doNotRerender) {
      setDoNotRerender(false);
      return;
    }

    rerenderEvents(tasks, 'tasks', false);

    if (!calendarRef.current) {
      return;
    }

    if (highlightedTasks.length > 0) {
      scrollToFirstHighlightedTask(highlightedTasks);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, showScheduledTasks, showRecurringTasks]);

  const scrollToFirstHighlightedTask = highlightedTasks => {
    if (highlightedTasks.length === 0) {
      return;
    }

    const eventElement = document.querySelector(
      `a[event-id='${getEventIdForTask(highlightedTasks[0])}']`
    );

    if (!eventElement) {
      return;
    }

    const scroller = getScrollerElement();

    if (!scroller) {
      return;
    }

    if (eventElement.offsetTop < scroller.scrollTop) {
      eventElement.scrollIntoView({
        behavior: 'instant',
        block: 'start',
        inline: 'nearest',
      });
      scroller.scrollTop -= 20; // Scroll away from edge
    } else if (
      eventElement.offsetTop + eventElement.clientHeight >
      scroller.scrollTop + scroller.clientHeight
    ) {
      eventElement.scrollIntoView({
        behavior: 'instant',
        block: 'end',
        inline: 'nearest',
      });
      scroller.scrollTop += 80; // Scroll above snackbar
    }
  };

  useEffect(() => {
    if (!eventTasks) {
      return;
    }

    rerenderEvents(eventTasks, 'eventTasks', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTasks, showEvents]);

  const rerenderEvents = (tasks, eventSourceId, isCalendarEvent) => {
    const calendar = calendarRef.current;

    let tasksToRender = tasks;
    if (!showScheduledTasks) {
      tasksToRender = tasksToRender.filter(task => task.IsRecurring === true);
    }
    if (!showRecurringTasks) {
      tasksToRender = tasksToRender.filter(task => task.IsRecurring === false);
    }
    if (isCalendarEvent && !showEvents) {
      tasksToRender = [];
    }

    const eventSourceToAdd = getCalendarEventsFromTasks(
      tasksToRender,
      eventSourceId,
      isCalendarEvent
    );
    const existingEventSource = calendar.getEventSourceById(eventSourceId);
    if (existingEventSource) {
      existingEventSource.remove();
    }
    calendar.addEventSource(eventSourceToAdd);
  };

  const renderEvents = tasks => {
    const calendar = calendarRef.current;
    for (const task of tasks) {
      const event = calendar.getEventById(getEventIdForTask(task));
      if (!event) {
        continue;
      }
      event.setExtendedProp('task', task);
    }
  };

  const tippyHideTimeoutRef = useRef();

  const onEventRender = e => {
    const eventTask = e.event.extendedProps.task;

    addDurationString(e.event, e.el);

    if (!eventTask) {
      return;
    }

    // Add desktop tooltip on mouseover
    if (!e.isMirror && !isMobile()) {
      destroyOrphanedTooltips();
      tippy(e.el, {
        content: getTooltipContent(eventTask),
        theme: 'light',
        duration: [275, 50],
        delay: [500, 0],
        placement: 'bottom-start',
        distance: 5,
        trigger: 'mouseenter',
        interactive: false,
        onMount: element => {
          tippyHideTimeoutRef.current = setTimeout(() => {
            element.hide();
          }, 5000);
        },
        onHide: () => {
          if (tippyHideTimeoutRef.current) {
            clearTimeout(tippyHideTimeoutRef.current);
          }
        },
      });
    }

    e.el.setAttribute('event-id', e.event.id);

    e.el.classList.add('waves-effect');
    e.el.classList.add('waves-effect-color');

    if (eventTask.IsCompleted) {
      e.el.querySelector('.fc-title').style.textDecoration = 'line-through';
    }

    if (eventTask.IsSelected) {
      e.el.style.backgroundColor = 'white';
      e.el.style.color = 'rgba(0, 0, 0, 0.87)';
      e.el.classList.add(classes.eventSelected);
    } else {
      const [textColour, backgroundColour] = getEventColoursForTask(
        eventTask,
        !e.event.startEditable
      );
      e.el.style.backgroundColor = backgroundColour;
      e.el.style.color = textColour;
    }

    if (isTaskHighlighted(eventTask)) {
      e.el.classList.add(classes.eventHighlighted);
    }

    // Set recurring icon if appropriate
    if (eventTask.IsRecurring) {
      if (!eventTask.AllDay) {
        const span = e.el.querySelector('div.fc-time > span');
        if (span) {
          span.innerHTML += autoRenewSvgHtml;
        }
      } else {
        const span = e.el.querySelector('span.fc-title');
        if (span) {
          span.innerHTML = autoRenewSvgHtml + span.innerHTML;
        }
      }
    }
  };

  // TODO: Find better fix for this
  const destroyOrphanedTooltips = () => {
    for (let index = 1; index <= 6; index++) {
      setTimeout(() => {
        const tooltips = document.querySelectorAll(
          'div.tippy-popper[style*="-22px"]'
        );
        for (const tooltip of tooltips) {
          tooltip.parentElement.removeChild(tooltip);
        }
      }, 100 * index);
    }
  };

  const autoRenewSvgHtml = `<svg class="MuiSvgIcon-root ${classes.recurringIcon}" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"></path></svg>`;

  const getTooltipContent = task => {
    let content = '';

    if (!task.Start) {
      return content;
    }

    content += [
      '<div data-cy="calendar-tooltip">',
      '<div class="tooltip-date-time" data-cy="calendar-tooltip-date">',
      '<span class="tooltip-date">',
      format(task.Start, 'EEEE, d MMMM'),
      '</span>',
    ].join('');

    if (!task.AllDay && task.End && isValid(task.Start) && isValid(task.End)) {
      content += [
        '&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;',
        '<span class="tooltip-small-text">',
        format(task.Start, 'HH:mm'),
        ' - ',
        format(task.End, 'HH:mm'),
        task.IsRecurring ? autoRenewSvgHtml : '&nbsp;',
        '(' + getCalendarDurationString(task.Start, task.End) + ')',
        '</span>',
      ].join('');
    } else if (task.IsRecurring) {
      content += autoRenewSvgHtml;
    }

    content += '</div>';

    content += `<div class="tooltip-title" data-cy="calendar-tooltip-title"><span>${task.Title.replace(
      /\n/g,
      '<br>'
    )}</span></div>`;

    if (task.IsCalendarEvent) {
      content +=
        '<div class="tooltip-small-text"><strong>Google Calendar Event</strong></div>';
    }

    if (task.Tags && task.Tags.length > 0) {
      const tags = task.Tags.map(
        tag =>
          `<span class="tooltip-tag" style="background-color: ${tag.Colour}" data-cy="calendar-tooltip-tag">${tag.Name}</span>`
      ).join('');
      content += `<div class="tooltip-tags">${tags}</div>`;
    }

    content += '</div>';

    return content;
  };

  const addDurationString = (event, element) => {
    const duration = getCalendarDurationString(event.start, event.end);
    if (!duration) {
      return;
    }

    const durationString = ` (${duration})`;
    if (!durationString) {
      return;
    }

    const fcTimeElement = element.querySelector('.fc-time');
    if (!fcTimeElement) {
      return;
    }

    fcTimeElement.append(durationString);
  };

  const onEventClick = e => {
    if (!e.event.startEditable) {
      return;
    }

    const setSelected = !e.event.extendedProps.task.IsSelected;
    const task = e.event.extendedProps.task;

    setTaskSelectedStatus(task, setSelected);

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  const onEventLimitClick = () => {
    showMore();
  };

  const showMoreOrLessLinkIfNotPresentWithDelay = delay => {
    if (!delay) delay = 0;
    setTimeout(() => showMoreOrLessLinkIfNotPresent(), delay);
  };

  const showMoreOrLessLinkIfNotPresent = () => {
    const allDayEventsCount = document.querySelectorAll(
      '.fc-day-grid-event.fc-event'
    ).length;
    if (allDayEventsCount <= allDayTasksMinCount) {
      return;
    }

    const calendar = calendarRef.current;
    const eventLimit = calendar.getOption('eventLimit');
    if (!eventLimit || eventLimit === allDayTasksMinCount) {
      showMoreLink();
    } else if (eventLimit === allDayTasksMaxCount) {
      showLessLink();
    }
  };

  const showMoreLink = () => {
    showMoreOrLessLink('More', () => showMore());
  };

  const showLessLink = () => {
    showMoreOrLessLink('Less', () => showLess());
  };

  const showMoreOrLessLink = (text, clickEventHandler) => {
    const tbody = document.querySelector(
      '.fc-day-grid .fc-content-skeleton tbody'
    );

    const hiddenTrs = tbody.querySelectorAll('tr.fc-limited');
    if (text === 'More' && hiddenTrs.length === 0) {
      return;
    }

    const visibleTrs = tbody.querySelectorAll('tr:not(.fc-limited)');
    const td = visibleTrs[visibleTrs.length - 1].firstChild;

    td.innerHTML = '';

    const div = document.createElement('div');
    div.id = 'more-less-calendar-all-day-icon-div';
    div.style.textAlign = 'center';
    div.style.maxHeight = '1rem';
    td.appendChild(div);

    ReactDOM.render(
      <IconButton
        className={classes.iconButtonSmall}
        onClick={clickEventHandler}
      >
        {text === 'More' ? (
          <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
        ) : (
          <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
        )}
      </IconButton>,
      document.getElementById('more-less-calendar-all-day-icon-div')
    );
  };

  const showMore = () => {
    const calendar = calendarRef.current;
    calendar.setOption('eventLimit', allDayTasksMaxCount);
    calendar.setOption('eventLimitClick', () => false);

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  const showLess = () => {
    const calendar = calendarRef.current;
    calendar.setOption('eventLimit', allDayTasksMinCount);
    calendar.setOption('eventLimitClick', () => showMore());

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  useHotkeys('t', () => goToToday(), [{}]);
  const goToToday = () => {
    const calendar = calendarRef.current;
    calendar.today();
    storeSelectedDate(calendar.getDate());
    setSelectedDate(calendar.getDate());

    scrollCalendarToNow();

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  const scrollCalendarToNow = () => {
    const scroller = getScrollerElement();
    if (!scroller) {
      return;
    }

    const now = new Date();
    const fractionOfDay = (getHours(now) * 60 + getMinutes(now)) / 1440;
    scroller.scrollTop =
      scroller.scrollHeight * fractionOfDay - scroller.clientHeight / 2;
  };

  useHotkeys('n', () => goToNextPeriod(), [{}]);
  const goToNextPeriod = () => {
    const calendar = calendarRef.current;
    calendar.next();
    storeSelectedDate(calendar.getDate());
    setSelectedDate(calendar.getDate());

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  useHotkeys('p', () => goToPreviousPeriod(), [{}]);
  const goToPreviousPeriod = () => {
    const calendar = calendarRef.current;
    calendar.prev(calendar.getDate());
    storeSelectedDate(calendar.getDate());
    setSelectedDate(calendar.getDate());

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  const changeSetAndStoreView = view => {
    const calendar = calendarRef.current;
    calendar.changeView(view);
    storeSelectedView(view);
    setSelectedView(view);

    showMoreOrLessLinkIfNotPresentWithDelay();
  };

  useHotkeys('d', () => goToDayView(), [{}]);
  const goToDayView = () => {
    changeSetAndStoreView('timeGridDay');
  };

  useHotkeys('x', () => goToDayXView(), [{}]);
  const goToDayXView = () => {
    changeSetAndStoreView('timeGridXDay');
  };

  useHotkeys('w', () => goToWeekView(), [{}]);
  const goToWeekView = () => {
    changeSetAndStoreView('timeGridWeek');
  };

  useHotkeys('m', () => goToMonthView(), [{}]);
  const goToMonthView = () => {
    changeSetAndStoreView('dayGridMonth');
  };

  const storeScrollTop = scrollTop => {
    setLocalStorage('calendarScrollTop', scrollTop, jsType.number);
    setLocalStorage('calendarScrollTopStoredAt', new Date().getTime());
  };

  const storeScrollPercent = scrollPercent => {
    setLocalStorage('calendarScrollPercent', scrollPercent);
  };

  const storeSelectedDate = date => {
    setLocalStorage('selectedDate', date.getTime());
    setLocalStorage('selectedDateStoredAt', new Date().getTime());
  };

  const dayGridHeight = useRef(null);
  const scrollTopRef = useRef(null);

  const onDatesDestroy = e => {
    dayGridHeight.current = e.el.querySelector('div.fc-day-grid').clientHeight;
    const timeGridContainer = e.el.querySelector(
      'div.fc-scroller.fc-time-grid-container'
    );
    if (timeGridContainer) {
      scrollTopRef.current = timeGridContainer.scrollTop;
    }
  };

  const onDatesRender = e => {
    if (!scrollTopRef.current || !dayGridHeight.current) {
      return;
    }
    const dayGridHeightNew = e.el.querySelector('div.fc-day-grid').clientHeight;
    const timeGridContainer = e.el.querySelector(
      '.fc-scroller.fc-time-grid-container'
    );
    if (timeGridContainer) {
      timeGridContainer.scrollTop =
        scrollTopRef.current - dayGridHeight.current + dayGridHeightNew;
    }
    setHeaderText(
      getCalendarHeaderDateString(calendarRef.current.view.currentStart)
    );
  };

  const onViewSkeletonRender = e => {
    setHeaderText(
      getCalendarHeaderDateString(calendarRef.current.view.currentStart)
    );
  };

  const sliderMarks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  return (
    <>
      <Toolbar disableGutters variant="dense" className={classes.toolbar}>
        <SimpleTooltip title={'Today, ' + format(new Date(), 'EEE d MMM')}>
          <IconButton onClick={goToToday} className={classes.iconButton}>
            <TodayIcon style={{ fontSize: '1.6rem' }} />
          </IconButton>
        </SimpleTooltip>

        <SimpleTooltip title="Previous period">
          <IconButton
            onClick={goToPreviousPeriod}
            className={classes.iconButton}
            style={{ padding: '0.55rem' }}
            data-cy="calendar-previous-period-button"
          >
            <ChevronLeftIcon style={{ fontSize: '2rem' }}></ChevronLeftIcon>
          </IconButton>
        </SimpleTooltip>

        <SimpleTooltip title="Next period">
          <IconButton
            onClick={goToNextPeriod}
            className={classes.iconButton}
            style={{ padding: '0.55rem', marginLeft: '-0.5rem' }}
            data-cy="calendar-next-period-button"
          >
            <ChevronRightIcon style={{ fontSize: '2rem' }}></ChevronRightIcon>
          </IconButton>
        </SimpleTooltip>

        <Typography
          variant="h6"
          style={{
            flexGrow: 1,
            userSelect: 'none',
            fontWeight: 400,
          }}
        >
          {headerText}
        </Typography>

        <SimpleTooltip title="Set zoom level">
          <IconButton onClick={onZoomClick} className={classes.iconButton}>
            <ZoomIcon></ZoomIcon>
          </IconButton>
        </SimpleTooltip>

        <SimpleDropdown
          name="calendar-views"
          items={isDesktop ? desktopViews : mobileViews}
          selectedValue={selectedView}
          setSelectedValue={changeSetAndStoreView}
        ></SimpleDropdown>

        <CalendarFilterMenu
          showScheduledTasks={showScheduledTasks}
          setShowScheduledTasks={setShowScheduledTasks}
          showRecurringTasks={showRecurringTasks}
          setShowRecurringTasks={setShowRecurringTasks}
          showEvents={showEvents}
          setShowEvents={setShowEvents}
        ></CalendarFilterMenu>
      </Toolbar>

      <div className={classes.calendar}>
        <div ref={calendarElementRef} id="fullcalendar"></div>
      </div>

      <Popover
        classes={{ paper: classes.popover }}
        open={isZoomPopoverOpen}
        anchorEl={zoomAnchorElement}
        onClose={onZoomPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="body1" align="center">
          Calendar zoom level
        </Typography>
        <Grid container wrap="nowrap">
          <Grid item>
            <SimpleTooltip title="Zoom out">
              <IconButton
                onClick={() => incrementZoomLevel(-1)}
                className={classNames(
                  classes.iconButton,
                  classes.sliderIconButton
                )}
              >
                <ZoomInIcon></ZoomInIcon>
              </IconButton>
            </SimpleTooltip>
          </Grid>
          <Grid item>
            <Slider
              className={classes.slider}
              min={minZoomLevel}
              max={maxZoomLevel}
              step={1}
              value={zoomLevel}
              defaultValue={1}
              marks={sliderMarks}
              valueLabelDisplay="auto"
              onChange={onZoomLevelSliderChange}
            ></Slider>
          </Grid>
          <Grid item>
            <SimpleTooltip title="Zoom in">
              <IconButton
                onClick={() => incrementZoomLevel(1)}
                className={classNames(
                  classes.iconButton,
                  classes.sliderIconButton
                )}
              >
                <ZoomInIcon></ZoomInIcon>
              </IconButton>
            </SimpleTooltip>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default FullCalendar;
