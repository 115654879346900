import { taskType, resourceStrings } from 'src/constants/constants';
import {
  isEqual,
  isValid,
  isToday,
  isTomorrow,
  isAfter,
  endOfTomorrow,
  startOfToday,
  differenceInDays,
  format,
  isThisYear,
  differenceInMilliseconds,
  isYesterday,
} from 'date-fns';
import { ValidationError } from 'src/errors/ValidationError';
import { Tag } from 'src/model/Tag';
import { Task } from 'src/model/Task';
import arrayMove from 'array-move';

export const findTaskIndex = (task, tasksList) => {
  if (!tasksList || tasksList.length === 0) {
    return -1;
  }

  return tasksList.findIndex(
    t => t.Id === task.Id && datesAreEqual(t.Start, task.Start)
  );
};

export const findTask = (task, tasksList) => {
  if (!tasksList || tasksList.length === 0) {
    return undefined;
  }

  return tasksList.find(
    t => t.Id === task.Id && datesAreEqual(t.Start, task.Start)
  );
};

export const findTaskByIdAndStart = (id, start, tasksList) => {
  if (isValid(start)) {
    return tasksList.find(t => t.Id === id && datesAreEqual(t.Start, start));
  } else {
    return tasksList.find(t => t.Id === id);
  }
};

export const updateTasksAsUnscheduled = tasks => {
  const updatedTasks = [];

  for (const task of tasks) {
    updatedTasks.push(updateTaskAsUnscheduled(task));
  }

  return updatedTasks;
};

export const updateTaskAsUnscheduled = task => {
  const updatedTask = cloneTask(task);

  updatedTask.Start = null;
  updatedTask.End = null;
  updatedTask.AllDay = false;
  updatedTask.IsRecurring = false;
  updatedTask.RecurrenceStart = null;
  updatedTask.RecurrenceFrequency = null;
  updatedTask.RecurrenceFrequencyCount = null;
  updatedTask.RecurrenceEnd = null;
  updatedTask.RecurrenceWeekdays = null;
  updatedTask.SortOrder = 1;

  return updatedTask;
};

export const updateTasksAsScheduled = (task, start, end, allDay) => {
  const updatedTask = cloneTask(task);

  updatedTask.Start = start;
  updatedTask.End = end;
  updatedTask.AllDay = allDay;

  return updatedTask;
};

export const getSectionHeader = (tasks, taskIndex) => {
  if (taskIndex < 0 || taskIndex >= tasks.length) {
    throw new ValidationError(
      `'taskIndex' must be between zero and maximum 'tasks' index`
    );
  }

  for (let index = taskIndex; index >= 0; index--) {
    if (tasks[index].Type === taskType.Header) {
      return tasks[index];
    }
  }
  return null;
};

export const getSortOrderInSection = (tasks, taskIndex) => {
  if (taskIndex < 0 || taskIndex >= tasks.length) {
    throw new ValidationError(
      `'taskIndex' must be between zero and maximum 'tasks' index`
    );
  }

  let sortOrder = 0;
  for (let index = taskIndex; index >= 0; index--) {
    if (tasks[index].Type === taskType.Header) {
      return sortOrder;
    }
    sortOrder++;
  }
  return -1;
};

export const moveItemInArray = (array, oldIndex, newIndex) => {
  if (!array || array.length <= 1) {
    throw new ValidationError(
      `'array' must be provided and must have at least 2 elements`
    );
  }

  if (
    oldIndex === undefined ||
    newIndex === undefined ||
    oldIndex < 0 ||
    newIndex < 0 ||
    oldIndex >= array.length ||
    newIndex >= array.length
  ) {
    throw new ValidationError(
      `oldIndex and newIndex must be between zero and maximum array index`
    );
  }

  return arrayMove(array, oldIndex, newIndex);
};

export const populateTasksAndTagsDates = tasks => {
  for (const task of tasks) {
    if (task.Start) {
      task.Start = new Date(task.Start);
    }

    if (task.End) {
      task.End = new Date(task.End);
    }

    if (task.CompletedDateTime) {
      task.CompletedDateTime = new Date(task.CompletedDateTime);
    }

    if (task.CreatedDateTime) {
      task.CreatedDateTime = new Date(task.CreatedDateTime);
    }

    if (task.UpdatedDateTime) {
      task.UpdatedDateTime = new Date(task.UpdatedDateTime);
    }

    if (task.RecurrenceStart) {
      task.RecurrenceStart = new Date(task.RecurrenceStart);
    }

    if (task.RecurrenceEnd) {
      task.RecurrenceEnd = new Date(task.RecurrenceEnd);
    }

    if (task.Tags) {
      for (const tag of task.Tags) {
        if (tag.CreatedDateTime) {
          tag.CreatedDateTime = new Date(tag.CreatedDateTime);
        }
        if (tag.UpdatedDateTime) {
          tag.UpdatedDateTime = new Date(tag.UpdatedDateTime);
        }
      }
    }
  }

  return tasks;
};

export const populateTagsDates = tags => {
  tags.forEach(tag => {
    populateTagDates(tag);
  });

  return tags;
};

export const populateTagDates = tag => {
  if (tag.CreatedDateTime) {
    tag.CreatedDateTime = new Date(tag.CreatedDateTime);
  }

  if (tag.UpdatedDateTime) {
    tag.UpdatedDateTime = new Date(tag.UpdatedDateTime);
  }

  return tag;
};

export const populateEventTasksDates = calendarTasks => {
  calendarTasks.forEach(calendarTask => {
    if (calendarTask.Start) {
      calendarTask.Start = new Date(calendarTask.Start);
    }

    if (calendarTask.End) {
      calendarTask.End = new Date(calendarTask.End);
    }
  });

  return calendarTasks;
};

export const cloneTasks = (tasks, clearSelection = false) => {
  const clonedTasks = [];
  for (const task of tasks) {
    if (clearSelection && task.IsSelected) {
      task.IsSelected = false;
    }
    clonedTasks.push(cloneTask(task));
  }
  return clonedTasks;
};

export const cloneTask = task => {
  return new Task(
    task.Id,
    task.SortOrder,
    task.Title,
    task.AllDay,
    task.Start ? new Date(task.Start.getTime()) : null,
    task.End ? new Date(task.End.getTime()) : null,
    task.IsCompleted,
    task.CompletedDateTime ? new Date(task.CompletedDateTime.getTime()) : null,
    task.CreatedDateTime ? new Date(task.CreatedDateTime.getTime()) : null,
    task.UpdatedDateTime ? new Date(task.UpdatedDateTime.getTime()) : null,
    cloneTags(task.Tags),
    task.IsRecurring,
    task.RecurrenceStart ? new Date(task.RecurrenceStart.getTime()) : null,
    task.RecurrenceFrequency,
    task.RecurrenceFrequencyCount,
    task.RecurrenceEnd ? new Date(task.RecurrenceEnd.getTime()) : null,
    task.RecurrenceWeekdays,
    // Local properties
    task.Type,
    task.IsSelected,
    task.IsCompleting
  );
};

export const cloneTags = tags => {
  if (!tags) {
    return null;
  }

  const clonedTags = [];
  for (const tag of tags) {
    clonedTags.push(cloneTag(tag));
  }
  return clonedTags;
};

export const cloneTag = tag => {
  return new Tag(
    tag.Name,
    tag.Colour,
    tag.Id,
    tag.CreatedDateTime ? new Date(tag.CreatedDateTime.getTime()) : null,
    tag.UpdatedDateTime ? new Date(tag.UpdatedDateTime.getTime()) : null
  );
};

export const rowIsHeader = row => {
  if (!row.closest('#task-row-item')) {
    return true;
  }
  return false;
};

export const changesMadeToTask = (existingTask, taskToAddOrUpdate) => {
  if (!existingTask && !taskToAddOrUpdate) {
    return false;
  }

  if ((!existingTask || existingTask.Id === -1) && taskToAddOrUpdate) {
    return true;
  }

  if (!existingTask.IsRecurring) {
    // Existing task is non-recurring

    if (!taskToAddOrUpdate.IsRecurring) {
      // Updated task is also non-recurring

      if (
        existingTask.Title === taskToAddOrUpdate.Title &&
        existingTask.AllDay === taskToAddOrUpdate.AllDay &&
        tagsAreSame(existingTask.Tags, taskToAddOrUpdate.Tags) &&
        datesAreEqual(existingTask.Start, taskToAddOrUpdate.Start) &&
        datesAreEqual(existingTask.End, taskToAddOrUpdate.End)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      // Updated task is recurring
      return true;
    }
  } else {
    // Existing task is recurring

    if (taskToAddOrUpdate.IsRecurring) {
      // Updated task is also recurring

      if (
        existingTask.Title === taskToAddOrUpdate.Title &&
        existingTask.AllDay === taskToAddOrUpdate.AllDay &&
        tagsAreSame(existingTask.Tags, taskToAddOrUpdate.Tags) &&
        datesAreEqual(
          existingTask.RecurrenceStart,
          taskToAddOrUpdate.RecurrenceStart
        ) &&
        existingTask.RecurrenceFrequency ===
          taskToAddOrUpdate.RecurrenceFrequency &&
        existingTask.RecurrenceFrequencyCount ===
          taskToAddOrUpdate.RecurrenceFrequencyCount &&
        datesAreEqual(
          existingTask.RecurrenceEnd,
          taskToAddOrUpdate.RecurrenceEnd
        ) &&
        existingTask.RecurrenceWeekdays === taskToAddOrUpdate.RecurrenceWeekdays
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      // Updated task is non-recurring
      return true;
    }
  }
};

export const tagsAreSame = (tags1, tags2) => {
  if (!tags1 || !tags2 || tags1.length !== tags2.length) {
    return;
  }

  tags1 = tags1.sort((tag1, tag2) => {
    return tag1.Name.toUpperCase().localeCompare(tag2.Name.toUpperCase());
  });

  tags2 = tags2.sort((tag1, tag2) => {
    return tag1.Name.toUpperCase().localeCompare(tag2.Name.toUpperCase());
  });

  for (let index = 0; index < tags1.length; index++) {
    if (tags1[index].Name.toUpperCase() !== tags2[index].Name.toUpperCase()) {
      return false;
    }
  }

  return true;
};

export const datesAreEqual = (date1, date2) => {
  if (date1 === undefined || date2 === undefined) {
    throw new ValidationError(`'date1' and 'date2' are required`);
  }

  if (date1 === null && date2 === null) {
    return true;
  }

  if (date1 !== null && date2 === null) {
    return false;
  }

  if (date1 === null && date2 !== null) {
    return false;
  }

  if (isEqual(date1, date2)) {
    return true;
  }

  return false;
};

export const toProperCase = text => {
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};

export const isRecurrenceRuleUpdated = (existingTask, taskToAddOrUpdate) => {
  if (!existingTask || existingTask.Id === -1 || !taskToAddOrUpdate) {
    return false;
  }

  if (!existingTask.IsRecurring || !taskToAddOrUpdate.IsRecurring) {
    return false;
  }

  if (
    existingTask.AllDay === taskToAddOrUpdate.AllDay &&
    datesAreEqual(
      existingTask.RecurrenceStart,
      taskToAddOrUpdate.RecurrenceStart
    ) &&
    existingTask.RecurrenceFrequency ===
      taskToAddOrUpdate.RecurrenceFrequency &&
    existingTask.RecurrenceFrequencyCount ===
      taskToAddOrUpdate.RecurrenceFrequencyCount &&
    datesAreEqual(
      existingTask.RecurrenceEnd,
      taskToAddOrUpdate.RecurrenceEnd
    ) &&
    existingTask.RecurrenceWeekdays === taskToAddOrUpdate.RecurrenceWeekdays
  ) {
    return false;
  } else {
    return true;
  }
};

export const recurringTaskUnscheduled = (existingTask, taskToAddOrUpdate) => {
  if (!existingTask || existingTask.Id === -1 || !taskToAddOrUpdate) {
    return false;
  }

  if (
    existingTask.IsRecurring &&
    !taskToAddOrUpdate.IsRecurring &&
    !taskToAddOrUpdate.Start
  ) {
    return true;
  }

  return false;
};

export const updateUnscheduledTasksSortOrder = (
  tasks,
  change,
  fromOrder = 1,
  toOrder = Number.MAX_SAFE_INTEGER
) => {
  for (const task of tasks) {
    if (task.IsCompleted || task.Start) {
      continue;
    }

    if (fromOrder === toOrder) {
      if (task.SortOrder === fromOrder) {
        task.SortOrder += change;
      }
    } else if (fromOrder < toOrder) {
      if (task.SortOrder >= fromOrder && task.SortOrder <= toOrder) {
        task.SortOrder += change;
      }
    } else if (fromOrder > toOrder) {
      if (task.SortOrder >= toOrder && task.SortOrder <= fromOrder) {
        task.SortOrder += change;
      }
    }
  }

  return tasks;
};

export const getIndexOfFirstDifference = (taskList1, taskList2) => {
  if (!taskList1 || !taskList2) {
    return null;
  }

  for (let index = 0; index < taskList1.length; index++) {
    const task1 = taskList1[index];
    const task2 = taskList2[index];

    if (!task1 || !task2) {
      return index;
    }

    if (task1.Type === 'Header' && task2.Type === 'Header') {
      continue;
    }

    if (
      task1.Id !== task2.Id ||
      !datesAreEqual(task1.Start, task2.Start) ||
      task1.Title !== task2.Title ||
      task1.Tags.length !== task2.Tags.length
    ) {
      return index;
    }
  }

  return null;
};

export const taskListsDifferByThisPropertyOnly = (
  taskList,
  updatedTaskList,
  propertyName
) => {
  if (!taskList || !updatedTaskList) {
    return false;
  }

  if (taskList.length !== updatedTaskList.length) {
    return false;
  }

  let isSelectedDifference = false;
  let isOtherDifference = false;

  const changedTasks = [];

  for (let index = 0; index < taskList.length; index++) {
    const task = taskList[index];
    const updatedTask = updatedTaskList[index];

    if (task[propertyName] !== updatedTask[propertyName]) {
      isSelectedDifference = true;
      changedTasks.push(updatedTask);
    }

    for (const taskPropertyName in task) {
      if (taskPropertyName === propertyName || taskPropertyName === 'Type') {
        continue;
      }

      if (
        task[taskPropertyName] &&
        typeof task[taskPropertyName].getMonth === 'function'
      ) {
        if (!isEqual(task[taskPropertyName], updatedTask[taskPropertyName])) {
          isOtherDifference = true;
        }
      } else if (task[taskPropertyName] !== updatedTask[taskPropertyName]) {
        isOtherDifference = true;
      }
    }
  }

  if (isSelectedDifference && !isOtherDifference) {
    return changedTasks;
  }

  return false;
};

export const getAllRecurringInstances = (recurringTask, tasksList) => {
  return tasksList.filter(task => task.Id === recurringTask.Id);
};

export const sortTagsByName = tags => {
  return tags.sort((t1, t2) => {
    if (!t1.Name || !t2.Name) {
      return 0;
    }
    const name1 = t1.Name.toLowerCase();
    const name2 = t2.Name.toLowerCase();
    return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
  });
};

export const formatDateForTaskList = (start, end, allDay) => {
  let datetimeString = '';

  if (isYesterday(start)) {
    datetimeString = 'Yesterday';
  } else if (isToday(start)) {
    datetimeString = resourceStrings.todayText;
  } else if (isTomorrow(start)) {
    datetimeString = resourceStrings.tomorrowText;
  } else if (
    isAfter(start, endOfTomorrow()) &&
    differenceInDays(start, startOfToday()) <= 6
  ) {
    datetimeString = format(start, 'EEEE');
  } else {
    datetimeString = format(start, 'EEE d MMM');
    if (!isThisYear(start)) {
      datetimeString += format(start, ' yyyy');
    }
  }

  if (!allDay) {
    datetimeString +=
      format(start, ' HH:mm') +
      getDurationStringForTaskList(start, end, allDay);
  }

  return datetimeString;
};

const getDurationStringForTaskList = (start, end, allDay) => {
  if (!start || !end || allDay) {
    return '';
  }

  const durationMilliseconds = differenceInMilliseconds(end, start);
  const durationMinutes = durationMilliseconds / 1000 / 60;
  const durationHours = durationMinutes / 60;

  let durationString;
  if (durationMinutes < 60) {
    durationString = ` (${durationMinutes} mins)`;
  } else {
    const plural = durationHours === 1 ? '' : 's';
    durationString = ` (${Math.round(durationHours * 100) /
      100} hour${plural})`;
  }

  return durationString;
};
