import React from 'react';

import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import { useHotkeys } from 'react-hotkeys-hook';
import classNames from 'classnames';

import { useTaskDialog } from 'src/providers/TaskDialogProvider';
import { useSnackbar } from 'src/providers/SnackbarProvider';
import SimpleTooltip from 'src/components/SimpleTooltip';

const useStyles = makeStyles(theme => ({
  fab: {
    zIndex: 500,
    position: 'fixed',
    bottom: '4.5rem',
    right: '1.5rem',
    transition: 'all 100ms ease',
    [theme.breakpoints.up('sm')]: {
      bottom: '5rem',
      right: '3rem',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '3rem',
      right: '4rem',
    },
  },
  fabMoveUp: {
    [theme.breakpoints.down('xs')]: {
      transform: 'translateY(-4.5rem)',
    },
  },
}));

const AddTaskFab = () => {
  const classes = useStyles();

  const { openTaskDialog } = useTaskDialog();
  const { isSnackbarOpen } = useSnackbar();

  useHotkeys('a', () => onFabClick());
  const onFabClick = () => {
    openTaskDialog();
  };

  return (
    <SimpleTooltip title="Add task" placement="left">
      <Fab
        className={classNames(classes.fab, {
          [classes.fabMoveUp]: isSnackbarOpen,
        })}
        color="primary"
        onClick={onFabClick}
        data-cy="add-task-fab"
      >
        <AddIcon />
      </Fab>
    </SimpleTooltip>
  );
};

export default AddTaskFab;
