import React, { useContext, createContext, useState } from 'react';

import {
  makeStyles,
  Snackbar,
  SnackbarContent,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import { useHotkeys } from 'react-hotkeys-hook';

const SnackbarContext = createContext();
export const useSnackbar = () => useContext(SnackbarContext);

const useStyles = makeStyles(theme => ({
  simpleSnackbar: {
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
      bottom: '4.6rem',
    },
  },
  textColor: {
    color: '#fff',
  },
  error: {
    backgroundColor: '#eb5a46',
  },
}));

export const SnackbarProvider = ({ children }) => {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    allowUndo: false,
    onUndo: null,
    undoApiTasks: null,
    undoLocalTasks: null,
    undoApiUpdateRecurringInstance: true,
    undoApiIsPatch: false,
    isError: false,
  });

  const [queuedSnackbar, setQueuedSnackbar] = useState({
    queued: false,
    message: '',
    allowUndo: false,
    onUndo: null,
    undoApiTasks: null,
    undoLocalTasks: null,
    undoApiUpdateRecurringInstance: true,
    undoApiIsPatch: false,
    isError: false,
  });

  const onSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    hideSnackbar();
  };

  const onSnackbarExited = () => {
    if (queuedSnackbar.queued) {
      showQueuedSnackbar();
    }
  };

  const showSnackbar = (
    message,
    isError,
    allowUndo,
    onUndo,
    undoApiTasks,
    undoLocalTasks,
    undoApiUpdateRecurringInstance = true,
    undoApiIsPatch = false
  ) => {
    if (!snackbar.open) {
      if (!isError) {
        showSuccessSnackbar(
          message,
          allowUndo,
          onUndo,
          undoApiTasks,
          undoLocalTasks,
          undoApiUpdateRecurringInstance,
          undoApiIsPatch
        );
      } else {
        showErrorSnackbar(message);
      }
    } else {
      hideSnackbar();

      if (!isError) {
        queueSuccessSnackbar(
          message,
          allowUndo,
          onUndo,
          undoApiTasks,
          undoLocalTasks,
          undoApiUpdateRecurringInstance,
          undoApiIsPatch
        );
      } else {
        queueErrorSnackbar(message);
      }
    }
  };

  const showSuccessSnackbar = (
    message,
    allowUndo,
    onUndo,
    undoApiTasks,
    undoLocalTasks,
    undoApiUpdateRecurringInstance,
    undoApiIsPatch
  ) => {
    setSnackbar({
      open: true,
      message: message,
      allowUndo: allowUndo === true ? true : false,
      onUndo: onUndo,
      undoApiTasks: undoApiTasks,
      undoLocalTasks: undoLocalTasks,
      undoApiUpdateRecurringInstance: undoApiUpdateRecurringInstance,
      undoApiIsPatch: undoApiIsPatch,
      isError: false,
    });
  };

  const queueSuccessSnackbar = (
    message,
    allowUndo,
    onUndo,
    undoApiTasks,
    undoLocalTasks,
    undoApiUpdateRecurringInstance,
    undoApiIsPatch
  ) => {
    setQueuedSnackbar({
      queued: true,
      message: message,
      allowUndo: allowUndo === true ? true : false,
      onUndo: onUndo,
      undoApiTasks: undoApiTasks,
      undoLocalTasks: undoLocalTasks,
      undoApiUpdateRecurringInstance: undoApiUpdateRecurringInstance,
      undoApiIsPatch: undoApiIsPatch,
      isError: false,
    });
  };

  const showErrorSnackbar = message => {
    setSnackbar({
      open: true,
      message: message,
      allowUndo: false,
      onUndo: null,
      undoApiTasks: null,
      undoLocalTasks: null,
      undoApiUpdateRecurringInstance: true,
      undoApiIsPatch: false,
      isError: true,
    });
  };

  const queueErrorSnackbar = message => {
    setQueuedSnackbar({
      queued: true,
      message: message,
      allowUndo: false,
      onUndo: null,
      undoApiTasks: null,
      undoLocalTasks: null,
      undoApiUpdateRecurringInstance: true,
      undoApiIsPatch: false,
      isError: true,
    });
  };

  const showQueuedSnackbar = () => {
    setSnackbar({
      open: true,
      message: queuedSnackbar.message,
      allowUndo: queuedSnackbar.allowUndo,
      onUndo: queuedSnackbar.onUndo,
      undoApiTasks: queuedSnackbar.undoApiTasks,
      undoLocalTasks: queuedSnackbar.undoLocalTasks,
      undoApiUpdateRecurringInstance:
        queuedSnackbar.undoApiUpdateRecurringInstance,
      undoApiIsPatch: queuedSnackbar.undoApiIsPatch,
      isError: queuedSnackbar.isError,
    });
    setQueuedSnackbar({
      queued: false,
      message: '',
      allowUndo: false,
      onUndo: null,
      undoApiTasks: null,
      undoLocalTasks: null,
      undoApiUpdateRecurringInstance: true,
      undoApiIsPatch: false,
      isError: false,
    });
  };

  const hideSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      allowUndo: false,
      onUndo: null,
      undoApiTasks: null,
      undoLocalTasks: null,
      undoApiUpdateRecurringInstance: true,
      undoApiIsPatch: false,
      isError: false,
    });
  };

  const snackbarMessage = (
    <Typography
      variant="body1"
      className={classNames(classes.textColor)}
      id="message-id"
    >
      {snackbar.message}
    </Typography>
  );

  const snackbarAction = [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={onSnackbarClose}
    >
      <CloseIcon />
    </IconButton>,
  ];

  useHotkeys(
    'u',
    () => {
      if (snackbar.allowUndo) {
        snackbar.onUndo(snackbar);
        hideSnackbar();
      }
    },
    [{}]
  );
  if (snackbar.allowUndo) {
    snackbarAction.unshift(
      <Button
        key="undo"
        color="secondary"
        size="medium"
        onClick={() => {
          snackbar.onUndo(snackbar);
          hideSnackbar();
        }}
      >
        UNDO
      </Button>
    );
  }

  return (
    <SnackbarContext.Provider
      value={{ showSnackbar, hideSnackbar, isSnackbarOpen: snackbar.open }}
    >
      {children}
      <Snackbar
        className={classes.simpleSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        disableWindowBlurListener={true}
        transitionDuration={{ enter: 200, exit: 200 }}
        onClose={onSnackbarClose}
        onExited={onSnackbarExited}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          classes={{ root: snackbar.isError ? classes.error : null }}
          message={snackbarMessage}
          action={snackbarAction}
          data-cy="snackbar-content"
        />
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
