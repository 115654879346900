import {
  compareAsc,
  format,
  startOfToday,
  addDays,
  isBefore,
  isSameDay,
  isTomorrow,
  isToday,
  startOfDay,
} from 'date-fns';

import { taskType, resourceStrings } from 'src/constants/constants';
import { cloneTasks } from 'src/util/TaskUtil';

export default class TaskSortService {
  sortTasksAndInsertHeaders(tasks) {
    if (tasks.length === 0) {
      return tasks;
    }
    tasks = cloneTasks(tasks);

    tasks = this.populateTasksType(tasks);

    tasks = this.sortTasks(tasks);

    tasks = this.insertTaskHeaders(tasks);

    return tasks;
  }

  populateTasksType(tasks) {
    tasks.forEach(task => {
      task.Type = taskType.Task;
    });

    return tasks;
  }

  sortTasks(tasks) {
    tasks = tasks.sort((t1, t2) => {
      // Both are unscheduled tasks, so sort based on sort order
      if (!t1.Start && !t2.Start) {
        return t1.SortOrder - t2.SortOrder;
      }
      // Both are scheduled tasks
      else if (t1.Start && t2.Start) {
        // Tasks on same day
        if (isSameDay(t1.Start, t2.Start)) {
          // Both are AllDay, so sort based on name
          if (t1.AllDay && t2.AllDay) {
            return t1.Title < t2.Title ? -1 : 1;
          }
          // Both are timed, so sort based on time
          else if (!t1.AllDay && !t2.AllDay) {
            return compareAsc(t1.Start, t2.Start);
          }
          // One is AllDay and one is timed, show timed first
          return t1.AllDay ? 1 : -1;
        }
        // On different days, so sort based on datetime
        else {
          return compareAsc(t1.Start, t2.Start);
        }
      }
      // One is scheduled and one is unscheduled, so show scheduled first
      else {
        return t1.Start ? -1 : 1;
      }
    });

    return tasks;
  }

  insertTaskHeaders(tasks) {
    const now = new Date();

    tasks = this.insertOverdueHeader(tasks, now);

    tasks = this.insertDateHeaders(tasks, now);

    tasks = this.insertUnscheduledHeader(tasks);

    return tasks;
  }

  insertOverdueHeader(tasks, now) {
    let overdueHeader = {
      Title: 'Overdue',
      Subtitle: '',
      Type: taskType.Header,
    };

    if (this.taskIsOverdue(tasks[0], now)) {
      tasks.splice(0, 0, overdueHeader);
    }

    return tasks;
  }

  taskIsOverdue(task, currentDateTime) {
    return (
      (!task.AllDay && isBefore(task.Start, currentDateTime)) ||
      (task.AllDay && isBefore(task.Start, startOfDay(currentDateTime)))
    );
  }

  insertDateHeaders(tasks, now) {
    let startDateTime = now;
    let endDateTime = addDays(startOfToday(), 8);

    for (
      let currentDateTime = startDateTime;
      isBefore(currentDateTime, endDateTime);
      currentDateTime = startOfDay(addDays(currentDateTime, 1))
    ) {
      let headerToInsert = {
        Title: this.getDateHeaderTitle(currentDateTime),
        Subtitle: this.getDateHeaderSubtitle(currentDateTime),
        Type: taskType.Header,
        Start: currentDateTime,
      };

      // Insert header before first task starting at or after the specified datetime (or day for AllDay tasks)
      let headerInsertIndex = tasks.findIndex(
        task =>
          task.Type !== taskType.Header &&
          task.Start &&
          !this.taskIsOverdue(task, currentDateTime)
      );

      if (headerInsertIndex >= 0) {
        tasks.splice(headerInsertIndex, 0, headerToInsert);
      }
      // Insert header before the first unscheduled task
      else {
        headerInsertIndex = tasks.findIndex(task => {
          return task.Type !== taskType.Header && task.Start == null;
        });

        if (headerInsertIndex >= 0) {
          tasks.splice(headerInsertIndex, 0, headerToInsert);
        }
      }
    }

    return tasks;
  }

  getDateHeaderTitle(date) {
    if (isToday(date)) {
      return resourceStrings.todayText;
    } else if (isTomorrow(date)) {
      return resourceStrings.tomorrowText;
    } else if (date.getTime() === addDays(startOfToday(), 7).getTime()) {
      return resourceStrings.after7DaysText;
    } else {
      return format(date, 'EEEE');
    }
  }

  getDateHeaderSubtitle(date) {
    // If 'After 7 Days' return no subtitle
    if (date.getTime() === addDays(startOfToday(), 7).getTime()) {
      return '';
    }

    return format(date, 'EEE d MMM');
  }

  insertUnscheduledHeader(tasks) {
    let unscheduledHeader = {
      Title: resourceStrings.unscheduledTasksText,
      Subtitle: '',
      Type: taskType.Header,
    };

    let headerInsertIndex = tasks.findIndex(task => {
      return task.Type !== taskType.Header && task.Start == null;
    });

    if (headerInsertIndex >= 0) {
      tasks.splice(headerInsertIndex, 0, unscheduledHeader);
    }

    return tasks;
  }
}
