import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import Skeleton from '@material-ui/lab/Skeleton';
import { taskType } from 'src/constants/constants';

const useStyles = makeStyles({
  headerRow: {
    userSelect: 'none',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: props => {
      return props.gutters ? '3rem' : '1rem';
    },
    paddingRight: props => {
      return props.gutters ? '2rem' : '1rem';
    },
  },
  headerTitle: {
    paddingRight: '0.5rem',
  },
  taskRow: {
    userSelect: 'none',
    paddingLeft: props => {
      return props.gutters ? '2rem' : 0;
    },
    paddingRight: props => {
      return props.gutters ? '2rem' : 0;
    },
  },
  iconContainer: {
    padding: '1rem 0.8rem 1rem 1rem',
  },
  taskContainer: {
    padding: '0.5rem 1rem 0.5rem 0',
  },
});

const TaskRowSkeleton = ({ type, gutters }) => {
  const classes = useStyles({ gutters });

  let taskRow;
  if (type === taskType.Header) {
    // Header row
    taskRow = (
      <Grid
        container
        wrap="nowrap"
        alignItems="baseline"
        className={classNames(classes.headerRow)}
      >
        <Grid item xs className={classes.headerTitle}>
          <Skeleton variant="text" width={100} height={20}></Skeleton>
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" width={80} height={6}></Skeleton>
        </Grid>
      </Grid>
    );
  }
  // Task row
  else {
    taskRow = (
      <Grid
        id="task-row-item"
        container
        wrap="nowrap"
        className={classNames(classes.taskRow)}
      >
        <Grid item xs className={classNames(classes.iconContainer)}>
          <Skeleton variant="circle" width={30} height={30}></Skeleton>
        </Grid>

        <Grid item xs={12} className={classes.taskContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Skeleton variant="text" width="90%" height={10}></Skeleton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container wrap="nowrap">
              <Grid item xs={12}>
                <Skeleton variant="text" width={100} height={6}></Skeleton>
              </Grid>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap">
            <Grid item style={{ marginRight: 10 }}>
              <Skeleton variant="text" width={50} height={10}></Skeleton>
            </Grid>
            <Grid item style={{ marginRight: 10 }}>
              <Skeleton variant="text" width={30} height={10}></Skeleton>
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={40} height={10}></Skeleton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return taskRow;
};

export default TaskRowSkeleton;
