import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';

import { CellMeasurerCache } from 'react-virtualized';
import { constants } from 'src/constants/constants';

const ListContext = createContext();
export const useList = () => useContext(ListContext);

export const ListProvider = ({ children }) => {
  // TODO: Find better way to force re-render after initialising CellMeasurerCache
  // eslint-disable-next-line no-unused-vars
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    cellMeasurerCacheRef.current = new CellMeasurerCache({
      defaultHeight: constants.initialRowHeight,
      fixedWidth: true,
    });
    setForceRender(true);
  }, []);

  const listRef = useRef();
  const cellMeasurerCacheRef = useRef();

  const clearListCache = (fromIndex, forceUpdateGridInstead) => {
    // TODO: Temp solution to ensure that list cache cleared not to early or too late.
    // But need more elegant solution to know exactly when to clear and do it then once list updated
    for (let index = 1; index <= 5; index++) {
      setTimeout(
        () => clearCache(fromIndex, forceUpdateGridInstead),
        200 * index
      );
    }
  };

  const clearCache = (fromIndex, forceUpdateGridInstead) => {
    if (!fromIndex) {
      cellMeasurerCacheRef.current.clearAll();
    } else {
      for (
        let index = fromIndex;
        index < cellMeasurerCacheRef.current._rowCount;
        index++
      ) {
        cellMeasurerCacheRef.current.clear(index);
      }
    }

    if (forceUpdateGridInstead) {
      forceUpdateGrid();
    } else {
      recomputeRowHeights(fromIndex ? fromIndex : 0);
    }
  };

  const recomputeRowHeights = fromIndex => {
    if (!listRef.current) {
      return;
    }
    listRef.current.recomputeRowHeights(fromIndex);
  };

  const forceUpdateGrid = () => {
    if (!listRef.current) {
      return;
    }
    listRef.current.forceUpdateGrid();
  };

  const scrollToIndex = index => {
    const originalScrollTop = getScrollTop();
    scrollToIndexInternal(index);
    setTimeout(() => {
      const updatedScrollTop = getScrollTop();

      if (!originalScrollTop || !updatedScrollTop) {
        return;
      }

      if (updatedScrollTop > originalScrollTop) {
        // Scrolled down, scroll down a little further
        scrollToPositionInternal(updatedScrollTop + 100);
      } else if (updatedScrollTop < originalScrollTop) {
        // Scrolled up, scroll up a little further
        scrollToPositionInternal(updatedScrollTop - 100);
      }
    }, 0);
  };

  const scrollToIndexInternal = index => {
    listRef.current.scrollToRow(index);
  };

  const scrollToPositionInternal = scrollTop => {
    if (scrollTop < 0) scrollTop = 0;
    listRef.current.scrollToPosition(scrollTop);
  };

  const getScrollTop = () => {
    if (!listRef.current) {
      return null;
    }

    return listRef.current.Grid._scrollingContainer.scrollTop;
  };

  return (
    <ListContext.Provider
      value={{
        listRef,
        clearListCache,
        cellMeasurerCache: cellMeasurerCacheRef.current,
        scrollListToIndex: scrollToIndex,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};
