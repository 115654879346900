import { getISODay, setISODay, addWeeks } from 'date-fns';

export const getLowerIndex = (firstIndex, secondIndex) => {
  if (!isValidIndex(firstIndex) && !isValidIndex(secondIndex)) {
    return 0;
  }

  if (isValidIndex(firstIndex) && !isValidIndex(secondIndex)) {
    return firstIndex;
  }

  if (!isValidIndex(firstIndex) && isValidIndex(secondIndex)) {
    return secondIndex;
  }

  return Math.min(firstIndex, secondIndex);
};

export const isValidIndex = index => {
  if (index === null || index === undefined || index === -1) {
    return false;
  }
  return true;
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const getRoundedDateTime = dateTime => {
  var result = new Date(dateTime);

  result.setMilliseconds(Math.round(result.getMilliseconds() / 1000) * 1000);
  result.setSeconds(Math.round(result.getSeconds() / 60) * 60);
  result.setMinutes(Math.round(result.getMinutes() / 15) * 15);
  return result;
};

export const getDateFromDayOfWeek = (inputDateTime, outputDayOfWeek) => {
  const inputDayOfWeek = getISODay(inputDateTime);
  if (inputDayOfWeek <= outputDayOfWeek) {
    return setISODay(inputDateTime, outputDayOfWeek);
  } else {
    return setISODay(addWeeks(inputDateTime, 1), outputDayOfWeek);
  }
};
