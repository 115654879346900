import React, { createContext, useContext, useState } from 'react';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ScheduledDateIcon from '@material-ui/icons/CalendarToday';
import ScheduledTimeIcon from '@material-ui/icons/Schedule';
import TodayIcon from '@material-ui/icons/Today';
import TomorrowIcon from '@material-ui/icons/WbSunnyOutlined';
import WeekendIcon from '@material-ui/icons/PhotoOutlined';
import NextWeekIcon from '@material-ui/icons/NextWeekOutlined';
import NowIcon from '@material-ui/icons/PlayCircleOutline';
import AllDayIcon from '@material-ui/icons/CalendarTodayOutlined';
import MorningIcon from '@material-ui/icons/Brightness5Outlined';
import AfternoonIcon from '@material-ui/icons/Brightness6Outlined';
import EveningIcon from '@material-ui/icons/Brightness2Outlined';
import {
  startOfToday,
  differenceInMinutes,
  addMinutes,
  getHours,
  getMinutes,
  getYear,
  getMonth,
  getDate,
  startOfTomorrow,
} from 'date-fns';
import { startOfDay } from '@fullcalendar/core';
import enGbLocale from 'date-fns/locale/en-GB';

import { getRoundedDateTime, getDateFromDayOfWeek } from 'src/util/Utils';
import { useTasks } from 'src/providers/TasksProvider';
import { cloneTask, updateTaskAsUnscheduled } from 'src/util/TaskUtil';
import SimpleTimePicker from 'src/components/SimpleTimePicker';
import SimpleDatePicker from 'src/components/SimpleDatePicker';

const DateDialogContext = createContext();
export const useDateDialog = () => useContext(DateDialogContext);

const useStyles = makeStyles({
  dialogPaper: {
    overflowY: 'visible',
    maxWidth: '400px',
  },
  dialogContentRoot: {
    overflowY: 'visible',
  },
  icon: {
    marginLeft: '0.5rem',
    marginRight: '1rem',
  },
  buttonLabel: {
    justifyContent: 'left',
  },
  dateTimePicker: {
    marginBottom: '1rem',
    marginRight: '0.5rem',
    width: '100%',
  },
});

export const DateDialogProvider = ({ children }) => {
  const classes = useStyles();

  const { addOrUpdateTasks } = useTasks();
  const { selectedTasks } = useTasks();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [scheduleDateTime, setScheduleDateTime] = useState(startOfToday());
  const [scheduleAllDay, setScheduleAllDay] = useState(false);
  const [isMultipleTasks, setIsMultipleTasks] = useState(false);

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const openDateDialog = () => {
    if (!selectedTasks || selectedTasks.length === 0) {
      return;
    }

    const firstSelectedTaskStart = selectedTasks[0].Start;
    const firstSelectedTaskAllDay = selectedTasks[0].AllDay;
    if (firstSelectedTaskStart) {
      setScheduleDateTime(firstSelectedTaskStart);
      setScheduleAllDay(firstSelectedTaskAllDay);
    } else {
      setScheduleDateTime(null);
      setScheduleAllDay(true);
    }

    setIsMultipleTasks(selectedTasks.length > 1);

    setIsDialogOpen(true);
  };

  const onDialogClose = () => {
    closeDialog();
  };

  const onDialogCancelClick = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const onDialogSaveClick = async () => {
    const tasks = [];
    const updatedTasks = [];

    for (const task of selectedTasks) {
      let updatedTask = cloneTask(task);
      if (scheduleDateTime !== null) {
        updatedTask.AllDay = scheduleAllDay;
        updatedTask.Start = scheduleDateTime;
        updatedTask.SortOrder = 0;
        if (scheduleAllDay) {
          updatedTask.End = null;
        } else {
          let lengthInMinutes = 30;
          if (task.End) {
            lengthInMinutes = differenceInMinutes(task.End, task.Start);
          }
          updatedTask.End = addMinutes(updatedTask.Start, lengthInMinutes);
        }
      } else {
        updatedTask = updateTaskAsUnscheduled(updatedTask);
      }
      tasks.push(task);
      updatedTasks.push(updatedTask);
    }

    if (selectedTasks.length > 0) {
      await addOrUpdateTasks(updatedTasks, tasks);
    }

    closeDialog();
  };

  const onDatePickerChange = selectedDate => {
    selectDate(selectedDate);
  };

  const onTimePickerChange = selectedTime => {
    selectTime(selectedTime);
  };

  const selectDate = selectedDate => {
    if (!selectedDate) {
      clearSchedule();
      return;
    }

    if (!scheduleDateTime) {
      setScheduleDateTime(startOfDay(selectedDate));
      setScheduleAllDay(true);
      return;
    }

    updateDatePartOfDateTime(selectedDate);
  };

  const selectTime = selectedTime => {
    if (!selectedTime) {
      clearTime();
      return;
    }

    if (!scheduleDateTime) {
      // Should not happen
      return;
    }

    updateTimePartOfDateTime(selectedTime);
    setScheduleAllDay(false);
  };

  const updateDatePartOfDateTime = updatedDate => {
    const updatedDateTime = new Date(scheduleDateTime.getTime());
    updatedDateTime.setFullYear(
      getYear(updatedDate),
      getMonth(updatedDate),
      getDate(updatedDate)
    );
    setScheduleDateTime(updatedDateTime);
  };

  const updateTimePartOfDateTime = updatedTime => {
    const updatedDateTime = new Date(scheduleDateTime.getTime());
    updatedDateTime.setHours(
      getHours(updatedTime),
      getMinutes(updatedTime),
      0,
      0
    );
    setScheduleDateTime(updatedDateTime);
  };

  const clearSchedule = () => {
    setScheduleDateTime(null);
    setScheduleAllDay(true);
  };

  const clearTime = () => {
    setScheduleAllDay(true);
    if (scheduleDateTime) {
      setScheduleDateTime(startOfDay(scheduleDateTime));
    }
  };

  const onClearDateClick = event => {
    clearSchedule();
    event.preventDefault();
    event.stopPropagation();
  };

  const onClearTimeClick = event => {
    clearTime();
    event.preventDefault();
    event.stopPropagation();
  };

  const isClearScheduleVisible = () => {
    if (scheduleDateTime) {
      return true;
    }
    return false;
  };

  const isTimeVisible = () => {
    if (scheduleDateTime) {
      return true;
    }
    return false;
  };

  const isClearTimeVisible = () => {
    if (scheduleDateTime && !scheduleAllDay) {
      return true;
    }
    return false;
  };

  const onTodayClick = () => {
    onDatePickerChange(startOfToday());
  };

  const onTomorrowClick = () => {
    onDatePickerChange(startOfTomorrow());
  };

  const onThisSatClick = () => {
    onDatePickerChange(getDateFromDayOfWeek(startOfToday(), 6));
  };

  const onThisSunClick = () => {
    onDatePickerChange(getDateFromDayOfWeek(startOfToday(), 7));
  };

  const onNextWeekClick = () => {
    onDatePickerChange(getDateFromDayOfWeek(startOfTomorrow(), 1));
  };

  const onNowClick = () => {
    setScheduleDateTime(getRoundedDateTime(new Date()));
    setScheduleAllDay(false);
  };

  const onAllDayClick = () => {
    if (scheduleDateTime) {
      clearTime();
    } else {
      setScheduleAllDay(true);
      setScheduleDateTime(startOfToday);
    }
  };

  const onMorningClick = () => {
    selectTime(new Date().setHours(8, 0, 0, 0));
  };

  const onAfternoonClick = () => {
    selectTime(new Date().setHours(13, 0, 0, 0));
  };

  const onEveningClick = () => {
    selectTime(new Date().setHours(18, 0, 0, 0));
  };

  return (
    <DateDialogContext.Provider value={{ openDateDialog }}>
      {children}
      <Dialog
        open={isDialogOpen}
        onClose={onDialogClose}
        fullWidth={false}
        fullScreen={!isDesktop}
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
      >
        <DialogTitle>Schedule Task{isMultipleTasks ? 's' : ''}</DialogTitle>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGbLocale}>
          <DialogContent classes={{ root: classes.dialogContentRoot }}>
            <Grid container spacing={2}>
              <Grid container wrap="nowrap">
                <ScheduledDateIcon className={classes.icon} />
                <SimpleDatePicker
                  dateTime={scheduleDateTime}
                  isClearButtonVisible={isClearScheduleVisible()}
                  onChange={onDatePickerChange}
                  onClearDateClick={onClearDateClick}
                ></SimpleDatePicker>
              </Grid>

              <Grid
                container
                wrap="nowrap"
                style={{ visibility: isTimeVisible() ? 'visible' : 'hidden' }}
              >
                <ScheduledTimeIcon className={classes.icon} />
                <SimpleTimePicker
                  allDay={scheduleAllDay}
                  dateTime={scheduleDateTime}
                  isClearButtonVisible={isClearTimeVisible()}
                  onChange={onTimePickerChange}
                  onClearTimeClick={onClearTimeClick}
                ></SimpleTimePicker>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<TodayIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onTodayClick}
                >
                  Today
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<NowIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onNowClick}
                >
                  Now
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<TomorrowIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onTomorrowClick}
                >
                  Tomorrow
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<AllDayIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onAllDayClick}
                >
                  All Day
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<WeekendIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onThisSatClick}
                >
                  This Sat
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<MorningIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onMorningClick}
                >
                  Morning
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<WeekendIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onThisSunClick}
                >
                  This Sun
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<AfternoonIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onAfternoonClick}
                >
                  Afternoon
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<NextWeekIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onNextWeekClick}
                >
                  Next Week
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  startIcon={<EveningIcon />}
                  fullWidth
                  classes={{ label: classes.buttonLabel }}
                  onClick={onEveningClick}
                >
                  Evening
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </MuiPickersUtilsProvider>
        <DialogActions>
          <Button onClick={onDialogCancelClick} color="primary">
            Cancel
          </Button>
          <Button onClick={onDialogSaveClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </DateDialogContext.Provider>
  );
};
