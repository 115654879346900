import React, { useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import TaskIncompleteIcon from '@material-ui/icons/RadioButtonUnchecked';
import TaskHoverIcon from '@material-ui/icons/CheckCircleOutline';
import TaskCompletedIcon from '@material-ui/icons/CheckCircle';

import { isMobile } from 'src/util/DeviceUtil';

const useStyles = makeStyles({
  iconContainer: {
    borderRadius: '50%',
  },
  icon: {
    display: 'block',
    fontSize: '1.8rem',
    color: 'rgba(0,0,0,.54)',
    pointerEvents: 'none',
  },
});

const TaskCompletionIcon = ({ task, onTaskClickCompleteTransition }) => {
  const classes = useStyles();

  const [mouseIsHovering, setMouseIsHovering] = useState(false);

  const onMouseEnter = event => {
    setMouseHoverState(true);
  };

  const onMouseLeave = event => {
    setMouseHoverState(false);
  };

  const onClick = event => {
    setMouseHoverState(false);
    onTaskClickCompleteTransition(event, task);
  };

  const setMouseHoverState = state => {
    if (isMobile()) {
      return;
    }
    setMouseIsHovering(state);
  };

  if (task.IsCompleting) return <TaskCompletedIcon className={classes.icon} />;
  else if (mouseIsHovering)
    return (
      <div
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        data-cy="task-completion-icon"
        className={classes.iconContainer}
      >
        <TaskHoverIcon id="task-completion-icon" className={classes.icon} />
      </div>
    );
  else
    return (
      <div
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        data-cy="task-completion-icon"
        className={classes.iconContainer}
      >
        <TaskIncompleteIcon
          id="task-completion-icon"
          className={classes.icon}
        />
      </div>
    );
};

export default TaskCompletionIcon;
