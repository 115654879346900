import React from 'react';

import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useAuth0 } from 'src/providers/Auth0Provider';

import GoogleLogo from 'src/media/google-logo.svg';
import { ReactComponent as Logo } from 'src/media/logo-todocalendar.svg';

const useStyles = makeStyles((theme) => ({
  top: {
    marginBottom: 'auto',
  },
  content: {
    height: '100%',
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#4183f1',
    marginBottom: '2rem',
  },
  logo: {
    width: '30%',
    maxWidth: '8rem',
  },
  loading: {
    marginTop: '2rem',
  },
  buttonContainer: {
    marginTop: 'auto',
    marginBottom: '2rem',
    width: 'calc(100% - 4rem)',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  button: {
    paddingLeft: '1rem',
    [theme.breakpoints.up('sm')]: {
      width: '24rem',
    },
  },
  googleLogo: {
    width: '2.5rem',
    paddingRight: '1rem',
    marginRight: 'auto',
  },
  buttonText: {
    textTransform: 'none',
  },
  emptyRight: {
    visibility: 'hidden',
    marginLeft: 'auto',
  },
}));

const Login = () => {
  const classes = useStyles();

  const { loginWithRedirect } = useAuth0();

  const onLoginButtonClick = () => {
    if (process.env.NODE_ENV === 'production') {
      loginWithRedirect({
        connection: 'google-oauth2',
        access_type: 'offline',
        connection_scope:
          'https://www.googleapis.com/auth/calendar.events.readonly',
      });
    } else {
      loginWithRedirect({
        access_type: 'offline',
        connection_scope:
          'https://www.googleapis.com/auth/calendar.events.readonly',
      });
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.top} />

      <Typography className={classes.title} variant="h4">
        <strong>Todo</strong> Calendar
      </Typography>

      <Logo className={classes.logo} />

      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          fullWidth={true}
          size="large"
          variant="outlined"
          data-cy="google-login-button"
          onClick={onLoginButtonClick}
        >
          <img src={GoogleLogo} alt="" className={classes.googleLogo} />
          <span className={classes.buttonText}>Continue with Google</span>
          <div className={classes.emptyRight} />
        </Button>
      </div>
    </div>
  );
};

export default Login;
