import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import TasksIcon from '@material-ui/icons/PlaylistAddCheck';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { setLocalStorage, getLocalStorage } from 'src/util/StorageUtil';
import { jsType } from 'src/constants/constants';

const useStyles = makeStyles(theme => ({
  navigation: {
    borderTop: '1px solid rgba(0,0,0,.10)',
    backgroundColor: theme.palette.background.default,
  },
  navButton: {
    'max-width': 'none',
  },
}));

const Navigation = withRouter(({ history }) => {
  const classes = useStyles();

  const [navigationSelection, setNavigationSelection] = useState(0);

  useEffect(() => {
    // TODO: Define navigation routes centrally so avoid duplication between here and Routing?
    switch (history.location.pathname) {
      case '/calendar': {
        setNavigationSelection(1);
        setLocalStorage('selectedTab', 1);
        break;
      }
      case '/tasks': {
        setNavigationSelection(0);
        setLocalStorage('selectedTab', 0);
        break;
      }
      default: {
        const selection = getLocalStorage('selectedTab', 0, jsType.number);
        setNavigationSelection(selection);
        if (selection === 0) {
          history.push('/tasks');
        } else if (selection === 1) {
          history.push('/calendar');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNavigationChange = (event, value) => {
    setNavigationSelection(value);
    setTimeout(() => {
      switch (value) {
        case 0: {
          history.push('/tasks');
          setLocalStorage('selectedTab', 0);
          break;
        }
        case 1: {
          history.push('/calendar');
          setLocalStorage('selectedTab', 1);
          break;
        }
        default: {
          // Empty
        }
      }
    }, 100);
  };

  return (
    <BottomNavigation
      value={navigationSelection}
      onChange={onNavigationChange}
      className={classes.navigation}
      showLabels
    >
      <BottomNavigationAction
        label="Tasks"
        icon={<TasksIcon />}
        className={classes.navButton}
        data-cy="navigation-tasks-button"
      />
      <BottomNavigationAction
        label="Calendar"
        icon={<CalendarIcon />}
        className={classes.navButton}
        data-cy="navigation-calendar-button"
      />
    </BottomNavigation>
  );
});

export default Navigation;
