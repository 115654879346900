export class Task {
  constructor(
    Id,
    SortOrder,
    Title,
    AllDay,
    Start,
    End,
    IsCompleted,
    CompletedDateTime,
    CreatedDateTime,
    UpdatedDateTime,
    Tags,
    IsRecurring,
    RecurrenceStart,
    RecurrenceFrequency,
    RecurrenceFrequencyCount,
    RecurrenceEnd,
    RecurrenceWeekdays,
    // Local properties
    Type,
    IsSelected,
    IsCompleting
  ) {
    this.Id = Id;
    this.SortOrder = SortOrder;
    this.Title = Title;
    this.AllDay = AllDay;
    this.Start = Start;
    this.End = End;
    this.IsCompleted = IsCompleted;
    this.CompletedDateTime = CompletedDateTime;
    this.CreatedDateTime = CreatedDateTime;
    this.UpdatedDateTime = UpdatedDateTime;
    this.Tags = Tags;
    this.IsRecurring = IsRecurring;
    this.RecurrenceStart = RecurrenceStart;
    this.RecurrenceFrequency = RecurrenceFrequency;
    this.RecurrenceFrequencyCount = RecurrenceFrequencyCount;
    this.RecurrenceEnd = RecurrenceEnd;
    this.RecurrenceWeekdays = RecurrenceWeekdays;
    // Local properties
    this.Type = Type;
    this.IsSelected = IsSelected;
    this.IsCompleting = IsCompleting;
  }
}
