import { jsType } from 'src/constants/constants';
import { ValidationError } from 'src/errors/ValidationError';

export const getLocalStorage = (key, defaultValue, convertToType) => {
  validateKey(key);

  if (
    defaultValue !== undefined &&
    defaultValue !== null &&
    typeof defaultValue !== jsType.string &&
    typeof defaultValue !== jsType.number &&
    typeof defaultValue !== jsType.boolean
  ) {
    throw new ValidationError(
      `if 'defaultValue' is provided it must be of type 'string', 'number' or 'boolean'`
    );
  }

  if (
    convertToType !== undefined &&
    convertToType !== jsType.string &&
    convertToType !== jsType.number &&
    convertToType !== jsType.boolean
  ) {
    throw new ValidationError(
      `if 'type' is provided it must be 'string', 'number' or 'boolean'`
    );
  }

  let value = localStorage.getItem(key);

  if (value) {
    if (convertToType === jsType.boolean) {
      value = value === 'true';
    } else if (convertToType === jsType.number) {
      value = value * 1;
    }
  } else if (defaultValue !== undefined && defaultValue !== null) {
    value = defaultValue;
    setLocalStorage(key, defaultValue);
  }

  return value;
};

export const setLocalStorage = (key, value) => {
  validateKey(key);

  if (value === undefined) {
    throw new ValidationError(`'value' is required`);
  }

  if (
    typeof value !== jsType.string &&
    typeof value !== jsType.number &&
    typeof value !== jsType.boolean
  ) {
    throw new ValidationError(
      `'value' must be of type 'string', 'number' or 'boolean'`
    );
  }

  localStorage.setItem(key, value);
};

const validateKey = key => {
  if (key === undefined || key === null || typeof key !== jsType.string) {
    throw new ValidationError(
      `'key' is required and should be of type 'string'`
    );
  }
};
