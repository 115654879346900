import React from 'react';

import {
  CssBaseline,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';

import { Auth0Provider } from 'src/providers/Auth0Provider';
import { ListProvider } from 'src/providers/ListProvider';
import { SnackbarProvider } from 'src/providers/SnackbarProvider';
import { TaskDialogProvider } from 'src/providers/TaskDialogProvider';
import { TasksProvider } from 'src/providers/TasksProvider';
import { ProgressProvider } from 'src/providers/ProgressProvider';
import { DialogProvider } from 'src/providers/DialogProvider';
import { DateDialogProvider } from 'src/providers/DateDialogProvider';
import { AddToHomeScreenProvider } from 'src/providers/AddToHomeScreenProvider';
import { ManageTagsDialogProvider } from 'src/providers/ManageTagsDialogProvider';
import { TagDialogProvider } from 'src/providers/TagDialogProvider';
import App from 'src/app/App';

const AppContainer = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#4183f1',
      },
      background: {
        default: '#fafafa',
      },
    },
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MuiTypography: {
        body2: {
          fontSize: `${12 / 16}rem`,
        },
      },
    },
  });

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Auth0Provider>
          <ListProvider>
            <SnackbarProvider>
              <ProgressProvider>
                <TasksProvider>
                  <DialogProvider>
                    <TaskDialogProvider>
                      <DateDialogProvider>
                        <AddToHomeScreenProvider>
                          <TagDialogProvider>
                            <ManageTagsDialogProvider>
                              <App></App>
                            </ManageTagsDialogProvider>
                          </TagDialogProvider>
                        </AddToHomeScreenProvider>
                      </DateDialogProvider>
                    </TaskDialogProvider>
                  </DialogProvider>
                </TasksProvider>
              </ProgressProvider>
            </SnackbarProvider>
          </ListProvider>
        </Auth0Provider>
      </MuiThemeProvider>
    </>
  );
};

export default AppContainer;
