import { isBefore, isToday, startOfTomorrow } from 'date-fns';

import { taskType, resourceStrings } from 'src/constants/constants';
import { cloneTasks } from 'src/util/TaskUtil';

export default class TaskFilterService {
  filterTasks(tasksToFilter, filters) {
    let filteredTasks = cloneTasks(tasksToFilter);

    let filterShowEmptySections = false;

    filteredTasks = filteredTasks.filter(task => !task.IsCompleted);

    for (const filter of filters) {
      // Filter out scheduled tasks if filter unchecked
      if (filter.key === 'filterShowScheduledTasks' && !filter.isChecked) {
        filteredTasks = this.removeScheduledTasks(filteredTasks);
      }

      // Filter out recurring tasks if filter unchecked
      if (filter.key === 'filterShowRecurringTasks' && !filter.isChecked) {
        filteredTasks = this.removeRecurringTasks(filteredTasks);
      }

      // Filter out future recurring instances if filter unchecked
      if (
        filter.key === 'filterShowFutureRecurringTasks' &&
        !filter.isChecked
      ) {
        filteredTasks = this.removeFutureRecurringInstances(filteredTasks);
      }

      // Filter out unscheduled tasks if filter unchecked
      if (filter.key === 'filterShowUnscheduledTasks' && !filter.isChecked) {
        filteredTasks = this.removeUnscheduledTasks(filteredTasks);
      }

      // Filter out non-Today tasks if filter checked
      if (filter.key === 'filterShowTodayOnly' && filter.isChecked) {
        filteredTasks = this.removeNonTodayTasksAndSections(filteredTasks);
      }

      if (filter.key === 'filterShowEmptySections' && filter.isChecked) {
        filterShowEmptySections = true;
      }
    }

    filteredTasks = this.removeEmptySections(
      filteredTasks,
      filterShowEmptySections
    );

    return filteredTasks;
  }

  removeScheduledTasks(tasks) {
    tasks = tasks.filter(task => {
      if (task.Type !== taskType.Header && !task.IsRecurring && task.Start) {
        return false;
      }
      return true;
    });

    return tasks;
  }

  removeRecurringTasks(tasks) {
    tasks = tasks.filter(task => {
      if (task.Type !== taskType.Header && task.IsRecurring) {
        return false;
      }
      return true;
    });

    return tasks;
  }

  removeFutureRecurringInstances(tasks) {
    const items = {};
    for (let index = 0; index < tasks.length; index++) {
      const task = tasks[index];

      if (!task.IsRecurring) {
        continue;
      }

      if (items[task.Id] === true && !isBefore(task.Start, startOfTomorrow())) {
        // Remove recurring task as we already have an earlier instance in the list, and the recurring task instance is after today
        tasks.splice(index, 1);
        index--;
      } else {
        // Add recurring task Id to list
        items[task.Id] = true;
      }
    }

    return tasks;
  }

  removeUnscheduledTasks(tasks) {
    tasks = tasks.filter(task => {
      if (task.Type !== taskType.Header && !task.Start) {
        return false;
      }
      return true;
    });

    return tasks;
  }

  removeNonTodayTasksAndSections(tasks) {
    tasks = tasks.filter(task => {
      if (
        task.Type !== taskType.Header &&
        (!task.Start || !isToday(task.Start))
      ) {
        return false;
      }

      if (
        task.Type === taskType.Header &&
        task.Title !== resourceStrings.todayText &&
        task.Title !== resourceStrings.overdueText
      ) {
        return false;
      }

      return true;
    });

    return tasks;
  }

  removeEmptySections(tasks, filterShowEmptySections) {
    for (let index = 0; index < tasks.length; index++) {
      const currentTask = tasks[index];

      if (currentTask.Type !== taskType.Header) {
        continue;
      }

      const nextTask = tasks.length > index + 1 ? tasks[index + 1] : null;

      if (!nextTask || nextTask.Type === taskType.Header) {
        if (
          currentTask.Title === resourceStrings.overdueText ||
          currentTask.Title === resourceStrings.after7DaysText ||
          !filterShowEmptySections
        ) {
          tasks.splice(index, 1);
          index--;
        }
      }
    }

    return tasks;
  }
}
