import React from 'react';
import ReactDOM from 'react-dom';

import 'typeface-roboto';
import 'src/index.css';
import * as serviceWorker from 'src/serviceWorker';
import AppContainer from 'src/app/AppContainer';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

ReactDOM.render(<AppContainer />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

if (process.env.REACT_APP_APPINSIGHTS_KEY) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
      disableCorrelationHeaders: false,
      enableCorsCorrelation: true,
      correlationHeaderDomains: [
        'api.todocalendar.co',
        'test.api.todocalendar.co',
        'app.todocalendar.co',
        'test.app.todocalendar.co',
      ],
      correlationHeaderExcludedDomains: [
        'todocalendar.eu.auth0.com',
        'todocalendar.eu.auth0.com',
        'r.lr-ingest.io',
      ],
    },
  });
  appInsights.loadAppInsights();

  var telemetryInitializer = (envelope) => {
    envelope.tags['ai.cloud.role'] = 'Todo Calendar App';
    envelope.tags['ai.cloud.roleInstance'] = 'Todo Calendar App Instance';
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
}
