import React from 'react';

import { TimePicker } from '@material-ui/pickers';
import { makeStyles, InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import SimpleTooltip from 'src/components/SimpleTooltip';
import { getRoundedDateTime } from 'src/util/Utils';

const useStyles = makeStyles({
  timePicker: {
    marginBottom: '1rem',
    marginRight: '0.5rem',
    width: '100%',
  },
});

const SimpleTimePicker = ({
  allDay,
  dateTime,
  isClearButtonVisible,
  onChange,
  onClearTimeClick,
}) => {
  const classes = useStyles();

  return (
    <TimePicker
      clearable
      initialFocusedDate={getRoundedDateTime(new Date())}
      clearLabel="Clear time"
      emptyLabel="No time"
      value={allDay ? null : dateTime}
      ampm={false}
      minutesStep={15}
      className={classes.timePicker}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SimpleTooltip title="Clear time">
              <IconButton
                style={{
                  visibility: isClearButtonVisible ? 'visible' : 'hidden',
                }}
                onClick={onClearTimeClick}
                data-cy="date-dialog-clear-time-button"
              >
                <ClearIcon />
              </IconButton>
            </SimpleTooltip>
          </InputAdornment>
        ),
      }}
    ></TimePicker>
  );
};

export default SimpleTimePicker;
