export default class AppSettings {
  static dateTimeSpanExceptions = [];

  static TAG_PREFIX = '#';

  static MORNING_HOUR = 8;
  static AFTERNOON_HOUR = 13;
  static EVENING_HOUR = 18;

  static SHOW_LOADING_INDICATOR_DELAY = 2000;
  static HTTP_TIMEOUT = 30000;

  static HOUR_IN_MILLISECONDS = 1000 * 60 * 60;
  static MINUTE_IN_MILLISECONDS = 1000 * 60;

  static MIN_EVENT_LENGTH_MINS = 15;
  static TASK_START_ROUND_MINS = 15;

  static CONTEXT_MENU_HEIGHT = 192; // TODO: Find way to calculate these dynamically
  static CONTEXT_MENU_WIDTH = 172;

  static NBSP = '\u00a0';

  static DEFAULT_TASK_DURATION_MINS = 30;
  static DEFAULT_TASK_DURATION_MS =
    AppSettings.DEFAULT_TASK_DURATION_MINS * 60 * 1000;

  static RECURRING_TASKS_SHOW_FUTURE_DAYS = 7;

  static MOBILE_BREAKPOINT_PIXELS = 1024;
  static MOBILE_VIEW = false;

  static DEFAULT_COLOURS = {
    TASK_EVENT_TEXT: '#fff',
    TASK_EVENT_TEXT_COMPLETED: 'rgba(0,0,0,0.38)',
    TASK_EVENT_BG: 'rgb(97, 97, 97)',
    TASK_EVENT_BG_COMPLETED: 'rgb(208, 208, 208)',
    GOOGLE_EVENT_BG: '#4285F4',
    GOOGLE_EVENT_BG_COMPLETED: '#a1c2fa',
    TAG_BG: '#616161',
  };

  static COLOURS = [
    {
      colourRgb: 'rgb(173, 20, 87)',
      completedColourRgb: 'rgb(230, 185, 205)',
      colourHex: '#AD1457',
    },
    {
      colourRgb: 'rgb(244, 81, 30)',
      completedColourRgb: 'rgb(252, 203, 188)',
      colourHex: '#F4511E',
    },
    {
      colourRgb: 'rgb(228, 196, 65)',
      completedColourRgb: 'rgb(247, 237, 198)',
      colourHex: '#E4C441',
    },
    {
      colourRgb: 'rgb(11, 128, 67)',
      completedColourRgb: 'rgb(182, 217, 199)',
      colourHex: '#0B8043',
    },
    {
      colourRgb: 'rgb(63, 81, 181)',
      completedColourRgb: 'rgb(197, 203, 233)',
      colourHex: '#3F51B5',
    },
    {
      colourRgb: 'rgb(142, 36, 170)',
      completedColourRgb: 'rgb(221, 189, 230)',
      colourHex: '#8E24AA',
    },
    {
      colourRgb: 'rgb(216, 27, 96)',
      completedColourRgb: 'rgb(243, 187, 207)',
      colourHex: '#D81B60',
    },
    {
      colourRgb: 'rgb(239, 108, 0)',
      completedColourRgb: 'rgb(250, 211, 179)',
      colourHex: '#EF6C00',
    },
    {
      colourRgb: 'rgb(192, 202, 51)',
      completedColourRgb: 'rgb(236, 239, 194)',
      colourHex: '#C0CA33',
    },
    {
      colourRgb: 'rgb(0, 150, 136)',
      completedColourRgb: 'rgb(179, 224, 219)',
      colourHex: '#009688',
    },
    {
      colourRgb: 'rgb(121, 134, 203)',
      completedColourRgb: 'rgb(215, 219, 239)',
      colourHex: '#7986CB',
    },
    {
      colourRgb: 'rgb(121, 85, 72)',
      completedColourRgb: 'rgb(215, 204, 200)',
      colourHex: '#795548',
    },
    {
      colourRgb: 'rgb(213, 0, 0)',
      completedColourRgb: 'rgb(242, 179, 179)',
      colourHex: '#D50000',
    },
    {
      colourRgb: 'rgb(240, 147, 0)',
      completedColourRgb: 'rgb(251, 223, 179)',
      colourHex: '#F09300',
    },
    {
      colourRgb: 'rgb(124, 179, 66)',
      completedColourRgb: 'rgb(216, 232, 198)',
      colourHex: '#7CB342',
    },
    {
      colourRgb: 'rgb(3, 155, 229)',
      completedColourRgb: 'rgb(179, 225, 247)',
      colourHex: '#039BE5',
    },
    {
      colourRgb: 'rgb(179, 157, 219)',
      completedColourRgb: 'rgb(232, 226, 244)',
      colourHex: '#B39DDB',
    },
    {
      colourRgb: 'rgb(97, 97, 97)',
      completedColourRgb: 'rgb(208, 208, 208)',
      colourHex: '#616161',
    },
    {
      colourRgb: 'rgb(230, 124, 115)',
      completedColourRgb: 'rgb(248, 216, 213)',
      colourHex: '#E67C73',
    },
    {
      colourRgb: 'rgb(246, 191, 38)',
      completedColourRgb: 'rgb(252, 236, 190)',
      colourHex: '#F6BF26',
    },
    {
      colourRgb: 'rgb(51, 182, 121)',
      completedColourRgb: 'rgb(194, 233, 215)',
      colourHex: '#33B679',
    },
    {
      colourRgb: 'rgb(66, 133, 244)',
      completedColourRgb: 'rgb(198, 218, 252)',
      colourHex: '#4285F4',
    },
    {
      colourRgb: 'rgb(158, 105, 175)',
      completedColourRgb: 'rgb(226, 210, 231)',
      colourHex: '#9E69AF',
    },
    {
      colourRgb: 'rgb(167, 155, 142)',
      completedColourRgb: 'rgb(229, 225, 221)',
      colourHex: '#A79B8E',
    },
  ];

  // tslint:disable:max-line-length
  static DATE_TIME_REGEX =
    // (?xsmi)
    // String.raw`
    `\\b
      (?:
          (?:                                                 
              (?:                                             
                  (?:                                         
                      (?:
                          (?<groupNow>now)                        # now
                          
                          (?:
                              [ ]
                              for
                              [ ]
                              (?<groupNowDurationCount>
                                  [1-9]
                                  [0-9]?
                                  [.]?
                                  [0-9]?
                                  [0-9]?
                              )
                              [ ]
                              (?<groupNowDurationName>
                                  hour|minute|min
                              )
                              s?
                          )?
                      )
                      |
                      (?:
                          (?:                                     
                              in[ ]                               # in 2 minutes, in 5 hours, in 3 days, in 1 week, in 3 weeks, in 1 month
                              (?<groupInIntervalCount>
                                  [1-9][0-9]?
                              )
                              [ ]
                              (?<groupInIntervalName>
                                  min|minute|hour|day|week|month
                              )
                              s?
                          )

                          (?:
                              [ ]
                              for
                              [ ]
                              (?<groupInIntervalDurationCount>
                                  [1-9]
                                  [0-9]?
                                  [.]?
                                  [0-9]?
                                  [0-9]?
                              )
                              [ ]
                              (?<groupInIntervalDurationName>
                                  hour|minute|min
                              )
                              s?
                          )?
                      )
                      |
                      (?<groupDayName>                        # tomorrow, saturday, sun, next week, next month, end of month
                          yesterday|tod|today|tom|tomorrow|
                          mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday|
                          next[ ]week|next[ ]month|end[ ]of[ ]month
                      )
                      |
                      (?:                                     # jan 27, september 2nd
                          (?<groupMonthNameA>
                              jan|january|feb|february|mar|march|apr|april|may|jun|june|jul|july|aug|august|sep|sept|september|oct|october|nov|november|dec|december
                          )
                          [ ]
                          (?<groupMonthDateA>
                              [1-9]|[12][0-9]|3[01]
                          )
                          (?:st|nd|rd|th)?
                          (?:
                              [ ]
                              (?<groupMonthYearA>
                                  20[12][0-9]
                              )
                          )?
                      )
                      |
                      (?:                                     # 27 jan, 2nd september
                          (?<groupMonthDateB>
                              [1-9]|[12][0-9]|3[01]
                          )
                          (?:st|nd|rd|th)?
                          [ ]
                          (?<groupMonthNameB>
                              jan|january|feb|february|mar|march|apr|april|may|jun|june|jul|july|aug|august|sep|sept|september|oct|october|nov|november|dec|december
                          )
                          (?:
                              [ ]
                              (?<groupMonthYearB>
                                  20[12][0-9]
                              )
                          )?
                      )
                      |
                      (?:                           
                          (?<groupMonthDateC>                 # 15th, 31st
                              [1-9]|[12][0-9]|3[01]
                          )
                          (?:st|nd|rd|th)
                      )
                  )
                  |
                  (?:                                         
                      (?<groupDaily>daily)                    # daily
                      |
                      (?<groupWeekly>weekly)                  # weekly
                      |
                      (?<groupMonthly>monthly)                # monthly
                      |
                      (?<groupYearly>yearly)                  # yearly
                      |
                      (?:
                          (?:every|ev)
                          (?<groupEveryPeriodEveryDay>day)
                      )
                      |
                      (?:every|ev)
                      [ ]
                      (?:
                          (?:                
                              (?:                             # ev day, every 3 days, ev 2 weeks
                                  (?:
                                      (?<groupEveryPeriodCount>
                                          [1-9]|[1-9][0-9]
                                      )
                                      [ ]
                                  )?
                                  (?<groupEveryPeriod1>
                                      day|week|month|year
                                  )
                                  s?
                              )
                              |
                              (?<groupEveryPeriod2>            # every weekday, ev morning
                                  weekday|weekend|
                                  morning|afternoon|evening
                              )
                              |
                              (?<groupEveryWeekOnDays>        # ev mon, every monday, wed,fri
                                  (mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday)
                                  (
                                      [,][ ]?
                                      (mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday)
                                  )?
                                  (
                                      [,][ ]?
                                      (mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday)
                                  )?
                                  (
                                      [,][ ]?
                                      (mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday)
                                  )?
                                  (
                                      [,][ ]?
                                      (mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday)
                                  )?
                                  (
                                      [,][ ]?
                                      (mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday)
                                  )?
                                  (
                                      [,][ ]?
                                      (mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday)
                                  )?
                              )
                          ) 
                          |
                          (?:                             # ev jan 1st, every february 12
                              (?<groupEveryMonthNameA>
                                  jan|january|feb|february|mar|march|apr|april|may|jun|june|jul|july|aug|august|sep|sept|september|oct|october|nov|november|dec|december
                              )
                              [ ]
                              (?<groupEveryMonthDateA>
                                  [1-9]|[12][0-9]|3[01]
                              )
                              (?:st|nd|rd|th)?
                          )
                          |
                          (?:                             # ev 1st jan, every 12 march, ev 23, every 29th
                              (?<groupEveryMonthDateB>
                                  [1-9]|[12][0-9]|3[01]
                              )
                              (?:st|nd|rd|th)?
                              (?:
                                  [ ]
                                  (?<groupEveryMonthNameB>
                                      jan|january|feb|february|mar|march|apr|april|may|jun|june|jul|july|aug|august|sep|sept|september|oct|october|nov|november|dec|december
                                  )
                              )?
                          )
                      )
                  )
              )
          
              (?:
                  (?:
                      (?:
                          [ ]at[ ]
                          |
                          [ ]@[ ]
                          |
                          [ ]
                      )
  
                      (?<groupTimeWithDate>                       
                          (?<groupTimeWithDate12Hour>             # 5:30pm, 6pm
                              [1-9]|1[0-2]
                          )
                          (?:
                              [:\\.]
                              (?<groupTimeWithDate12Minute>
                                  [0-5][0-9]
                              )
                          )?
                          [ ]?
                          (?<groupTimeWithDate12AMPM>             
                              [ap]m
                          )
                          |
                          (?<groupTimeWithDate24Hour>             # 23:30, 08:15
                              [01][0-9]|2[0-3]
                          )
                          [:\\.]
                          (?<groupTimeWithDate24Minute>
                              [0-5][0-9]
                          )
                          |
                          (?<groupTimeWithDateNoon>               # noon
                              noon
                          )
                      )
                  )
                  
                  (?:
                      [ ]
                      for
                      [ ]
                      (?<groupDurationCount>
                          [1-9]
                          [0-9]?
                          [.]?
                          [0-9]?
                          [0-9]?
                      )
                      [ ]
                      (?<groupDurationName>
                          hour|minute|min
                      )
                      s?
                  )?
              )?

              (?:
                  [ ]
                  (?:start|starting|from|starting[ ]from|start[ ]from)
                  [ ]
                  (?:                                                 # STARTING/FROM
                      (?<groupStartingDayName>                        # tomorrow, saturday, sun, next week, next month, end of month
                          tod|today|tom|tomorrow|
                          mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday|
                          next[ ]week|next[ ]month|end[ ]of[ ]month
                      )
                      |
                      (?:                                             # jan 27, september 2nd
                          (?<groupStartingMonthNameA>
                              jan|january|feb|february|mar|march|apr|april|may|jun|june|jul|july|aug|august|sep|sept|september|oct|october|nov|november|dec|december
                          )
                          [ ]
                          (?<groupStartingMonthDateA>
                              [1-9]|[12][0-9]|3[01]
                          )
                          (?:st|nd|rd|th)?
                          (?:
                              [ ]
                              (?<groupStartingMonthYearA>
                                  20[12][0-9]
                              )
                          )?
                      )
                      |
                      (?:                                             # 27 jan, 2nd september
                          (?<groupStartingMonthDateB>
                              [1-9]|[12][0-9]|3[01]
                          )
                          (?:st|nd|rd|th)?
                          [ ]
                          (?<groupStartingMonthNameB>
                              jan|january|feb|february|mar|march|apr|april|may|jun|june|jul|july|aug|august|sep|sept|september|oct|october|nov|november|dec|december
                          )
                          (?:
                              [ ]
                              (?<groupStartingMonthYearB>
                                  20[12][0-9]
                              )
                          )?
                      )
                      |
                      (?:                           
                          (?<groupStartingMonthDateC>                 # 15th, 31st
                              [1-9]|[12][0-9]|3[01]
                          )
                          (?:st|nd|rd|th)
                      )
                  )
                  |
                  [ ]
                  (?:
                      starting[ ]
                      in[ ]                                           # in 2 minutes, in 5 hours, in 3 days, in 1 week, in 3 weeks, in 1 month
                      (?<groupStartingInIntervalCount>
                          [1-9][0-9]?
                      )
                      [ ]
                      (?<groupStartingInIntervalName>
                          day|week|month
                      )
                      s?
                  )
              )?

              (?:
                  [ ]
                  (?:
                      (?:until|ending)
                      [ ]
                      (?:
                          (?<groupEndingDayName>                        # tomorrow, saturday, sun, next week, next month, end of month
                              yesterday|tod|today|tom|tomorrow|
                              mon|monday|tue|tues|tuesday|wed|wednesday|thu|thur|thurs|thursday|fri|friday|sat|saturday|sun|sunday|
                              next[ ]week|next[ ]month|end[ ]of[ ]month
                          )
                          |
                          (?:                                     # jan 27, september 2nd
                              (?<groupEndingMonthNameA>
                                  jan|january|feb|february|mar|march|apr|april|may|jun|june|jul|july|aug|august|sep|sept|september|oct|october|nov|november|dec|december
                              )
                              [ ]
                              (?<groupEndingMonthDateA>
                                  [1-9]|[12][0-9]|3[01]
                              )
                              (?:st|nd|rd|th)?
                              (?:
                                  [ ]
                                  (?<groupEndingMonthYearA>
                                      20[12][0-9]
                                  )
                              )?
                          )
                          |
                          (?:                                     # 27 jan, 2nd september
                              (?<groupEndingMonthDateB>
                                  [1-9]|[12][0-9]|3[01]
                              )
                              (?:st|nd|rd|th)?
                              [ ]
                              (?<groupEndingMonthNameB>
                                  jan|january|feb|february|mar|march|apr|april|may|jun|june|jul|july|aug|august|sep|sept|september|oct|october|nov|november|dec|december
                              )
                              (?:
                                  [ ]
                                  (?<groupEndingMonthYearB>
                                      20[12][0-9]
                                  )
                              )?
                          )
                          |
                          (?:                           
                              (?<groupEndingMonthDateC>                 # 15th, 31st
                                  [1-9]|[12][0-9]|3[01]
                              )
                              (?:st|nd|rd|th)
                          )
                      )
                  )
                  |
                  [ ]
                  (?:
                      (?:for|ending[ ]in)
                      [ ]
                      (?<groupEndingInIntervalCount>
                          [1-9][0-9]?
                      )
                      [ ]
                      (?<groupEndingInIntervalName>
                          day|week|month
                      )
                      s?
                  )
              )?
          )
          |
          (?:         
              (?:
                  (?<groupTimeOnly12Hour>                         # 5:30pm, 6pm
                      [1-9]|1[0-2]
                  )
                  (?:
                      [:\\.]
                      (?<groupTimeOnly12Minute>
                          [0-5][0-9]
                      )
                  )?
                  [ ]?
                  (?<groupTimeOnly12AMPM>
                      [ap]m
                  )
                  |
                  (?<groupTimeOnly24Hour>                         # 23:30, 08:15
                      [01][0-9]|2[0-3]
                  )
                  [:\\.]
                  (?<groupTimeOnly24Minute>
                      [0-5][0-9]
                  )
                  |
                  (?<groupTimeOnlyNoon>                         # noon
                      noon
                  )
              )                  

              (?:
                  [ ]
                  for
                  [ ]
                  (?<groupTimeOnlyDurationCount>
                      [1-9]
                      [0-9]?
                      [.]?
                      [0-9]?
                      [0-9]?
                  )
                  [ ]
                  (?<groupTimeOnlyDurationName>
                      hour|minute|min
                  )
                  s?
              )?
          )
      )
      \\b(?!<\\/span>)`;

  /*
  TEST STRINGS
  
  yesterday
  tod
  today
  tom
  tomorrow
  mon
  monday
  tue
  tuesday
  wed
  wednesday
  thu
  thursday
  fri
  friday
  sat
  saturday
  sun at 5:30pm
  sunday 2:15am
  next week at 11pm
  next mon 01:30
  next month at 09:45
  end of month 17:00
  
  in 1 minute
  in 2 minutes
  in 12 minutes
  in 1 hour at 5:30pm
  in 2 hours 2:15am
  in 1 week at 11pm
  in 55 weeks 01:30
  in 1 month at 09:45
  in 22 months 17:00
  
  jan 1st
  january 12th
  feb 1st
  february 31st
  mar 1st
  march 12th
  apr 1st
  april 29th
  may 1st
  may 31st
  jun 3rd
  june 31st
  jul 6th
  july 12th
  aug 16th
  august 29th
  sep 1st
  sept 10th
  september 11th
  oct 1st at 5:30pm
  october 22nd 2:15am
  nov 1st at 11pm
  november 3rd 01:30
  dec 22nd at 09:45
  december 29th 17:00
  
  1 jan
  1st january
  2 feb
  23rd february
  1st mar
  29 march
  4 apr
  11th april
  6 may
  12th may
  31 jun
  1st june
  9th jul
  10 july
  1 aug
  1st august
  31 sep
  31st sept
  20th september
  1 oct at 5:30pm
  1st october 2:15am
  2 nov at 11pm
  3rd november 01:30
  5 dec @ 09:45
  17th december 17:00
  
  1st
  2nd
  10th
  15th
  19th 17:00
  20th at 5:30pm
  21st 2:15am
  29th at 11pm
  30th 01:30
  31st at 09:45
  
  ev day
  every weekday
  ev morning
  every afternoon
  ev evening
  every mon
  every monday
  ev tue
  ev tuesday
  every wed
  every wednesday
  ev thu
  ev thursday
  every fri at 5:30pm
  every friday 2:15am
  ev sat at 11pm
  ev saturday 01:30
  every sun at 09:45
  every sunday 17:00
  
  ev jan 1st
  every january 12th
  ev feb 1st
  every february 31st
  ev mar 1st
  every march 12th
  ev apr 1st
  every april 29th
  ev may 1st
  every may 31st
  ev jun 3rd
  every june 31st
  ev jul 6th
  every july 12th
  ev aug 16th
  every august 29th
  ev sep 1st
  every sept 10th
  ev september 11th
  every oct 1st
  ev october 22nd
  every nov 1st
  ev november 3rd
  every dec 22nd
  ev december 29th
  every oct 1st at 5:30pm
  ev october 22nd 2:15am
  every nov 1st at 11pm
  ev november 3rd 01:30
  every dec 22nd at 09:45
  ev december 29th 17:00
  
  every 7th
  ev 1
  every 31st
  ev 22nd
  every 11 jan
  ev 27th september
  every 7th at 5:30pm
  ev 1 2:15am
  every 31st at 11pm
  ev 22nd 01:30
  every 11 jan at 09:45
  ev 27th september 17:00
  
  5:30pm
  2:15am
  11pm
  01:30
  09:45
  17:00
  
  */
  static getCompletedColour(colour) {
    const index = this.COLOURS.findIndex(c => c.colourRgb === colour);
    if (index > -1) {
      return this.COLOURS[index].completedColourRgb;
    } else {
      return this.DEFAULT_COLOURS.TASK_EVENT_BG_COMPLETED;
    }
  }

  static dayOfWeekNumbers = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  };

  static monthNumbers = {
    jan: 0,
    january: 0,
    feb: 1,
    february: 1,
    mar: 2,
    march: 2,
    apr: 3,
    april: 3,
    may: 4,
    jun: 5,
    june: 5,
    jul: 6,
    july: 6,
    aug: 7,
    august: 7,
    sep: 8,
    sept: 8,
    september: 8,
    oct: 9,
    october: 9,
    nov: 10,
    november: 10,
    dec: 11,
    december: 11,
  };
}
