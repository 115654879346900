import AppSettings from 'src/constants/AppSettings';

export class Tag {
  constructor(Name, Colour, Id, CreatedDateTime, UpdatedDateTime) {
    this.Name = Name;
    this.Colour = Colour ? Colour : AppSettings.DEFAULT_COLOURS.TAG_BG;
    this.Id = Id;
    this.CreatedDateTime = CreatedDateTime ? CreatedDateTime : new Date();
    this.UpdatedDateTime = UpdatedDateTime ? UpdatedDateTime : new Date();
  }
}
