import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';

import {
  Dialog,
  DialogContent,
  useMediaQuery,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  DialogActions,
  Button,
  Grid,
  Popover,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import { CirclePicker } from 'react-color';

import SimpleTooltip from 'src/components/SimpleTooltip';
import { useTasks } from 'src/providers/TasksProvider';
import { Tag } from 'src/model/Tag';
import { useSnackbar } from 'src/providers/SnackbarProvider';
import { resourceStrings } from 'src/constants/constants';
import { useDialog } from 'src/providers/DialogProvider';
import AppSettings from 'src/constants/AppSettings';
import { cloneTag } from 'src/util/TaskUtil';

const TagDialogContext = createContext();
export const useTagDialog = () => useContext(TagDialogContext);

const useStyles = makeStyles({
  toolbar: {
    borderBottom: '1px solid rgba(0,0,0,.10)',
    marginBottom: '1rem',
  },
  textField: {
    minHeight: '76px',
    marginBottom: '1rem',
  },
  colourDisplayContainer: {
    paddingLeft: '0.5rem',
  },
  colourDisplay: {
    width: '3rem',
    height: '3rem',
    marginTop: '0.25rem',
    borderRadius: '50%',
    transition: 'all 100ms ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  popover: {
    padding: '1.4rem',
  },
});

const PaperComponent = ({ height, ...other }) => {
  return <div style={{ height: height, minHeight: '250px' }} {...other} />;
};

export const TagDialogProvider = ({ children }) => {
  const classes = useStyles();

  const [isTagDialogOpen, setIsTagDialogOpen] = useState(false);

  const [tagForEdit, setTagForEdit] = useState(null);

  const [tagName, setTagName] = useState('');
  const [isTagFieldTouched, setIsTagFieldTouchedInternal] = useState(false);
  const [isTagFieldValid, setIsTagFieldValid] = useState(false);
  const [tagFieldErrorText, setTagFieldErrorText] = useState(null);

  const [height, setHeight] = useState(null);

  const [tagColour, setTagColour] = useState(
    AppSettings.DEFAULT_COLOURS.TAG_BG
  );

  const [isColourPickerOpen, setIsColourPickerOpen] = useState(false);
  const colourPickerRef = useRef();

  const onColourPickerClick = () => {
    setIsColourPickerOpen(true);
  };

  const onColourPickerClose = () => {
    setIsColourPickerOpen(false);
  };

  const { tasks, tags, addOrUpdateTag, deleteTag } = useTasks();
  const { showSnackbar } = useSnackbar();
  const { openDialog } = useDialog();

  const colours = AppSettings.COLOURS.map(colour => colour.colourHex);

  const setIsTagFieldTouched = value => {
    setIsTagFieldTouchedInternal(value);
  };

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const openTagDialog = (tag, height) => {
    setIsTagDialogOpen(true);
    setTagForEdit(tag);
    setTagName(tag ? tag.Name.replace('#', '') : '');
    setTagColour(tag ? tag.Colour : AppSettings.DEFAULT_COLOURS.TAG_BG);
    setHeight(height);
    setIsTagFieldValid(true);
  };

  const onTagDialogCloseClick = () => {
    closeTagDialog();
  };

  const onTagDialogClose = () => {
    closeTagDialog();
  };

  const onTagDialogConfirm = () => {
    confirmTagDialog();
  };

  const confirmTagDialog = async () => {
    if (!isTagFieldValid) {
      return;
    }

    if (!tagForEdit) {
      const newTag = new Tag(`#${tagName}`, tagColour);

      const result = await addOrUpdateTag(newTag, null);

      if (!result) {
        showSnackbar(resourceStrings.errorAddingTag);
        return;
      }
      showSnackbar(resourceStrings.tagAdded);
    } else {
      const updatedTag = cloneTag(tagForEdit);
      updatedTag.Name = `#${tagName}`;
      updatedTag.Colour = tagColour;

      const result = await addOrUpdateTag(updatedTag, tagForEdit);

      if (!result) {
        showSnackbar(resourceStrings.errorUpdatingTag);
        return;
      }
      showSnackbar(resourceStrings.tagUpdated);
    }

    closeTagDialog();
  };

  const closeTagDialog = () => {
    setIsTagFieldTouched(false);
    setIsTagFieldValid(false);
    setTagFieldErrorText(null);
    setIsTagDialogOpen(false);
  };

  const onDeleteTagButtonClick = async () => {
    const tasksWithTag = tasks.filter(
      task => task.Tags.filter(tag => tag.Id === tagForEdit.Id).length > 0
    );

    const activeTasksWithTagCount = tasksWithTag.filter(
      task => task.IsCompleted === false
    ).length;
    const completedTasksWithTagCount = tasksWithTag.filter(
      task => task.IsCompleted === true
    ).length;

    const activeS = activeTasksWithTagCount !== 1 ? 's' : '';
    const completedS = completedTasksWithTagCount !== 1 ? 's' : '';

    const result = await openDialog(
      `Delete Tag ${tagForEdit.Name}`,
      `Tag '${tagForEdit.Name}' is assigned to ${activeTasksWithTagCount} active task${activeS} and ${completedTasksWithTagCount} completed task${completedS}. Permanently delete this tag?`
    );
    if (result) {
      const result = await deleteTag(tagForEdit);
      if (!result) {
        showSnackbar(resourceStrings.errorDeletingTag);
        return;
      }
      showSnackbar(resourceStrings.tagDeleted);
      closeTagDialog();
    }
  };

  const onTagNameChange = event => {
    setTagName(event.target.value);
  };

  const onTagNameBlur = () => {
    if (!isTagDialogOpen) {
      return;
    }

    if (!isTagFieldTouched) {
      setIsTagFieldTouched(true);
    }
  };

  useEffect(() => {
    validateTagField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagName, isTagFieldTouched]);

  const validateTagField = () => {
    if (tagName.length === 0) {
      setIsTagFieldValid(false);
      setTagFieldErrorText('Tag name is required');
      return;
    }

    if (tagName.indexOf('#') > -1) {
      setIsTagFieldValid(false);
      setTagFieldErrorText('Tag name must not contain #');
      return;
    }

    if (tagName.indexOf(' ') > -1) {
      setIsTagFieldValid(false);
      setTagFieldErrorText('Tag name must not contain spaces');
      return;
    }

    const tagsToValidate = !tagForEdit
      ? tags
      : tags.filter(tag => tag.Id !== tagForEdit.Id);
    if (tagsToValidate.some(tag => tag.Name === `#${tagName}`)) {
      setIsTagFieldValid(false);
      setTagFieldErrorText('Tag name already exists');
      return;
    }

    setIsTagFieldValid(true);
    setTagFieldErrorText(null);
  };

  const onColourChangeComplete = colour => {
    setTagColour(colour.hex);
    setIsColourPickerOpen(false);
  };

  return (
    <TagDialogContext.Provider value={{ openTagDialog }}>
      {children}
      <Dialog
        open={isTagDialogOpen}
        onClose={onTagDialogClose}
        fullWidth={true}
        fullScreen={!isDesktop}
        scroll="paper"
        PaperProps={{
          component: PaperComponent,
          height: isDesktop ? height : null,
        }}
      >
        <Toolbar className={classes.toolbar} disableGutters>
          <SimpleTooltip title="Go back">
            <IconButton onClick={onTagDialogCloseClick}>
              <ArrowBackIcon />
            </IconButton>
          </SimpleTooltip>

          <Typography
            variant="h6"
            color="inherit"
            style={{ flexGrow: 1, userSelect: 'none' }}
            data-cy="tag-dialog-title"
          >
            {tagForEdit ? 'Edit Tag' : 'Add Tag'}
          </Typography>

          {tagForEdit && (
            <SimpleTooltip title="Delete tag">
              <IconButton
                onClick={onDeleteTagButtonClick}
                data-cy="tag-dialog-delete-button"
              >
                <DeleteIcon />
              </IconButton>
            </SimpleTooltip>
          )}
        </Toolbar>

        <DialogContent>
          <Grid container wrap="nowrap">
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                label="Tag Name"
                variant="outlined"
                fullWidth
                autoFocus
                inputProps={{ maxLength: '60' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  ),
                }}
                className={classes.textField}
                value={tagName}
                onChange={onTagNameChange}
                onBlur={onTagNameBlur}
                error={
                  tagForEdit
                    ? !isTagFieldValid
                    : isTagFieldTouched && !isTagFieldValid
                }
                helperText={
                  tagForEdit
                    ? tagFieldErrorText
                    : isTagFieldTouched && tagFieldErrorText
                }
                data-cy="tag-dialog-input-text"
              />
            </Grid>
            <Grid item className={classes.colourDisplayContainer}>
              <div
                className={classes.colourDisplay}
                style={{ backgroundColor: tagColour }}
                onClick={onColourPickerClick}
                ref={colourPickerRef}
                data-cy="tag-dialog-color-button"
              ></div>
            </Grid>
          </Grid>

          <Popover
            classes={{ paper: classes.popover }}
            anchorEl={colourPickerRef.current}
            open={isColourPickerOpen}
            onClose={onColourPickerClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <div data-cy="tag-dialog-colour-picker">
              <CirclePicker
                width={248}
                color={tagColour}
                colors={colours}
                circleSize={48}
                onChangeComplete={onColourChangeComplete}
              ></CirclePicker>
            </div>
          </Popover>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onTagDialogClose}
            color="primary"
            data-cy="tag-dialog-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={onTagDialogConfirm}
            color="primary"
            disabled={!isTagFieldValid}
            data-cy="tag-dialog-save-button"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </TagDialogContext.Provider>
  );
};
