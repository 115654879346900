import React, { useState } from 'react';

import {
  Menu,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import DuplicateIcon from '@material-ui/icons/FileCopy';
import UncompleteIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import UnscheduleIcon from '@material-ui/icons/RemoveCircleOutline';

import SimpleTooltip from 'src/components/SimpleTooltip';
import { useTasks } from 'src/providers/TasksProvider';
import { useDialog } from 'src/providers/DialogProvider';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'src/providers/SnackbarProvider';
import { useDateDialog } from 'src/providers/DateDialogProvider';

const MoreMenu = ({ className }) => {
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const {
    selectedTasks,
    setTasksCompletionStatus,
    onTasksUpdateSnackbarUndo,
    deleteTasks,
    setTasksUnscheduled,
  } = useTasks();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { openDateDialog } = useDateDialog();

  const onMenuOpen = event => {
    setMenuAnchorElement(event.currentTarget);
  };

  const onMenuClose = () => {
    closeMenu();
  };

  const closeMenu = () => {
    setMenuAnchorElement(null);
  };

  const canCompleteSeries = () => {
    if (selectedTasks.length !== 1) {
      return false;
    }

    return selectedTasks.every(
      selectedTask => selectedTask.IsRecurring && !selectedTask.IsCompleted
    );
  };

  const canDeleteSeries = () => {
    if (selectedTasks.length !== 1) {
      return false;
    }

    return selectedTasks.every(selectedTask => selectedTask.IsRecurring);
  };

  const canUncomplete = () => {
    if (selectedTasks.length === 0) {
      return false;
    }

    return selectedTasks.every(selectedTask => selectedTask.IsCompleted);
  };

  const canUncompleteSeries = () => {
    if (selectedTasks.length !== 1) {
      return false;
    }

    return selectedTasks.every(
      selectedTask => selectedTask.IsRecurring && selectedTask.IsCompleted
    );
  };

  const canUnschedule = () => {
    if (selectedTasks.length === 0) {
      return false;
    }

    return selectedTasks.every(
      selectedTask => selectedTask.Start && !selectedTask.IsRecurring
    );
  };

  useHotkeys(
    's',
    e => {
      e.preventDefault();
      onScheduleClick();
    },
    [{}]
  );
  const onScheduleClick = () => {
    closeMenu();

    if (!selectedTasks || selectedTasks.length === 0) {
      return;
    }

    if (selectedTasks.filter(t => t.IsRecurring).length > 0) {
      showSnackbar('Recurring tasks cannot be rescheduled.');
      return;
    }

    openDateDialog();
  };

  const onCompleteSeriesClick = () => {
    closeMenu();

    if (!canCompleteSeries()) {
      return;
    }

    setTasksCompletionStatus(
      [selectedTasks[0]],
      true,
      onTasksUpdateSnackbarUndo,
      false
    );
  };

  const onDeleteSeriesClick = async () => {
    closeMenu();

    if (!canDeleteSeries()) {
      return;
    }

    const result = await openDialog(
      '',
      `Permanently delete task series? This operation cannot be undone.`
    );
    if (result) {
      deleteTasks(selectedTasks, true);
    }
  };

  const onUncompleteClick = () => {
    closeMenu();

    if (!canUncomplete()) {
      return;
    }

    setTasksCompletionStatus(selectedTasks, false);
  };

  const onUncompleteSeriesClick = () => {
    closeMenu();

    if (!canUncompleteSeries()) {
      return;
    }

    setTasksCompletionStatus([selectedTasks[0]], false, null, false);
  };

  useHotkeys('u', () => onUnscheduleClick(), [{}]);
  const onUnscheduleClick = () => {
    closeMenu();

    if (!canUnschedule()) {
      return;
    }

    setTasksUnscheduled(selectedTasks);
  };

  return (
    <div>
      <SimpleTooltip title="Menu">
        <IconButton onClick={onMenuOpen} className={className}>
          <MoreVertIcon />
        </IconButton>
      </SimpleTooltip>

      <Menu
        disableAutoFocusItem
        anchorEl={menuAnchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menuAnchorElement)}
        onClose={onMenuClose}
      >
        <MenuItem onClick={onScheduleClick}>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText>Schedule</ListItemText>
        </MenuItem>

        {canUnschedule() && (
          <MenuItem onClick={onUnscheduleClick}>
            <ListItemIcon>
              <UnscheduleIcon />
            </ListItemIcon>
            <ListItemText>Unschedule</ListItemText>
          </MenuItem>
        )}

        {canCompleteSeries() && (
          <MenuItem onClick={onCompleteSeriesClick}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText>Complete Series</ListItemText>
          </MenuItem>
        )}

        {canDeleteSeries() && (
          <MenuItem onClick={onDeleteSeriesClick}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete Series</ListItemText>
          </MenuItem>
        )}

        {canUncomplete() && (
          <MenuItem onClick={onUncompleteClick}>
            <ListItemIcon>
              <UncompleteIcon />
            </ListItemIcon>
            <ListItemText>Uncomplete</ListItemText>
          </MenuItem>
        )}

        {canUncompleteSeries() && (
          <MenuItem onClick={onUncompleteSeriesClick}>
            <ListItemIcon>
              <UncompleteIcon />
            </ListItemIcon>
            <ListItemText>Uncomplete Series</ListItemText>
          </MenuItem>
        )}

        {/* <MenuItem onClick={onMenuClose}>
          <ListItemIcon>
            <DuplicateIcon />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem> */}
      </Menu>
    </div>
  );
};

export default MoreMenu;
