import React, { useContext, createContext, useState, useRef } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const ProgressContext = createContext();
export const useProgress = () => useContext(ProgressContext);

const useStyles = makeStyles(theme => ({
  progressContainer: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    lineHeight: '38px',
    top: '22px',
    left: 'calc(50% - 20px)',
    textAlign: 'center',
    borderRadius: '20px',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.3)',
    zIndex: 1500,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      top: '14px',
    },
  },
  progress: {
    verticalAlign: 'middle',
    animationDuration: '1.2s',
  },
}));

export const ProgressProvider = ({ children }) => {
  const classes = useStyles();

  const [isProgressShown, setIsProgressShown] = useState(false);
  const progressListRef = useRef({});
  const progressTimeoutRef = useRef({});

  const showProgress = (label = 'default', immediate) => {
    if (progressTimeoutRef.current[label]) {
      return;
    }

    progressTimeoutRef.current[label] = setTimeout(() => {
      progressListRef.current[label] = true;
      updateIsProgressShown();
    }, 800);
  };

  const hideProgress = (label = 'default', immediate) => {
    progressListRef.current[label] = false;

    updateIsProgressShown();
    clearTimeout(progressTimeoutRef.current[label]);
    progressTimeoutRef.current[label] = null;
  };

  const updateIsProgressShown = () => {
    const isProgressShown = Object.values(progressListRef.current).some(
      value => value === true
    );
    setIsProgressShown(isProgressShown);
  };

  return (
    <ProgressContext.Provider value={{ showProgress, hideProgress }}>
      {children}
      {isProgressShown && (
        <div className={classes.progressContainer}>
          <CircularProgress
            size={21}
            thickness={5}
            disableShrink={true}
            className={classes.progress}
          />
        </div>
      )}
    </ProgressContext.Provider>
  );
};
