import AppSettings from 'src/constants/AppSettings';
import { isValid, differenceInMinutes, format } from 'date-fns';
import { constants } from 'src/constants/constants';

export const getCalendarEventsFromTasks = (
  tasks,
  eventSourceId,
  isCalendarEvent
) => {
  if (!tasks) {
    return null;
  }

  const eventsFromTasks = tasks
    .filter(t => t.Start != null)
    .map(task => {
      if (isCalendarEvent) {
        task.IsCalendarEvent = true;
      }

      return {
        id: getEventIdForTask(task),
        title: task.Title,
        start: task.Start,
        end: task.End,
        allDay: task.AllDay,
        editable: isCalendarEvent ? false : true,
        extendedProps: {
          task: task,
          sortOrder: isCalendarEvent ? 0 : 1,
        },
      };
    });

  const eventSource = {
    events: eventsFromTasks,
    id: eventSourceId,
  };

  return eventSource;
};

export const getEventIdForTask = task => {
  if (!task.IsRecurring) {
    return task.Id.toString();
  } else {
    // TODO: Figure out best way to form this ID based on recurrence changes will make including allowing to move a recurring instance to e.g. another day so that this would id not change when drag/drop somehow (if it does then will not be able to select dropped event until event recreated with new id)
    // E.g. at least add time to it?
    return task.Id.toString() + format(task.Start, 'yyyy-MM-dd');
  }
};

export const getEventColoursForTask = (task, calendarEvent) => {
  const textColour = !task.IsCompleted
    ? AppSettings.DEFAULT_COLOURS.TASK_EVENT_TEXT
    : AppSettings.DEFAULT_COLOURS.TASK_EVENT_TEXT_COMPLETED;

  let backgroundColour;

  if (calendarEvent) {
    backgroundColour = !task.IsCompleted
      ? AppSettings.DEFAULT_COLOURS.GOOGLE_EVENT_BG
      : AppSettings.DEFAULT_COLOURS.GOOGLE_EVENT_BG_COMPLETED;
  } else if (task.Tags && task.Tags.length > 0) {
    backgroundColour = !task.IsCompleted
      ? task.Tags[0].Colour
      : AppSettings.getCompletedColour(task.Tags[0].Colour);
  } else {
    backgroundColour = !task.IsCompleted
      ? AppSettings.DEFAULT_COLOURS.TASK_EVENT_BG
      : AppSettings.DEFAULT_COLOURS.TASK_EVENT_BG_COMPLETED;
  }

  return [textColour, backgroundColour];
};

export const getCalendarDurationString = (start, end) => {
  if (!isValidDate(start) || !isValidDate(end)) {
    return null;
  }

  const eventMins = differenceInMinutes(end, start);
  if (eventMins < 0) {
    return null;
  }

  const eventHours = eventMins / 60;

  let durationString;
  if (eventMins < 60) {
    durationString = `${eventMins}m`;
  } else {
    durationString = `${Math.round(eventHours * 100) / 100}h`;
  }

  return durationString;
};

export const getCalendarHeaderDateString = date => {
  if (!isValidDate(date)) {
    return '';
  }

  if (window.innerWidth < constants.smBreakpoint) {
    return format(date, 'MMM');
  } else {
    return format(date, 'MMMM yyyy');
  }
};

export const isValidDate = input => {
  if (input && input instanceof Date && isValid(input)) {
    return true;
  }
  return false;
};
