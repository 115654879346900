import React from 'react';

import { DatePicker } from '@material-ui/pickers';
import { makeStyles, InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import SimpleTooltip from 'src/components/SimpleTooltip';

const useStyles = makeStyles({
  datePicker: {
    marginBottom: '1rem',
    marginRight: '0.5rem',
    width: '100%',
  },
});

const SimpleDatePicker = ({
  dateTime,
  isClearButtonVisible,
  onChange,
  onClearDateClick,
}) => {
  const classes = useStyles();

  return (
    <DatePicker
      clearable
      autoOk
      value={dateTime}
      format="dd/MM/yyyy"
      clearLabel="Clear"
      emptyLabel="No schedule"
      onChange={onChange}
      className={classes.datePicker}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SimpleTooltip title="Clear schedule">
              <IconButton
                style={{
                  visibility: isClearButtonVisible ? 'visible' : 'hidden',
                }}
                onClick={onClearDateClick}
                data-cy="date-dialog-clear-date-button"
              >
                <ClearIcon />
              </IconButton>
            </SimpleTooltip>
          </InputAdornment>
        ),
      }}
    ></DatePicker>
  );
};

export default SimpleDatePicker;
