import React, { useState, useEffect, useContext, createContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import LogRocket from 'logrocket';
import packageJson from 'src/../package.json';

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const Auth0Context = createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0Client] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0ClientLocal = await createAuth0Client({
        audience: 'https://api.todocalendar.com',
        client_id: 'a8fdgllhGAVROpiHl23RDnibZkXYiq3T',
        domain: 'todocalendar.eu.auth0.com',
        redirect_uri: window.location.origin,
      });
      setAuth0Client(auth0ClientLocal);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0ClientLocal.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0ClientLocal.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0ClientLocal.getUser();
        setUser(user);
      }

      setIsLoading(false);
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (process.env.REACT_APP_ENV !== 'production') {
      // Don't record outside of Production
      return;
    }

    if (user.sub === 'google-oauth2|103162656978562295687') {
      // Don't record for Jonathan Dudley
      return;
    }

    LogRocket.init(`zb6j5h/todo-calendar-${process.env.REACT_APP_ENV}`, {
      release: packageJson.version,
    });

    LogRocket.identify(user.sub, {
      name: user.name,
      email: user.email,
    });
  }, [user]);

  const loginWithPopup = async (params = {}) => {
    setIsPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setIsPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setIsLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setIsLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        isPopupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: () => auth0Client.logout({ returnTo: window.location.origin }),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
