import React, { createContext, useContext, useState, useRef } from 'react';

import {
  Dialog,
  DialogContent,
  useMediaQuery,
  Toolbar,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';

import { useTasks } from 'src/providers/TasksProvider';
import SimpleTooltip from 'src/components/SimpleTooltip';
import { useTagDialog } from 'src/providers/TagDialogProvider';
import { sortTagsByName } from 'src/util/TaskUtil';

const ManageTagsDialogContext = createContext();
export const useManageTagsDialog = () => useContext(ManageTagsDialogContext);

const useStyles = makeStyles({
  toolbar: {
    borderBottom: '1px solid rgba(0,0,0,.10)',
    marginBottom: '1rem',
  },
  dialogPaper: {
    minHeight: '150px',
  },
  typographyCenter: {
    textAlign: 'center',
    marginTop: '5px',
  },
  tag: {
    fontSize: '1rem',
    color: '#fff',
    padding: '0.1rem',
    borderRadius: '0.2rem',
    margin: '0 0.5rem 1rem 0',
    cursor: 'pointer',
  },
});

const PaperComponent = ({ dialogRef, ...other }) => {
  return <div ref={dialogRef} {...other} />;
};

export const ManageTagsDialogProvider = ({ children }) => {
  const classes = useStyles();

  const [isManageTagsDialogOpen, setIsManageTagsDialogOpen] = useState(false);

  const { tags } = useTasks();
  const { openTagDialog } = useTagDialog();

  const dialogRef = useRef();

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const openManageTagsDialog = () => {
    setIsManageTagsDialogOpen(true);
  };

  const onManageTagsDialogCloseClick = () => {
    closeManageTagsDialog();
  };

  const onManageTagsDialogClose = () => {
    closeManageTagsDialog();
  };

  const closeManageTagsDialog = () => {
    setIsManageTagsDialogOpen(false);
  };

  const onAddTagButtonClick = () => {
    openTagDialog(null, dialogRef.current.offsetHeight);
  };

  const onTagClick = tag => {
    openTagDialog(tag, dialogRef.current.offsetHeight);
  };

  return (
    <ManageTagsDialogContext.Provider
      value={{
        openManageTagsDialog,
        manageTagsDialogHeight: dialogRef.current
          ? dialogRef.current.offsetHeight
          : null,
      }}
    >
      {children}
      <Dialog
        open={isManageTagsDialogOpen}
        onClose={onManageTagsDialogClose}
        fullWidth={true}
        fullScreen={!isDesktop}
        scroll="paper"
        PaperProps={{ component: PaperComponent, dialogRef: dialogRef }}
        data-cy="manage-tags-dialog"
        classes={{ paper: classes.dialogPaper }}
      >
        <Toolbar className={classes.toolbar} disableGutters>
          <SimpleTooltip title="Go back">
            <IconButton
              onClick={onManageTagsDialogCloseClick}
              data-cy="manage-tags-dialog-back-button"
            >
              <ArrowBackIcon />
            </IconButton>
          </SimpleTooltip>

          <Typography
            variant="h6"
            color="inherit"
            style={{ flexGrow: 1, userSelect: 'none' }}
            data-cy="manage-tags-dialog-title"
          >
            Manage Tags
          </Typography>

          <SimpleTooltip title="Add tag">
            <IconButton
              onClick={onAddTagButtonClick}
              data-cy="manage-tags-add-tag-button"
            >
              <AddIcon />
            </IconButton>
          </SimpleTooltip>
        </Toolbar>

        <DialogContent>
          {tags && tags.length > 0 && (
            <Grid container justify="center">
              {sortTagsByName(tags).map(tag => (
                <Grid
                  item
                  key={tag.Id}
                  className={classNames(
                    classes.tag,
                    'waves-effect waves-effect-color'
                  )}
                  style={{ backgroundColor: tag.Colour }}
                  onClick={() => onTagClick(tag)}
                  data-cy="manage-tags-dialog-tag"
                >
                  {tag.Name}
                </Grid>
              ))}
            </Grid>
          )}
          {tags && tags.length === 0 && (
            <Typography
              className={classes.typographyCenter}
              data-cy="manage-tags-dialog-no-tags-message"
            >
              No Tags have been added.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </ManageTagsDialogContext.Provider>
  );
};
