import React, { useState } from 'react';

import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';

import SimpleTooltip from 'src/components/SimpleTooltip';
import { resourceStrings } from 'src/constants/constants';
import { setLocalStorage } from 'src/util/StorageUtil';

const CalendarFilterMenu = ({
  showScheduledTasks,
  setShowScheduledTasks,
  showRecurringTasks,
  setShowRecurringTasks,
  showEvents,
  setShowEvents,
}) => {
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const handleMenuOpenClick = event => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    closeMenu();
  };

  const onShowScheduledTasksToggle = () => {
    setLocalStorage('filterCalendarShowScheduledTasks', !showScheduledTasks);
    setShowScheduledTasks(!showScheduledTasks);
    closeMenu();
  };

  const onShowRecurringTasksToggle = () => {
    setLocalStorage('filterCalendarShowRecurringTasks', !showRecurringTasks);
    setShowRecurringTasks(!showRecurringTasks);
    closeMenu();
  };

  const onShowEventsToggle = () => {
    setLocalStorage('filterCalendarShowEvents', !showEvents);
    setShowEvents(!showEvents);
    closeMenu();
  };

  const closeMenu = () => {
    setMenuAnchorElement(null);
  };

  return (
    <div>
      <SimpleTooltip title="Filter tasks">
        <IconButton
          onClick={handleMenuOpenClick}
          data-cy="calendar-filter-menu-button"
        >
          <FilterIcon />
        </IconButton>
      </SimpleTooltip>

      <Menu
        disableAutoFocusItem
        anchorEl={menuAnchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(menuAnchorElement)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={onShowScheduledTasksToggle}>
          <ListItemIcon>
            <CheckIcon
              style={showScheduledTasks ? { opacity: 1 } : { opacity: 0 }}
            />
          </ListItemIcon>
          <ListItemText>
            {resourceStrings.showScheduledTasksFilterText}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={onShowRecurringTasksToggle}>
          <ListItemIcon>
            <CheckIcon
              style={showRecurringTasks ? { opacity: 1 } : { opacity: 0 }}
            />
          </ListItemIcon>
          <ListItemText>
            {resourceStrings.showRecurringTasksFilterText}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={onShowEventsToggle}>
          <ListItemIcon>
            <CheckIcon style={showEvents ? { opacity: 1 } : { opacity: 0 }} />
          </ListItemIcon>
          <ListItemText>{resourceStrings.showEventsFilterText}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CalendarFilterMenu;
