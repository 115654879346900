import React from 'react';

import { Typography, Grid } from '@material-ui/core';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import { formatDateForTaskList } from 'src/util/TaskUtil';

const useStyles = makeStyles({
  colorSecondary: {
    color: 'rgba(0,0,0,.54)',
  },
  icon: {
    fontSize: '0.75rem',
  },
  iconPadding: {
    paddingRight: '0.2rem',
  },
});

const TaskDate = ({ task }) => {
  const classes = useStyles();

  const start = task.Start;
  const end = task.End;
  const allDay = task.AllDay ? true : false;
  const isRecurring = task.IsRecurring ? true : false;

  if (!start) {
    return null;
  }

  const datetimeString = formatDateForTaskList(start, end, allDay);

  return (
    <Grid container wrap="nowrap">
      {isRecurring && (
        <Grid item xs className={classes.iconPadding}>
          <AutoRenewIcon
            className={classNames(classes.icon, classes.colorSecondary)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          variant="body2"
          noWrap
          className={classes.colorSecondary}
          data-cy="task-row-date"
        >
          {datetimeString}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TaskDate;
