import React from 'react';

import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import TagIcon from '@material-ui/icons/LocalOffer';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import { makeStyles } from '@material-ui/styles';

import { useAuth0 } from 'src/providers/Auth0Provider';
import { UseAddToHomeScreen } from 'src/providers/AddToHomeScreenProvider';
import { useManageTagsDialog } from 'src/providers/ManageTagsDialogProvider';
import { useTasks } from 'src/providers/TasksProvider';
import packageJson from 'src/../package.json';
import { useHotkeys } from 'react-hotkeys-hook';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

const AppDrawer = ({ drawerIsOpen, toggleDrawer }) => {
  const classes = useStyles();

  const { logout } = useAuth0();
  const { isInstallable, promptToInstall } = UseAddToHomeScreen();
  const { openManageTagsDialog } = useManageTagsDialog();
  const { tags } = useTasks();

  const onManageTagsClick = () => {
    openManageTagsDialog();
  };

  useHotkeys('shift+/,/', () => onHelpClick(), [{}]);
  const onHelpClick = () => {
    window.open('https://www.todocalendar.co/help', 'help_page');
  };

  const onAddToHomeScreenClick = () => {
    promptToInstall();
  };

  const onLogoutClick = () => {
    logout();
  };

  const onThrowTestErrorClick = () => {
    const nullVar = null;
    // eslint-disable-next-line no-unused-vars
    const result = nullVar.doesNotExist;
  };

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      open={drawerIsOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <div
        className={classes.list}
        role="presentation"
        onClick={() => toggleDrawer(false)}
      >
        <List>
          {tags && (
            <ListItem
              button
              data-cy="drawer-manage-tags-button"
              onClick={onManageTagsClick}
            >
              <ListItemIcon>
                <TagIcon />
              </ListItemIcon>
              <ListItemText>Manage Tags</ListItemText>
            </ListItem>
          )}

          <ListItem button onClick={onHelpClick}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText>Help</ListItemText>
          </ListItem>

          {isInstallable && (
            <ListItem button onClick={onAddToHomeScreenClick}>
              <ListItemIcon>
                <AddToHomeScreenIcon />
              </ListItemIcon>
              <ListItemText>Add to Home Screen</ListItemText>
            </ListItem>
          )}

          <ListItem
            button
            data-cy="drawer-logout-button"
            onClick={onLogoutClick}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>

          {process.env.REACT_APP_ENV !== 'production' && (
            <ListItem
              button
              data-cy="drawer-logout-button"
              onClick={onThrowTestErrorClick}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Throw Test Error</ListItemText>
            </ListItem>
          )}

          <ListItem>
            <ListItemText>Version {packageJson.version}</ListItemText>
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default AppDrawer;
