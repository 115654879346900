import React, { useState, useEffect } from 'react';

import SplitPane from 'react-split-pane';
import { makeStyles } from '@material-ui/core';

import { useList } from 'src/providers/ListProvider';
import FullCalendar from 'src/components/FullCalendar';
import TaskList from 'src/components/TaskList';
import { debounce } from 'src/util/Utils';
import { jsType } from 'src/constants/constants';
import { getLocalStorage, setLocalStorage } from 'src/util/StorageUtil';

const splitterWidth = 12;

const useStyles = makeStyles({
  splitPane: {
    position: 'static !important',
    '& .Resizer': {
      background: 'rgba(0, 0, 0, .08)',
      boxSizing: 'border-box',
      backgroundClip: 'padding-box',
    },
    '& .Resizer:hover': {
      transition: 'all 0.5s ease',
    },
    '& .Resizer.vertical': {
      width: `${splitterWidth}px`,
      cursor: 'col-resize',
    },
    '& .Resizer.vertical:hover': {
      background: 'rgba(0, 0, 0, .16)',
    },
    '& .Resizer.disabled': {
      cursor: 'not-allowed',
    },
    '& .Resizer.disabled:hover': {
      borderColor: 'transparent',
    },
    '& .Pane.vertical.Pane1': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .Pane.vertical.Pane2': {
      display: 'flex',
    },
  },
  resizeIcon: {
    position: 'relative',
    top: '40%',
    left: '-9px',
    fontSize: '30px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const SimpleSplitPane = () => {
  const classes = useStyles();

  const { clearListCache } = useList();

  const [isDragging, setIsDragging] = useState(false);
  const [size, setSize] = useState(undefined);
  const [fullScreenPane, setFullScreenPane] = useState(
    getLocalStorage('splitPaneFullScreenPane', 0, jsType.number)
  );

  useEffect(() => {
    const splitPane = document.querySelector('span.Resizer.vertical');
    if (!splitPane) {
      return;
    }

    const moreVertSvgHtml = `<svg class="MuiSvgIcon-root ${classes.resizeIcon}" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>`;
    splitPane.innerHTML += moreVertSvgHtml;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const taskListShowGuttersMinWidth = 400;
  const paneMinWidth = 300;

  const getStoredSize = () => {
    const result = getLocalStorage(
      'splitPanePosition',
      getWindowWidth() / 2,
      jsType.number
    );
    if (result > getWindowWidth()) {
      return getWindowWidth();
    }
    return result;
  };

  const getWindowWidth = () => {
    return window.innerWidth - splitterWidth;
  };

  const showGutters = size => {
    return size > taskListShowGuttersMinWidth;
  };

  const [taskListGutters, setTaskListGutters] = useState(
    showGutters(getStoredSize())
  );

  const onDragStarted = () => {
    setIsDragging(true);
  };

  const onDragFinished = () => {
    setIsDragging(false);
    clearListCache(null, true);
    setTimeout(() => {
      setSize(undefined);
    }, 0);
  };

  const onChange = size => {
    setTaskListGutters(showGutters(size));
    const windowWidth = getWindowWidth();

    if (size > 0 && size < paneMinWidth) {
      setSize(0);
      if (fullScreenPane !== 1) {
        setAndStoreFullScreenPane(1);
      }
      setStoredSize(0);
    } else if (size > windowWidth - paneMinWidth) {
      setSize(windowWidth);
      if (fullScreenPane !== 2) {
        setAndStoreFullScreenPane(2);
      }
      setStoredSize(windowWidth);
    } else {
      setSize(undefined);
      if (fullScreenPane !== 0) {
        setAndStoreFullScreenPane(0);
      }
      setStoredSize(size);
    }
  };

  const setStoredSize = debounce(size => {
    setLocalStorage('splitPanePosition', size);
  }, 300);

  const setAndStoreFullScreenPane = value => {
    setFullScreenPane(value);
    setLocalStorage('splitPaneFullScreenPane', value);
  };

  return (
    <SplitPane
      className={classes.splitPane}
      split="vertical"
      size={isDragging ? undefined : size}
      onChange={onChange}
      defaultSize={getStoredSize()}
      minSize={0}
      maxSize={getWindowWidth()}
      primary="second"
      onDragStarted={onDragStarted}
      onDragFinished={onDragFinished}
    >
      {fullScreenPane !== 2 ? <FullCalendar /> : <div></div>}
      {fullScreenPane !== 1 ? (
        <TaskList gutters={taskListGutters} />
      ) : (
        <div></div>
      )}
    </SplitPane>
  );
};

export default SimpleSplitPane;
