import React, { createContext, useContext, useState, useRef } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';

const DialogContext = createContext();
export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const resolveRef = useRef();

  const openDialog = (title, content) => {
    setTitle(title);
    setContent(content);
    setIsDialogOpen(true);
    return new Promise(resolve => {
      resolveRef.current = resolve;
    });
  };

  const onDialogClose = () => {
    closeDialog();
    resolveRef.current(false);
  };

  const onDialogConfirm = () => {
    closeDialog();
    resolveRef.current(true);
  };

  const closeDialog = () => {
    setTitle('');
    setContent('');
    setIsDialogOpen(false);
  };

  return (
    <DialogContext.Provider
      value={{
        openDialog,
      }}
    >
      {children}
      <Dialog open={isDialogOpen} onClose={onDialogClose} fullWidth={true}>
        <DialogTitle data-cy="dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText data-cy="dialog-content">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onDialogClose}
            color="primary"
            data-cy="dialog-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={onDialogConfirm}
            color="primary"
            data-cy="dialog-ok-button"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};
