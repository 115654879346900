import React from 'react';

import {
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  formControl: {
    width: '5.5rem',
  },
  select: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    '&:focus': {
      backgroundColor: '#fafafa',
    },
    '&:focus:hover': {
      backgroundColor: '#f1f3f4',
    },
  },
  menuItem: {
    width: '9rem',
  },
  notchedOutlineRoot: {
    borderWidth: '1px !important',
    '&:hover': {
      borderColor: 'rgb(218, 220, 224) !important',
      backgroundColor: '#f1f3f4',
    },
    '&:active': {
      borderColor: 'rgb(218, 220, 224) !important',
    },
  },
  notchedOutline: {
    borderWidth: '1px !important',
    borderColor: 'rgb(218, 220, 224) !important',
  },
});

const SimpleDropdown = ({ name, items, selectedValue, setSelectedValue }) => {
  const classes = useStyles();

  const onChange = event => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl className={classes.formControl} data-cy={`${name}-dropdown`}>
      <Select
        value={selectedValue}
        autoWidth={true}
        onChange={onChange}
        input={
          <OutlinedInput
            classes={{
              root: classes.notchedOutlineRoot,
              notchedOutline: classes.notchedOutline,
            }}
            className={classes.notchedOutlineRoot}
          />
        }
        classes={{ root: classes.select }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            text={item.text}
            className={classes.menuItem}
            data-cy={`${name}-dropdown-item`}
          >
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SimpleDropdown;
