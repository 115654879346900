import React, { useRef, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import AppSettings from 'src/constants/AppSettings';
import TaskInputService from 'src/services/TaskInputService';
import { useTasks } from 'src/providers/TasksProvider';
import { resourceStrings } from 'src/constants/constants';
import { useSnackbar } from 'src/providers/SnackbarProvider';

const useStyles = makeStyles({
  taskDescriptionContainer: {
    position: 'relative',
  },
  taskDescription: {
    display: 'inline-block',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    padding: '18.5px 14px',
    fontSize: '16px',
    outline: 0,
    '&:before': {
      content: '"\\feff"',
    },
  },
  taskInputAutocomplete: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    zIndex: 1000,
    cursor: 'pointer',
    maxHeight: '10em',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '0.2rem',
    display: 'block',
  },
  taskInputAutocompleteAnchor: {
    display: 'block',
    padding: '0.5rem',
    '&:hover': {
      backgroundColor: 'rgba(237, 237, 237, 1)',
    },
  },
  selected: {
    backgroundColor: 'rgba(237, 237, 237, 1)',
  },
  taskInputAutocompleteAnchorSpan: {
    color: '#ffffff',
    backgroundColor: `${AppSettings.DEFAULT_COLOURS.TAG_BG}`,
    borderRadius: '0.2rem',
    padding: '0.1rem',
  },
  dateHighlight: {
    backgroundColor: '#616161',
    color: '#fff',
    borderRadius: '.2rem',
    padding: '.1rem',
    cursor: 'pointer',
  },
  tagHighlight: {
    backgroundColor: `${AppSettings.DEFAULT_COLOURS.TAG_BG}`,
    color: 'white',
    borderRadius: '0.2rem',
    padding: '0.1rem',
  },
});

const taskInputService = new TaskInputService();

const TaskInput = ({
  onCancel,
  onComplete,
  inputTask,
  setInputTask,
  setInputTaskError,
}) => {
  const classes = useStyles();

  const { tags } = useTasks();
  const { showSnackbar } = useSnackbar();

  const [savedSelection, setSavedSelection] = useState(null);
  const [tagResults, setTagResults] = useState([]);
  const [selectedTagResultIndex, setSelectedTagResultIndex] = useState(null);

  const taskInputRef = useRef(null);
  const taskInputAutocompleteRef = useRef(null);

  taskInputService.setClasses(classes.dateHighlight, classes.tagHighlight);

  useEffect(() => {
    document.execCommand('defaultParagraphSeparator', false, 'br'); // Set Firefox to insert br instead of div

    const taskInput = taskInputRef.current;

    if (inputTask) {
      taskInputService.populateTaskEditString(inputTask, taskInput);
      taskInputService.setCaretPosition(
        taskInput,
        inputTask.Title.replace(/\n/g, '').length
      );
    }

    taskInput.blur();
    setTimeout(() => {
      taskInput.focus();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTaskTextKeyDown = event => {
    const taskInput = taskInputRef.current;
    const taskInputAutocomplete = taskInputAutocompleteRef.current;

    // If autocomplete is closed, allow to process ArrowLeft/ArrowRight without any other processing
    if (!taskTagAutocompleteIsOpen()) {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        return;
      }
    }

    if (taskInputService.caretIsInDateSpan(taskInput)) {
      if (event.key === AppSettings.TAG_PREFIX) {
        event.preventDefault();
      } else if (event.key === 'Backspace') {
        // Have pressed backspace in a date/time highlight span
        // Remove the span, and then execute another search for other matches
        const dateSpanNodeText = taskInputService.getDateTimeSpanAtCaretText(
          taskInput
        );
        taskInputService.removeDateTimeSpan(taskInput);
        taskInputService.addDateTimeSpanException(dateSpanNodeText);
        taskInputService.highlightDateTime(taskInput);
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'Enter') {
        event.preventDefault();
        confirmEnteredTask();
      } else if (event.key === 'Enter') {
        // Prevent enter key in date span
        event.preventDefault();
        showSnackbar('Cannot add a newline within matched text.');
      }
    } else if (taskInputService.caretIsInTagSpan(taskInput)) {
      const tagSpanNode = taskInputService.getTagSpanAtCaret(taskInput);

      if (event.key === AppSettings.TAG_PREFIX) {
        event.preventDefault();
      } else if (
        event.key === 'Backspace' &&
        tagSpanNode &&
        tagSpanNode.childNodes[0] &&
        tagSpanNode.childNodes[0].nodeValue.length === 1 &&
        taskInputService.getCaretPosition(taskInput) > 0
      ) {
        // Remove span if pressing backspace and text is length 1, to avoid Chrome contenteditable span/font/inline style bug
        if (
          tagSpanNode.previousSibling &&
          tagSpanNode.previousSibling.nodeType === Node.TEXT_NODE &&
          tagSpanNode.previousSibling.nodeValue.endsWith(' ')
        ) {
          // Replace trailing space of previous text node with nbsp; so that cursor does not move back too far
          tagSpanNode.previousSibling.nodeValue = tagSpanNode.previousSibling.nodeValue.replace(
            /[ ]$/g,
            AppSettings.NBSP
          );
        }
        taskInput.removeChild(tagSpanNode);
        closeTaskTagAutocomplete();
        event.preventDefault();
      } else if (taskTagAutocompleteIsOpen()) {
        // Autocomplete is open
        switch (event.key) {
          case 'ArrowDown': {
            if (selectedTagResultIndex < tagResults.length - 1) {
              const updatedIndex = selectedTagResultIndex + 1;
              setSelectedTagResultIndex(updatedIndex);
              taskInputService.ensureScrolledIntoView(
                taskInputAutocomplete,
                document.getElementById(
                  'autocomplete-tag-result-' + updatedIndex
                )
              );
            }
            event.preventDefault();
            break;
          }
          case 'ArrowUp': {
            if (selectedTagResultIndex > 0) {
              const updatedIndex = selectedTagResultIndex - 1;
              setSelectedTagResultIndex(updatedIndex);
              taskInputService.ensureScrolledIntoView(
                taskInputAutocomplete,
                document.getElementById(
                  'autocomplete-tag-result-' + updatedIndex
                )
              );
            }
            event.preventDefault();
            break;
          }
          case 'ArrowLeft':
          case 'ArrowRight':
          case AppSettings.TAG_PREFIX: {
            event.preventDefault();
            break;
          }
          case 'Enter':
          case 'Tab': {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            const tagResult = tagResults[selectedTagResultIndex];
            insertSelectedAutocompleteTag(tagResult);
            break;
          }
          case 'Escape': {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            closeTaskTagAutocomplete();
            break;
          }
          default: {
            // Empty
          }
        }
      } else {
        // Autocomplete is closed
        if (event.key === 'Escape') {
          event.preventDefault();
          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
          taskInputService.clearDateTimeSpanExceptions();
          onCancel();
        } else if (event.ctrlKey && event.key === 'Enter') {
          event.preventDefault();
          confirmEnteredTask();
        } else if (event.key === 'Enter') {
          // Prevent Enter in tag span
          event.preventDefault();
          showSnackbar('Cannot add a newline within matched text.');
        }
      }
    } else {
      // Not in a tag or datetime span
      if (event.key === 'Escape') {
        taskInputService.clearDateTimeSpanExceptions();
        onCancel();
      } else if (event.ctrlKey && event.key === 'Enter') {
        event.preventDefault();
        confirmEnteredTask();
      } else if (event.key === 'Enter') {
        // Do nothing (allow Enter to insert newline)
      }
    }

    saveEnteredTask();
  };

  const onTaskTextInput = () => {
    const taskInput = taskInputRef.current;

    if (taskInput.innerHTML === '<br>') {
      // Bug where if input contains highlight tags, and user clears input (select all, backspace), <br> is left in contenteditable
      taskInput.innerHTML = '';
    }

    // Remove any <br> tags added by Firefox after inserting a space, replacing space with NBSP
    for (const childNode of [...taskInput.childNodes]) {
      taskInputService.removeBrFromNodeAndChildren(taskInput, childNode);
    }

    taskInputService.highlightTags(taskInput);
    taskInputService.highlightDateTime(taskInput);
    saveSelection(taskInput);

    if (taskInputService.caretIsInTagSpan(taskInput)) {
      // Caret is in a tag span, so filter autocomplete
      const tagText = taskInputService.getTagSpanAtCaretText(taskInput);
      if (tagText === AppSettings.TAG_PREFIX) {
        if (tags) {
          setTagResults(tags);
        } else {
          setTagResults([]);
        }
        setSelectedTagResultIndex(0);
      } else {
        if (tags) {
          setTagResults(
            tags.filter(t =>
              t.Name.toLowerCase().startsWith(tagText.toLowerCase())
            )
          );
        } else {
          setTagResults([]);
        }
        setSelectedTagResultIndex(0);
      }
    } else {
      // Caret is not in a tag span, so should be no autocomplete
      if (tagResults && tagResults.length > 0) {
        closeTaskTagAutocomplete();
      }
    }

    saveEnteredTask();
  };

  const saveSelection = inputElement => {
    const selection = window.getSelection();
    if (
      selection &&
      selection.anchorNode &&
      inputElement.contains(selection.anchorNode)
    ) {
      setSavedSelection({ node: selection.anchorNode, type: selection.type });
    }
  };

  const taskTagAutocompleteIsOpen = () => {
    if (tagResults && tagResults.length > 0) {
      return true;
    }
    return false;
  };

  const insertSelectedAutocompleteTag = tagResult => {
    const taskInput = taskInputRef.current;
    taskInputService.insertSelectedAutocompleteTag(
      taskInput,
      tagResult,
      savedSelection
    );
    closeTaskTagAutocomplete();

    saveEnteredTask();
  };

  const closeTaskTagAutocomplete = () => {
    setTagResults([]);
  };

  const saveEnteredTask = () => {
    const taskInput = taskInputRef.current;

    // TODO: Why getting title here when whole task is retrieved later, could check after that?
    const taskTitle = taskInputService.getElementText(taskInput);
    if (taskTitle.length === 0) {
      setInputTaskError(resourceStrings.noTaskTitleEntered);
      return;
    }

    const tagNames = taskInputService.getInputTagNames(taskInput);
    if (
      tagNames.findIndex(tagName => tagName === AppSettings.TAG_PREFIX) > -1
    ) {
      setInputTaskError(resourceStrings.invalidTagNameEntered);
      return;
    }

    const taskToAddOrUpdate = taskInputService.getTaskFromInput(
      taskInput,
      inputTask,
      tags
    );

    setInputTaskError(null);
    setInputTask(taskToAddOrUpdate);
  };

  const confirmEnteredTask = () => {
    closeTaskTagAutocomplete();

    onComplete();
  };

  const onTaskTextPaste = event => {
    let clipboardData, pastedData;

    // Stop data actually being pasted into div
    event.stopPropagation();
    event.preventDefault();

    // Get pasted data via clipboard API
    clipboardData = event.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');

    // Do whatever with pasted data
    taskInputService.insertTextAtCursor(pastedData);

    const taskInput = taskInputRef.current;
    taskInput.normalize();
  };

  return (
    <div className={classes.taskDescriptionContainer}>
      <div
        className={classes.taskDescription}
        contentEditable="true"
        onInput={onTaskTextInput}
        onKeyDown={onTaskTextKeyDown}
        onPaste={onTaskTextPaste}
        ref={taskInputRef}
        data-cy="task-input-contenteditable"
      />
      <div
        ref={taskInputAutocompleteRef}
        className={classes.taskInputAutocomplete}
      >
        {tagResults &&
          tagResults.map((tagResult, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={tagResult.Id}
              id={`autocomplete-tag-result-${index}`}
              onClick={() => insertSelectedAutocompleteTag(tagResult)}
              className={classNames(classes.taskInputAutocompleteAnchor, {
                [classes.selected]: selectedTagResultIndex === index,
              })}
            >
              <span
                className={classes.taskInputAutocompleteAnchorSpan}
                style={{ backgroundColor: tagResult.Colour }}
              >
                {tagResult.Name}
              </span>
            </a>
          ))}
      </div>
    </div>
  );
};

export default TaskInput;
