import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  tag: {
    color: '#fff',
    padding: '0.1rem',
    borderRadius: '0.2rem',
    marginRight: '0.2rem',
  },
});

const TagList = ({ tags }) => {
  const classes = useStyles();

  if (tags == null || tags.length === 0) {
    return null;
  }
  const tagsList = tags.map((tag, index) => (
    <Typography
      noWrap
      variant="body2"
      display="inline"
      className={classes.tag}
      style={{ backgroundColor: tag.Colour }}
      key={index}
      data-cy="task-row-tag-list-tag"
    >
      {tag.Name}
    </Typography>
  ));

  return tagsList;
};

export default TagList;
