import React, { useState } from 'react';

import { IconButton, Menu, makeStyles } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';

import { useTasks } from 'src/providers/TasksProvider';
import SimpleTooltip from 'src/components/SimpleTooltip';
import { setLocalStorage } from 'src/util/StorageUtil';
import FilterMenuItem from 'src/components/FilterMenuItem';

const useStyles = makeStyles(theme => ({
  filterMenu: {
    position: 'absolute',
    zIndex: 1,
    top: '0.5rem',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.3)',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
  },
}));

const FilterMenu = ({ className, marginRight }) => {
  const classes = useStyles();

  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const { filters, setFilters } = useTasks();

  const handleMenuOpenClick = event => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    closeMenu();
  };

  const handleMenuItemClick = index => (filterKey, e) => {
    setFilters(prevState => {
      const newState = prevState.slice(0);
      newState[index].isChecked = !newState[index].isChecked;
      setLocalStorage(filterKey, newState[index].isChecked);
      return newState;
    });

    closeMenu();
  };

  const closeMenu = () => {
    setMenuAnchorElement(null);
  };

  const menuItemControls = filters.map((item, index) => (
    <FilterMenuItem
      key={index}
      text={item.text}
      isChecked={item.isChecked}
      filterKey={item.key}
      handleClick={handleMenuItemClick(index)}
    />
  ));

  return (
    <div
      className={classes.filterMenu}
      style={{
        right: marginRight,
      }}
    >
      <SimpleTooltip title="Filter tasks">
        <IconButton
          onClick={handleMenuOpenClick}
          className={className}
          data-cy="filter-menu-button"
        >
          <FilterIcon />
        </IconButton>
      </SimpleTooltip>

      <Menu
        disableAutoFocusItem
        anchorEl={menuAnchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(menuAnchorElement)}
        onClose={handleMenuClose}
      >
        {menuItemControls}
      </Menu>
    </div>
  );
};

export default FilterMenu;
