import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';

import AddTaskFab from 'src/components/AddTaskFab';
import AppDrawer from 'src/components/AppDrawer';
import TopAppBar from 'src/components/TopAppBar';
import Navigation from 'src/components/Navigation';

const useStyles = makeStyles({
  home: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const Layout = ({ children, hideNavigation }) => {
  const classes = useStyles();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const toggleDrawer = open => {
    setDrawerIsOpen(open);
  };

  return (
    <div
      onContextMenu={event => {
        event.preventDefault();
      }}
      className={classes.home}
    >
      <AppDrawer drawerIsOpen={drawerIsOpen} toggleDrawer={toggleDrawer} />
      <TopAppBar toggleDrawer={toggleDrawer} />
      {children}
      {!hideNavigation && <Navigation />}
      <AddTaskFab></AddTaskFab>
    </div>
  );
};

export default Layout;
