import React, { createContext, useContext, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { startOfDay, isDate, isEqual } from 'date-fns';

import { useTasks } from 'src/providers/TasksProvider';
import { useSnackbar } from 'src/providers/SnackbarProvider';
import { useDialog } from 'src/providers/DialogProvider';
import TaskInput from 'src/components/TaskInput';
import {
  changesMadeToTask,
  isRecurrenceRuleUpdated,
  recurringTaskUnscheduled,
} from 'src/util/TaskUtil';
import { resourceStrings } from 'src/constants/constants';

const TaskDialogContext = createContext();
export const useTaskDialog = () => useContext(TaskDialogContext);

const useStyles = makeStyles({
  dialogPaper: {
    overflowY: 'visible',
  },
  dialogContentRoot: {
    overflowY: 'visible',
  },
});

export const TaskDialogProvider = ({ children }) => {
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();
  const { addOrUpdateTask } = useTasks();
  const { openDialog } = useDialog();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [inputTask, setInputTask] = useState(null);
  const [inputTaskError, setInputTaskError] = useState(null);
  const [isTaskEditing, setIsTaskEditing] = useState(false);

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const openTaskDialog = (taskToEdit, taskToAdd) => {
    if (taskToEdit) {
      setIsTaskEditing(true);
      setTaskToEdit(taskToEdit);
      setInputTask(taskToEdit);
    } else if (taskToAdd) {
      setIsTaskEditing(false);
      setTaskToEdit(null);
      setInputTask(taskToAdd);
    } else {
      setIsTaskEditing(false);
      setTaskToEdit(null);
      setInputTask(null);
    }
    setIsDialogOpen(true);
  };

  const onDialogClose = () => {
    closeDialog();
  };

  const onDialogCancelClick = () => {
    closeDialog();
  };

  const onTaskInputCancel = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setInputTask(null);
    setTaskToEdit(null);
  };

  const onDialogSaveClick = async () => {
    await confirmInputTask();
  };

  const onTaskInputComplete = async () => {
    await confirmInputTask();
  };

  const confirmInputTask = async () => {
    if (inputTaskError) {
      showSnackbar(inputTaskError);
      return;
    }

    if (!changesMadeToTask(taskToEdit, inputTask)) {
      showSnackbar(resourceStrings.noChangesWereMade);
      return;
    }

    const taskStartDate = inputTask.Start ? startOfDay(inputTask.Start) : null;
    const taskEndDate = inputTask.End ? startOfDay(inputTask.End) : null;
    if (
      isDate(taskStartDate) &&
      isDate(taskEndDate) &&
      !isEqual(taskStartDate, taskEndDate)
    ) {
      // For now don't allow tasks to extend over 2 days
      showSnackbar(resourceStrings.multiDayTasksNotSupported);
      return;
    }

    if (isRecurrenceRuleUpdated(taskToEdit, inputTask)) {
      // Tasks are both recurring and change to task recurrence - get confirmation
      const result = await openDialog(
        resourceStrings.updateAllTasks,
        resourceStrings.thisWillUpdateAllTasksEtc
      );
      if (result) {
        await addOrUpdateTask(inputTask, taskToEdit);
        showSnackbar(resourceStrings.tasksUpdated);
      }
    } else if (recurringTaskUnscheduled(taskToEdit, inputTask)) {
      // Recurring task set to be unscheduled
      const result = await openDialog(
        resourceStrings.removeRecurrence,
        resourceStrings.thisWillCompletelyRemoveTaskRecurrenceEtc
      );
      if (!result) {
        return;
      }
      await addOrUpdateTask(inputTask, taskToEdit);
    } else {
      await addOrUpdateTask(inputTask, taskToEdit);
    }

    closeDialog();
  };

  return (
    <TaskDialogContext.Provider value={{ openTaskDialog }}>
      {children}
      <Dialog
        open={isDialogOpen}
        onClose={onDialogClose}
        fullWidth={true}
        fullScreen={!isDesktop}
        classes={{ paper: classes.dialogPaper }}
        scroll="body"
      >
        <DialogTitle>{isTaskEditing ? 'Edit Task' : 'Add Task'}</DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <TaskInput
            onCancel={onTaskInputCancel}
            onComplete={onTaskInputComplete}
            inputTask={inputTask}
            setInputTask={setInputTask}
            setInputTaskError={setInputTaskError}
          ></TaskInput>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onDialogCancelClick}
            color="primary"
            data-cy="task-dialog-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={onDialogSaveClick}
            color="primary"
            data-cy="task-dialog-save-button"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </TaskDialogContext.Provider>
  );
};
