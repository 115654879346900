import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import MoreMenu from 'src/components/MoreMenu';
import SimpleTooltip from 'src/components/SimpleTooltip';
import { useDialog } from 'src/providers/DialogProvider';
import { useTaskDialog } from 'src/providers/TaskDialogProvider';
import { useTasks } from 'src/providers/TasksProvider';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { cloneTasks } from 'src/util/TaskUtil';

const growStyle = delay => ({
  opacity: 0,
  transform: 'scale(0)',
  animationName: '$grow',
  animationDuration: '100ms',
  animationDelay: `${delay}ms`,
  animationFillMode: 'forwards',
});

const useStyles = makeStyles(theme => ({
  growWithDelay0: growStyle(0),
  growWithDelay1: growStyle(50),
  growWithDelay2: growStyle(100),
  growWithDelay3: growStyle(150),
  growWithDelay4: growStyle(200),
  '@keyframes grow': {
    '0%': {
      opacity: 0,
      transform: 'scale(0)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  shrinkImmediately: {
    animationName: '$shrink',
    animationDuration: '200ms',
    animationDelay: `0ms`,
    animationFillMode: 'forwards',
  },
  '@keyframes shrink': {
    '0%': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '100%': {
      opacity: 0,
      transform: 'scale(0)',
    },
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
    borderBottom: '1px solid rgba(0,0,0,.10)',
  },
}));

const TopAppBar = ({ toggleDrawer }) => {
  const classes = useStyles();

  const {
    getTasksTagsAndEventsFromServer,
    selectedTasks,
    tasks,
    setTasks,
    setTasksCompletionStatus,
    deleteTasks,
    onTasksUpdateSnackbarUndo,
  } = useTasks();
  const { openTaskDialog } = useTaskDialog();
  const { openDialog } = useDialog();

  const onMenuButtonClick = () => {
    toggleDrawer(true);
  };

  useHotkeys('Esc', () => onArrowBackButtonClick(), [{}]);
  const onArrowBackButtonClick = () => {
    if (!selectedTasks || selectedTasks.length === 0) {
      return;
    }

    setTasks(cloneTasks(tasks, true));
  };

  useHotkeys('c', () => onCheckButtonClick(), [{}]);
  const onCheckButtonClick = () => {
    if (selectedTasks.length === 0) {
      return;
    }

    setTasksCompletionStatus(selectedTasks, true, onTasksUpdateSnackbarUndo);
  };

  useHotkeys(
    'e',
    e => {
      e.preventDefault();
      onEditButtonClick();
    },
    [{}]
  );
  const onEditButtonClick = () => {
    if (!selectedTasks || selectedTasks.length !== 1) {
      return;
    }

    openTaskDialog(selectedTasks[0]);
  };

  useHotkeys('Del', async () => await onDeleteButtonClick(), [{}]);
  const onDeleteButtonClick = async () => {
    if (!selectedTasks || selectedTasks.length === 0) {
      return;
    }

    const count = selectedTasks.length;
    const result = await openDialog(
      '',
      `Delete ${count} task${count !== 1 ? 's' : ''}?`
    );
    if (result) {
      deleteTasks(selectedTasks);
    }
  };

  useHotkeys('r', () => onRefreshButtonClick(), [{}]);
  const onRefreshButtonClick = () => {
    getTasksTagsAndEventsFromServer();
  };

  const canCompleteSelectedTasks = () => {
    if (!selectedTasks || selectedTasks.length === 0) {
      return false;
    }

    // If at least one selected task is not completed, return true
    return selectedTasks.some(selectedTask => !selectedTask.IsCompleted);
  };

  let tasksAreSelected = false;
  if (selectedTasks && selectedTasks.length > 0) {
    tasksAreSelected = true;
  }

  let toolbar;

  if (!tasksAreSelected) {
    toolbar = (
      <Toolbar disableGutters key="no-tasks-selected-toolbar">
        <SimpleTooltip title="Main menu">
          <IconButton
            data-cy="drawer-toggle-button"
            onClick={onMenuButtonClick}
          >
            <MenuIcon />
          </IconButton>
        </SimpleTooltip>

        <Typography
          data-cy="application-title"
          variant="h6"
          color="inherit"
          style={{ flexGrow: 1, userSelect: 'none' }}
        >
          Todo Calendar
        </Typography>

        <SimpleTooltip title="Refresh">
          <IconButton
            onClick={onRefreshButtonClick}
            data-cy="top-app-bar-refresh-button"
          >
            <RefreshIcon />
          </IconButton>
        </SimpleTooltip>
      </Toolbar>
    );
  } else {
    const s = selectedTasks.length !== 1 ? 's' : '';
    toolbar = (
      <Toolbar disableGutters key="tasks-selected-toolbar">
        <SimpleTooltip title="Clear selection">
          <IconButton onClick={onArrowBackButtonClick}>
            <ArrowBackIcon />
          </IconButton>
        </SimpleTooltip>

        <Typography
          variant="h6"
          style={{ flexGrow: 1, userSelect: 'none' }}
          data-cy="top-app-bar-selected-tasks-text"
        >
          {`${selectedTasks.length} selected`}
        </Typography>

        <SimpleTooltip title={`Edit selected task${s}`}>
          <IconButton
            onClick={onEditButtonClick}
            className={classNames({
              [classes.growWithDelay3]: selectedTasks.length === 1,
              [classes.shrinkImmediately]: selectedTasks.length !== 1,
            })}
            data-cy="top-app-bar-edit-button"
          >
            <EditIcon />
          </IconButton>
        </SimpleTooltip>

        {canCompleteSelectedTasks() && (
          <SimpleTooltip title={`Complete selected task${s}`}>
            <IconButton
              onClick={onCheckButtonClick}
              className={classNames(classes.growWithDelay2)}
              data-cy="top-app-bar-complete-button"
            >
              <CheckIcon />
            </IconButton>
          </SimpleTooltip>
        )}

        <SimpleTooltip title={`Delete selected task${s}`}>
          <IconButton
            onClick={onDeleteButtonClick}
            className={classNames(classes.growWithDelay1)}
            data-cy="top-app-bar-delete-button"
          >
            <DeleteIcon />
          </IconButton>
        </SimpleTooltip>

        <MoreMenu className={classes.growWithDelay0} />
      </Toolbar>
    );
  }

  return (
    <AppBar position="static" color="default" className={classes.appBar}>
      {toolbar}
    </AppBar>
  );
};

export default TopAppBar;
