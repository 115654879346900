import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import { taskType } from 'src/constants/constants';
import { useTasks } from 'src/providers/TasksProvider';
import TaskCompletionIcon from 'src/components/TaskCompletionIcon';
import TagList from 'src/components/TagList';
import TaskDate from 'src/components/TaskDate';

const hideTransitionDurationMs = 400;
const completeDelayMs = 100;

const useStyles = makeStyles({
  headerRow: {
    userSelect: 'none',
    paddingTop: '3rem',
    paddingBottom: '1rem',
    paddingLeft: props => {
      return props.gutters ? '3rem' : '1rem';
    },
    paddingRight: props => {
      return props.gutters ? '2rem' : '1rem';
    },
    '&:after': {
      position: 'absolute',
      borderBottom: '1px solid rgba(0,0,0,.10)',
      bottom: 0,
      content: '""',
      left: props => {
        return props.gutters ? '3rem' : '1rem';
      },
      right: props => {
        return props.gutters ? '2rem' : '1rem';
      },
    },
  },
  firstRow: {
    paddingTop: '1rem',
  },
  headerTitle: {
    paddingRight: '0.5rem',
  },
  taskRow: {
    userSelect: 'none',
    paddingLeft: props => {
      return props.gutters ? '2rem' : 0;
    },
    paddingRight: props => {
      return props.gutters ? '2rem' : 0;
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      backgroundColor: 'rgba(65, 131, 241, 0.3)',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      zIndex: -1,

      opacity: 0,
      transition: 'opacity 100ms',
    },
    '&:after': {
      position: 'absolute',
      borderBottom: '1px solid rgba(0,0,0,.10)',
      bottom: 0,
      content: '""',
      left: props => {
        return props.gutters ? '3rem' : '1rem';
      },
      right: props => {
        return props.gutters ? '2rem' : '1rem';
      },
    },
  },
  taskRowHidden: {
    transition: `opacity ${hideTransitionDurationMs}ms`,
    opacity: 0,
  },
  taskRowSelected: {
    '&:before': {
      opacity: 1,
      transition: 'opacity 100ms',
    },
  },
  taskRowHighlighted: {
    '&:before': {
      animationName: '$taskRowHighlighted',
      animationDuration: '2s',
      animationDelay: '100ms',
    },
  },
  '@keyframes taskRowHighlighted': {
    '0%': {
      opacity: 0,
    },
    '15%': {
      opacity: 1,
    },
    '85%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  taskSubtitle: {
    color: 'rgba(0,0,0,.54)',
  },
  iconContainer: {
    padding: '1rem 0.8rem 1rem 1rem',
  },
  taskContainer: {
    padding: '1rem 1rem 1rem 0',
  },
  taskTitle: {
    wordBreak: 'break-word',
  },
});

const TaskRow = ({ task, isFirst, onTaskClick, dragging, gutters }) => {
  const classes = useStyles({ gutters });

  const {
    setTaskIsCompleting,
    setTasksIsCompleted,
    isTaskHighlighted,
  } = useTasks();

  const [rippleEnabled, setRippleEnabled] = useState(true);

  const onTouchMove = () => {
    if (rippleEnabled) {
      setRippleEnabled(false);
    }
  };

  const onTouchEnd = () => {
    if (!rippleEnabled) {
      scheduleRippleEnabled(600);
    }
  };

  const scheduleRippleEnabled = timeout => {
    setTimeout(() => setRippleEnabled(true), timeout);
  };

  const onClick = () => {
    setIsSelected(!task.IsSelected);
  };

  const setIsSelected = selected => {
    onTaskClick(task, selected);
  };

  const onTaskClickCompleteTransition = (event, task) => {
    // Prevent row from being selected
    event.stopPropagation();
    setIsSelected(false);
    setRippleEnabled(false);
    scheduleRippleEnabled(1500);

    setTaskIsCompleting(task, true);

    setTimeout(() => {
      setTasksIsCompleted([task], true);
    }, completeDelayMs);
  };

  let taskRow;
  if (task.Type === taskType.Header) {
    // Header row
    taskRow = (
      <Grid
        id="task-row-header"
        container
        wrap="nowrap"
        alignItems="baseline"
        className={classNames(classes.headerRow, {
          [classes.firstRow]: isFirst,
        })}
      >
        <Grid item xs className={classes.headerTitle}>
          <Typography variant="h6" noWrap>
            {task.Title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" className={classes.taskSubtitle}>
            {task.Subtitle}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  // Task row
  else {
    taskRow = (
      <Grid
        id="task-row-item"
        data-cy="task-row"
        container
        wrap="nowrap"
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        className={classNames(classes.taskRow, {
          'waves-effect': true,
          'waves-effect-color': rippleEnabled && !dragging,
          'waves-white': !rippleEnabled || dragging,
          [classes.taskRowSelected]: task.IsSelected,
          [classes.taskRowHighlighted]: isTaskHighlighted(task),
          [classes.taskRowHidden]: task.IsCompleting || task.IsCompleted,
        })}
        data-task-id={task.Id}
        data-task-start={task.Start ? task.Start.toISOString() : ''}
      >
        <Grid
          item
          xs
          className={classNames(classes.iconContainer, {
            'waves-effect-none': true,
          })}
        >
          <TaskCompletionIcon
            task={task}
            onTaskClickCompleteTransition={onTaskClickCompleteTransition}
          />
        </Grid>

        <Grid item xs={12} className={classes.taskContainer} onClick={onClick}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={classNames(classes.taskTitle, 'task-row-title')}
                data-cy="task-row-title"
              >
                {task.Title}
              </Typography>
            </Grid>
          </Grid>

          {task.Start && (
            <Grid item xs={12}>
              <TaskDate task={task} />
            </Grid>
          )}

          {task.Tags && task.Tags.length > 0 && (
            <Grid item xs={12}>
              <TagList tags={task.Tags} />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  return taskRow;
};

export default TaskRow;
